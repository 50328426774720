import { useTranslation } from 'react-i18next'
import { Stack, Box, Typography, TextField } from '@mui/material'
import { QuotationResearchDetails } from '@/types'

type QuotationResearchRoundDetailsProps = {
  data: QuotationResearchDetails
  showRounds: boolean
}

export const QuotationResearchRoundDetails = ({
  data,
  showRounds = true,
}: QuotationResearchRoundDetailsProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      <Typography variant="h6" sx={{ mt: 4, mx: 2 }}>
        {data.title}
      </Typography>
      <Stack
        direction="row"
        spacing={8}
        sx={{
          alignItems: 'center',
          borderRadius: 2.5,
          backgroundColor: 'grey.50',
          width: '100%',
          p: 4,
          mb: 3,
        }}
      >
        <Box>
          <Typography variant="body2" color="grey">
            {t('quotations:titles.researchSpecies')}
          </Typography>
          <Typography variant="body1">{data.species}</Typography>
        </Box>
        <Box>
          <Typography variant="body2" color="grey">
            {t('quotations:titles.researchTypes')}
          </Typography>
          <Typography variant="body1">{data.research_types}</Typography>
        </Box>
      </Stack>
      {showRounds && (
        <Stack spacing={3}>
          {data.quotation_research_rounds.map((round) => (
            <Stack
              key={round.id}
              direction={{ xs: 'column', xl: 'row' }}
              alignItems={{ xs: 'flex-start', xl: 'center' }}
              justifyContent="space-between"
              spacing={{ xs: 4, xl: 2 }}
              borderRadius={2.5}
              p={4}
              bgcolor="grey.50"
              width="100%"
            >
              <Box>
                <Typography>{round.round_type}</Typography>
                <Typography variant="body2">{round.description}</Typography>
              </Box>
              <Stack direction="row" spacing={2}>
                <Box width={100}>
                  <TextField
                    value={round.number_of_researchers}
                    label={t('quotations:placeholders.numberOfResearchers')}
                    disabled={true}
                  />
                </Box>
                <Box width={80}>
                  <TextField
                    value={round.number_of_rounds}
                    label={t('quotations:placeholders.numberOfRounds')}
                    disabled={true}
                  />
                </Box>
                <Box width={90}>
                  <TextField
                    value={round.total_visits}
                    label={t('quotations:placeholders.visitTotal')}
                    disabled={true}
                  />
                </Box>
                <Box width={110}>
                  <TextField
                    value={round.round_duration_minutes}
                    label={t('quotations:placeholders.roundDuration')}
                    disabled={true}
                  />
                </Box>
                <Box width={80}>
                  <TextField
                    value={(round.total_duration_minutes / 60).toFixed(1)}
                    label={t('quotations:placeholders.hours')}
                    disabled={true}
                  />
                </Box>
                <Box width={110}>
                  <TextField
                    value={round.coupled_rounds}
                    label={t('quotations:placeholders.coupledRound')}
                    disabled={true}
                  />
                </Box>
              </Stack>
            </Stack>
          ))}
        </Stack>
      )}
    </>
  )
}
