import { ControlledFieldProps, PlanningControlChecks } from '@/types'
import { FieldValues } from 'react-hook-form/dist/types/fields'
import { AutocompleteOption, ControlledAutocomplete } from '@/components/inputs'
import { useTranslation } from 'react-i18next'

export type PlanningControlChecksAutocompleteProps<D extends FieldValues> =
  ControlledFieldProps<D>

export enum PlanningControlCheckGroups {
  GIERZWALUW = 'gierzwaluw_group',
  MASSAWINTER = 'massawinter_group',
  MEERVLEERMUIS = 'meervleermuis_group',
  PAARONDERZOEK = 'paaronderzoek_group',
  VLEERMUIS_VOORJAAR = 'vleermuis_voorjaar_group',
  SMP_HAARLEM = 'smp_haarlem_group',
  GROTE_GEBIEDEN = 'grote_gebieden_group',
}

const PlanningControlCheckOtherGroup = 'other_group'

export const groupedPlanningControlChecks = [
  {
    group_name: PlanningControlCheckGroups.GIERZWALUW,
    values: [PlanningControlChecks.DAYS_BETWEEN_FIRST_AND_THIRD_SWIFT_ROUNDS],
  },
  {
    group_name: PlanningControlCheckGroups.MASSAWINTER,
    values: [
      PlanningControlChecks.MASSAWINTER_ROUND_BEFORE_SEPTEMBER_10,
      PlanningControlChecks.FIRST_MASSAWINTER_ROUND_BEFORE_AUGUST_28,
      PlanningControlChecks.DAYS_BETWEEN_NIGHT_MASSAWINTER_ROUNDS,
      PlanningControlChecks.DAYS_BETWEEN_PAAR_MASSAWINTER_ROUNDS,
      PlanningControlChecks.FIRST_PARR_AND_FIRST_MASSAWINTER_SAME_DAY,
    ],
  },
  {
    group_name: PlanningControlCheckGroups.MEERVLEERMUIS,
    values: [
      PlanningControlChecks.MEERVLEERMUIS_ROUND_BEFORE_SEPTEMBER_15,
      PlanningControlChecks.FIRST_EVENING_ROUND_FOR_MEERVLEERMUIS_BEFORE_AUGUST_20,
      PlanningControlChecks.FIRST_NIGHT_GEWONE_WITH_MEERVLEERMUIS_BEFORE_AUGUST_20,
      PlanningControlChecks.FIRST_PAAR_ROUND_BEFORE_SEPTEMBER_5,
    ],
  },
  {
    group_name: PlanningControlCheckGroups.PAARONDERZOEK,
    values: [PlanningControlChecks.DAYS_BETWEEN_PAAR_EVENING_ROUNDS],
  },
  {
    group_name: PlanningControlCheckGroups.VLEERMUIS_VOORJAAR,
    values: [PlanningControlChecks.DAYS_BETWEEN_BAT_ROUNDS],
  },
  {
    group_name: PlanningControlCheckGroups.SMP_HAARLEM,
    values: [
      PlanningControlChecks.FIRST_HAARLEM_HOUSE_SPARROW_MORNING_ROUND_BEFORE_4_MAY,
      PlanningControlChecks.FIRST_HAARLEM_MORNING_ROUND_BEFORE_20_JUNE,
      PlanningControlChecks.MORNING_HAARLEM_HOUSE_SPARROW_ROUND_START_FROM_1H_AFTER_SUNRISE_AND_END_TILL_12,
    ],
  },
  {
    group_name: PlanningControlCheckGroups.GROTE_GEBIEDEN,
    values: [
      PlanningControlChecks.FIRST_LV_EVENING_ROUND_BEFORE_4_MAY,
      PlanningControlChecks.FIRST_MIDDERNACHTZWERMEN_EVENING_ROUND_BEFORE_20_AUGUST,
    ],
  },
]

const getGroupOfOption = (option: AutocompleteOption) =>
  groupedPlanningControlChecks.find((g) =>
    g.values.includes(option.value as PlanningControlChecks)
  )?.group_name ??
  (Object.values(PlanningControlCheckGroups).includes(
    option.value as PlanningControlCheckGroups
  )
    ? option.value
    : PlanningControlCheckOtherGroup)

export const PlanningControlChecksAutocomplete = <D extends FieldValues>({
  name,
  control,
}: PlanningControlChecksAutocompleteProps<D>): JSX.Element => {
  const { t } = useTranslation()

  const planningControlChecks = Object.keys(PlanningControlChecks).map(
    (key) => {
      const check = PlanningControlChecks[
        key as keyof typeof PlanningControlChecks
      ] as string

      return {
        value: check,
        title: t(`forms:planningControlChecks.${check}`),
      } as AutocompleteOption
    }
  )

  planningControlChecks.sort((a, b) => {
    const a_group = getGroupOfOption(a)
    const b_group = getGroupOfOption(b)

    const compared = a_group.localeCompare(b_group)

    if (compared === 0) {
      return a.title.localeCompare(b.title)
    }

    return compared
  })

  return (
    <ControlledAutocomplete
      name={name}
      control={control}
      options={planningControlChecks}
      textFieldProps={{
        placeholder: t('projects:protocolsStep.planningControlChecks'),
      }}
      multiple={true}
      groupBy={(option: AutocompleteOption) =>
        t(`forms:uncertaintyReason.${getGroupOfOption(option)}`)
      }
    />
  )
}
