import {
  useQueryClient,
  useMutation,
  UseMutationResult,
} from '@tanstack/react-query'
import { getUseSoundRecordsKey, useApiClient } from '@/hooks'
import { unwrapErrorResponse, unwrapResponse } from '@/utils'
import { APIErrorResponse, PostSoundRecord, SoundRecord } from '@/types'

export const usePostSoundRecord = (
  id: number
): UseMutationResult<SoundRecord, APIErrorResponse, PostSoundRecord> => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  return useMutation(
    async (params) => {
      const formData = new FormData()

      if (params.spectrogram_link) {
        formData.append('spectrogram_link', params.spectrogram_link)
      }

      formData.append('specie_identified', params.specie_identified)
      formData.append('analysis_notes', params.analysis_notes)

      return client
        .post(`/plannings/sound_recordings/${id}/save_analysis/`, formData)
        .then(unwrapResponse)
        .catch(unwrapErrorResponse)
    },
    {
      onSuccess: async () => {
        const queryKey = getUseSoundRecordsKey()

        await queryClient.invalidateQueries(queryKey)
      },
    }
  )
}
