import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { useApiClient } from '@/hooks'
import { unwrapResponse, unwrapErrorResponse } from '@/utils/unwrapResponse'
import {
  APIErrorResponse,
  QuotationPaymentTerm,
  PatchQuotationPaymentTerm,
} from '@/types'

export const usePatchQuotationPaymentTerm = (
  quotationId: number
): UseMutationResult<
  QuotationPaymentTerm,
  APIErrorResponse,
  PatchQuotationPaymentTerm
> => {
  const client = useApiClient()

  return useMutation((params) =>
    client
      .patch(`/quotations/${quotationId}/payment_terms/${params.id}/`, params)
      .then(unwrapResponse)
      .catch(unwrapErrorResponse)
  )
}
