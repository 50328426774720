import { useQueryList } from './useQueryList'
import { UseQueryResult } from '@tanstack/react-query'
import { APIErrorResponse, Contractor } from '@/types'

export const useUsersContractors = (
  enabled = true
): UseQueryResult<Contractor[], APIErrorResponse> =>
  useQueryList({
    url: '/users/contractors/',
    options: {
      enabled,
    },
  })
