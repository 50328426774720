import * as yup from 'yup'
import translation from 'i18next'
import { photosSchema } from '@/components/inputs/ControlledMediaUpload/photosValidation'

export const visitLogInfoSchema = yup.object({
  start_datetime: yup.date().typeError('invalid_date').required().nullable(),
  end_datetime: yup.date().typeError('invalid_date').required().nullable(),
  start_temperature: yup
    .number()
    .typeError(translation.t('errors:validation.numberType'))
    .required(),
  end_temperature: yup
    .number()
    .typeError(translation.t('errors:validation.numberType'))
    .required(),
  wind_force: yup
    .number()
    .typeError(translation.t('errors:validation.numberType'))
    .required(),
  cloud_cover: yup.string().required(),
  precipitation: yup.string().required(),
  precipitation_duration: yup.string().required(),
  photos: photosSchema,
})
