import { ClientPerson, APIErrorResponse } from '@/types'
import { UseQueryResult } from '@tanstack/react-query'
import { useQueryList } from './useQueryList'

export type UseClientPersonsProps = {
  clientId?: number
}

export const useClientPersons = ({
  clientId,
}: UseClientPersonsProps): UseQueryResult<ClientPerson[], APIErrorResponse> =>
  useQueryList({
    url: `/clients/${clientId}/persons/`,
    options: {
      enabled: !!clientId,
    },
  })
