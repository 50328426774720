import * as yup from 'yup'
import translation from 'i18next'
import { mapRules } from '@/utils'

export const quotationResearchSchema = yup.object({
  name: yup.string().required(),
  species: yup
    .array()
    .of(
      yup.object({
        value: yup.object(),
        title: yup.string(),
      })
    )
    .min(1, translation.t('errors:validation.array_min', { min: 1 })),
  research_types: yup.lazy((map) =>
    yup.object(
      mapRules(
        map,
        yup
          .array()
          .of(
            yup.object({
              value: yup.string(),
              title: yup.string(),
            })
          )
          .min(1, translation.t('errors:validation.array_min', { min: 1 }))
      )
    )
  ),
})
