import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, Stack, Button, Pagination } from '@mui/material'
import { AuthorizationGuard, EmptyListData, Loader } from '@/components'
import {
  QuotationStatusSelect,
  ProjectLeadersMultiSelect,
} from '@/components/inputs'
import { useFilters, useQuotations, UseQuotationsParams } from '@/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import {
  DEFAULT_LIST_LIMIT,
  DEFAULT_LIST_OFFSET,
  ROUTES_PATH,
} from '@/constants'
import { QuotationsTable } from '@/features/quotations/QuotationsTable/QuotationsTable'
import { PaginatedListResponse, Quotation, UserGroups } from '@/types'

export const QuotationsOverviewContainer = (): JSX.Element => {
  const { t } = useTranslation('quotations')
  const navigate = useNavigate()

  const defaultFiltersParams: UseQuotationsParams = {
    status: undefined,
    project_leader: undefined,
    search: undefined,
    limit: DEFAULT_LIST_LIMIT,
    offset: DEFAULT_LIST_OFFSET,
  }

  const {
    filterParams,
    handleFieldValueChange,
    initPagesCount,
    pagesCount,
    page,
    onPageChange,
  } = useFilters<
    UseQuotationsParams,
    PaginatedListResponse<Quotation>,
    Quotation
  >(defaultFiltersParams)

  const { data, isLoading } = useQuotations(filterParams)

  useEffect(() => {
    if (data && data.count) {
      initPagesCount(data?.count)
    }
  }, [data])

  return (
    <Stack data-testid="quotations-overview-table">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={5.75}
      >
        <Stack direction="row" spacing={6.75}>
          <QuotationStatusSelect
            value={filterParams.status as string}
            onChange={(value) => handleFieldValueChange(value, 'status')}
          />
          <ProjectLeadersMultiSelect
            value={filterParams.project_leader as string}
            onChange={(value) =>
              handleFieldValueChange(value, 'project_leader')
            }
            placeholder={t('placeholders.projectsLeaders')}
          />
        </Stack>
        <AuthorizationGuard
          allowedGroups={[UserGroups.MANAGER]}
          redirect={false}
        >
          <Button onClick={() => navigate(ROUTES_PATH.QUOTATION_CREATE)}>
            {t('action.create')}
            <FontAwesomeIcon style={{ paddingLeft: '10px' }} icon={faPlus} />
          </Button>
        </AuthorizationGuard>
      </Stack>

      {data && data.count ? (
        <>
          <QuotationsTable rows={data.results} />

          {pagesCount > 1 && (
            <Box alignSelf="center" mt={16}>
              <Pagination
                count={pagesCount}
                page={page}
                onChange={onPageChange}
              />
            </Box>
          )}
        </>
      ) : (
        <Box my={20}>
          {isLoading && <Loader />}

          {!isLoading && !data?.count && (
            <EmptyListData textKey="quotations:noQuotations" />
          )}
        </Box>
      )}
    </Stack>
  )
}
