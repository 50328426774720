import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { useApiClient } from '@/hooks'
import { unwrapErrorResponse, unwrapResponse } from '@/utils'
import {
  APIErrorResponse,
  QuotationResearchSpecie,
  PostQuotationResearchSpecie,
} from '@/types'

export const usePostQuotationResearchSpecie = (
  quotationId: number
): UseMutationResult<
  QuotationResearchSpecie,
  APIErrorResponse,
  PostQuotationResearchSpecie & { research_id: number }
> => {
  const client = useApiClient()

  return useMutation((params) =>
    client
      .post(
        `/quotations/${quotationId}/researches/${params.research_id}/species/`,
        {
          research_type_id: params.research_type_id,
          specie_id: params.specie_id,
        }
      )
      .then(unwrapResponse)
      .catch(unwrapErrorResponse)
  )
}
