import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Grid, Stack } from '@mui/material'
import { Edit, XSquare } from 'react-feather'
import { ModalLayout, CardAttribute } from '@/components'
import { SoundRecordFormContainer } from '@/components/forms'
import { getFileNameFromUrl, getSpeciesWithResearchTypesString } from '@/utils'
import dayjs from 'dayjs'
import { SoundRecord as SoundRecordType } from '@/types'
import { DATE_FORMAT_DMY_TIME } from '@/constants'

export type SoundRecordProps = {
  soundRecord: SoundRecordType
}

export const SoundRecord = ({ soundRecord }: SoundRecordProps): JSX.Element => {
  const { t } = useTranslation('soundRecords')

  const [data, setData] = useState<SoundRecordType>(soundRecord)

  const [isSoundRecordEdit, setIsSoundRecordEdit] = useState(false)

  return (
    <ModalLayout
      title={t(`titles.soundRecordDetailTitle`, { id: data.id })}
      cardContentSx={{ maxHeight: '70vh', overflowY: 'auto' }}
    >
      <Grid container={true} rowSpacing={8.25} width={{ md: 925, sm: 'auto' }}>
        <Grid xs={12} item={true} container={true} spacing={4}>
          <Grid xs={6} item={true}>
            <CardAttribute
              title={t('sound-record.fieldTitles.projectNumber')}
              value={
                data.observation_log.research_visit_event?.research_area.project
                  .project_number
              }
            />
          </Grid>
          <Grid xs={6} item={true}>
            <CardAttribute
              title={t('sound-record.fieldTitles.projectName')}
              value={
                data.observation_log.research_visit_event?.research_area.project
                  .name
              }
            />
          </Grid>
          <Grid xs={9} item={true}>
            <CardAttribute
              title={t('sound-record.fieldTitles.species')}
              value={getSpeciesWithResearchTypesString(
                data.observation_log.research_visit_event
              )}
            />
          </Grid>
        </Grid>

        <Grid xs={12} item={true} container={true} spacing={4}>
          <Grid xs={3} item={true}>
            <CardAttribute
              title={t('sound-record.fieldTitles.createdAt')}
              value={
                data.created_at
                  ? dayjs(data.created_at).format(DATE_FORMAT_DMY_TIME)
                  : '-'
              }
            />
          </Grid>
          <Grid xs={3} item={true}>
            <CardAttribute
              title={t('sound-record.fieldTitles.createdBy')}
              value={`${data.created_by?.first_name ?? '-'} ${
                data.created_by?.last_name ?? ''
              }`}
            />
          </Grid>
          <Grid xs={6} item={true}>
            <CardAttribute
              title={t('sound-record.fieldTitles.researchers')}
              value={
                data.observation_log.research_visit_event.researchers?.map(
                  ({ first_name, last_name }, i) =>
                    `${i > 0 ? ', ' : ''}${first_name} ${last_name}`
                ) ?? '-'
              }
            />
          </Grid>
          <Grid xs={3} item={true}>
            <CardAttribute
              title={t('sound-record.fieldTitles.analysed')}
              value={
                data.analysed ? t('sound-record.yes') : t('sound-record.no')
              }
            />
          </Grid>
          <Grid xs={3} item={true}>
            <CardAttribute
              title={t('sound-record.fieldTitles.analysedAt')}
              value={
                data.analysed_at
                  ? dayjs(data.analysed_at).format(DATE_FORMAT_DMY_TIME)
                  : '-'
              }
            />
          </Grid>
          <Grid xs={6} item={true}>
            <CardAttribute
              title={t('sound-record.fieldTitles.analysedBy')}
              value={`${data.analysed_by?.first_name ?? '-'} ${
                data.analysed_by?.last_name ?? ''
              }`}
            />
          </Grid>
          <Grid xs={12} item={true}>
            <CardAttribute
              title={t('sound-record.fieldTitles.soundRecordFile')}
              value={
                <a href={data.link} target="_blank" rel="noreferrer">
                  {getFileNameFromUrl(data.link)}
                </a>
              }
            />
          </Grid>
        </Grid>

        <Grid xs={12} item={true}>
          <Stack
            direction="row"
            alignItems="flex-start"
            justifyContent="space-between"
            spacing={4}
            mt={6}
          >
            <CardAttribute
              title={t('sound-record.fieldTitles.observationType')}
              value={data.observation_log.research_observation_log_type.name}
            />
            <Button
              onClick={() => setIsSoundRecordEdit(!isSoundRecordEdit)}
              variant="textThin"
              endIcon={
                isSoundRecordEdit ? <XSquare size={14} /> : <Edit size={14} />
              }
            >
              {isSoundRecordEdit
                ? t('buttons.closeEditing')
                : t('buttons.editSoundRecord')}
            </Button>
          </Stack>
        </Grid>

        <Grid xs={12} item={true} container={true} spacing={4}>
          {isSoundRecordEdit ? (
            <Grid xs={12} item={true}>
              <SoundRecordFormContainer
                researchObservationLogType={
                  data.observation_log.research_observation_log_type.code
                }
                data={data}
                setUpdatedSoundRecord={(data: SoundRecordType) => setData(data)}
                onSuccessUpdate={() => setIsSoundRecordEdit(false)}
              />
            </Grid>
          ) : (
            <>
              <Grid xs={12} item={true}>
                <CardAttribute
                  title={t('sound-record.fieldTitles.spectrogramLink')}
                  value={
                    !!data.spectrogram_link ? (
                      <a
                        href={data.spectrogram_link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {getFileNameFromUrl(data.spectrogram_link)}
                      </a>
                    ) : (
                      '-'
                    )
                  }
                />
              </Grid>
              <Grid xs={12} item={true}>
                <CardAttribute
                  title={t('sound-record.fieldTitles.specieIdentified')}
                  value={data.specie_identified ?? '-'}
                />
              </Grid>
              <Grid xs={12} item={true}>
                <CardAttribute
                  title={t('sound-record.fieldTitles.analysisNotes')}
                  value={data.analysis_notes ?? '-'}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </ModalLayout>
  )
}
