import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { useApiClient } from '@/hooks'
import { unwrapErrorResponse, unwrapResponse } from '@/utils/unwrapResponse'
import {
  APIErrorResponse,
  Quotation,
  PostQuotationAttachProject,
} from '@/types'

export const usePostQuotationAttachProject = (): UseMutationResult<
  Quotation,
  APIErrorResponse,
  PostQuotationAttachProject
> => {
  const client = useApiClient()

  return useMutation((params) =>
    client
      .post(`/quotations/${params.id}/attach_project/${params.project_id}/`)
      .then(unwrapResponse)
      .catch(unwrapErrorResponse)
  )
}
