import { Paper, Table, TableContainer } from '@mui/material'
import { MonitoringTableHead, MonitoringTableBody } from '@/features'
import { UseResearchAreasMonitoringParams } from '@/hooks'
import { ResearchAreaMonitoring } from '@/types'

export type MonitoringTableProps = {
  rows: ResearchAreaMonitoring[]
  filterParams: UseResearchAreasMonitoringParams
  openedAreas: number[]
  toggleAreaDetails: (id: number) => void
}

export const MonitoringTable = ({
  rows,
  filterParams,
  openedAreas,
  toggleAreaDetails,
}: MonitoringTableProps): JSX.Element => (
  <TableContainer component={Paper}>
    <Table>
      <MonitoringTableHead />
      <MonitoringTableBody
        openedAreas={openedAreas}
        rows={rows}
        filterParams={filterParams}
        toggleAreaDetails={toggleAreaDetails}
      />
    </Table>
  </TableContainer>
)
