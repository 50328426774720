import {
  APIErrorResponse,
  PaginatedListResponse,
  ResearchAreaMonitoring,
  SearchRequestParams,
} from '@/types'
import { UseQueryResult } from '@tanstack/react-query'
import { useQueryList } from './useQueryList'
import { QUERY_KEYS } from '@/constants'

export type UseResearchAreasMonitoringParams = SearchRequestParams & {
  id?: string
  project?: string
  client?: string
  researchAreaResearchers?: string
  uncertaintyReason?: string
  animal?: string
  project_leader?: string
  startDate?: string
  endDate?: string
}

export const useResearchAreasMonitoring = ({
  ...params
}: UseResearchAreasMonitoringParams): UseQueryResult<
  PaginatedListResponse<ResearchAreaMonitoring>,
  APIErrorResponse
> =>
  useQueryList({
    key: [...QUERY_KEYS.PLANNING_EVENTS_MANAGER, params],
    url: '/projects/research_areas/monitoring/',
    params: {
      ...(!!params.id && {
        id: params.id,
      }),
      ...(!!params.project && { project: params.project }),
      ...(!!params.client && { client: params.client }),
      ...(!!params.researchAreaResearchers && {
        researchers: params.researchAreaResearchers,
      }),
      ...(!!params.uncertaintyReason && {
        reason_of_uncertainty: params.uncertaintyReason,
      }),
      ...(!!params.animal && { animal: params.animal }),
      ...(!!params.project_leader && { project_leader: params.project_leader }),
      ...(!!params.startDate && { date_start: params.startDate }),
      ...(!!params.endDate && { date_end: params.endDate }),
      limit: params.limit,
      offset: params.offset,
    },
  })
