import * as yup from 'yup'
import {
  photosSchema,
  validatePhotos,
} from '@/components/inputs/ControlledMediaUpload/photosValidation'
import {
  BIRDS_BEHAVIOUR_REQUIRE_PHOTOS,
  DIRECTION_BIRD_GEDRAG_OPTION_VALUE,
  OTHER_GEDRAG_OPTION_VALUE,
  OTHER_SPECIE_OPTION_VALUE,
} from '@/components/forms/ObservationLogFormContainer/fieldsOptions'
import translation from 'i18next'

export const schema = yup.object({
  specie: yup.string().required(),
  other_specie: yup.string().when('specie', {
    is: OTHER_SPECIE_OPTION_VALUE,
    then: (schema) => schema.required(),
  }),
  amount: yup.string().required(),
  counting_method: yup.string().required(),
  behaviour: yup.string().required(),
  other_behaviour: yup.string().when('behaviour', {
    is: OTHER_GEDRAG_OPTION_VALUE,
    then: (schema) => schema.required(),
  }),
  behaviour_notes: yup.string().max(256),
  direction: yup.string().when('behaviour', {
    is: DIRECTION_BIRD_GEDRAG_OPTION_VALUE,
    then: (schema) => schema.required(),
  }),
  observed_at: yup.date().required(),
  photos: photosSchema.when('behaviour', {
    is: (behaviour: string) =>
      BIRDS_BEHAVIOUR_REQUIRE_PHOTOS.includes(behaviour),
    then: (schema) =>
      schema.test(
        'valid-photos',
        translation.t('errors:validation.requiredImagesWhenBehaviour'),
        validatePhotos
      ),
    otherwise: (schema) => schema.optional(),
  }),
})
