import { useTranslation } from 'react-i18next'
import { InvalidDataInfo } from '@/features'
import { Stack, Typography } from '@mui/material'
import {
  HoursReportInvalidVariable,
  HoursReportInvalidVariables,
} from '@/types'

export enum HourReportParams {
  RESEARCH_TIME = 'research_time',
  TRAVEL_TIME = 'travel_time',
  TRAVEL_DISTANCE = 'travel_distance',
  REPORTING_TIME = 'reporting_time',
}

export type HourReportParam = `${HourReportParams}`

const HoursReportParamInvalidVariables = {
  [HourReportParams.RESEARCH_TIME]: [
    HoursReportInvalidVariables.RESEARCH_TIME_IS_BIGGER_THAN_DEFINED_BY_PROTOCOL,
  ],
  [HourReportParams.TRAVEL_TIME]: [
    HoursReportInvalidVariables.TRAVEL_TIME_IS_BIGGER_THAN_STANDARD,
    HoursReportInvalidVariables.TRAVEL_TIME_IS_REPORTED_FOR_COUPLED_ROUND,
  ],
  [HourReportParams.TRAVEL_DISTANCE]: [
    HoursReportInvalidVariables.TRAVEL_DISTANCE_IS_BIGGER_THAN_CALCULATED,
  ],
  [HourReportParams.REPORTING_TIME]: [
    HoursReportInvalidVariables.REPORTING_TIME_IS_BIGGER_THAN_STANDARD,
  ],
}

type HoursReportItemProps = {
  name: HourReportParam
  value?: number | string
  invalidVariables?: HoursReportInvalidVariable[]
}

export const HoursReportItem = ({
  name,
  value,
  invalidVariables,
}: HoursReportItemProps): JSX.Element => {
  const { t } = useTranslation('hoursReporting')

  const invalidData = invalidVariables
    ?.filter((iv) =>
      HoursReportParamInvalidVariables[name].some((v) => v === iv)
    )
    .map((item) => t(`invalidVariable.${item}`))

  return (
    <Stack direction="row" alignItems="center">
      {!!invalidData?.length && (
        <InvalidDataInfo
          title={t(`hours-report.fieldTitles.invalidVariables`)}
          invalidData={invalidData}
        />
      )}
      <Typography
        variant="body2"
        color={!!invalidData?.length ? 'error.main' : 'primary.main'}
      >
        {value ?? '-'}
      </Typography>
    </Stack>
  )
}
