import { useForm, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Stack, Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { ControlledTextField, ControlledMediaUpload } from '@/components/inputs'
import { fieldReportInfoSchema } from './validationSchema'
import { FormComponentProps, PatchFieldReport } from '@/types'
import { yupResolver } from '@hookform/resolvers/yup'
import { PatchMedia } from '@/hooks'

export type FieldReportFormData = Omit<
  PatchFieldReport,
  'research_visit_event_id'
> & {
  photos?: PatchMedia
}

export const FieldReportForm = ({
  onSubmit,
  defaultValues,
  isLoading,
  apiErrorData,
}: FormComponentProps<FieldReportFormData>): JSX.Element => {
  const { t } = useTranslation()

  const methods = useForm<FieldReportFormData>({
    mode: 'onChange',
    defaultValues: {
      field_impression: '',
      round_impression: '',
      photos: undefined,
    },
    values: defaultValues,
    resolver: yupResolver(fieldReportInfoSchema),
  })

  const handleSubmit = methods.handleSubmit((formData) => {
    onSubmit(formData)
  })

  const handleReset = () =>
    methods.reset({
      ...defaultValues,
    })

  return (
    <FormProvider {...methods}>
      <Stack direction="column" spacing={4} mt={4} width="100%">
        <ControlledTextField
          name="field_impression"
          control={methods.control}
          placeholder={t(
            'dataValidation:visit-log.fieldTitles.fieldImpression'
          )}
          multiline={true}
          inputProps={{
            style: {
              padding: 0,
            },
          }}
          apiErrorData={apiErrorData}
        />
        <ControlledTextField
          name="round_impression"
          control={methods.control}
          placeholder={t(
            'dataValidation:visit-log.fieldTitles.roundImpression'
          )}
          multiline={true}
          inputProps={{
            style: {
              padding: 0,
            },
          }}
          apiErrorData={apiErrorData}
        />
        <ControlledMediaUpload
          name="photos"
          control={methods.control}
          label={t('dataValidation:visit-log.fieldTitles.photos')}
          errorMessagePrefix=""
        />
        {methods.formState.isDirty && (
          <Stack direction="row" spacing={5} pt={1}>
            <Button variant="textThin" color="secondary" onClick={handleReset}>
              {t('forms:buttons.cancel')}
            </Button>
            <LoadingButton
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              loading={isLoading}
              disableElevation={true}
              disabled={!methods.formState.isDirty}
            >
              <span>{t('forms:buttons.save')}</span>
            </LoadingButton>
          </Stack>
        )}
      </Stack>
    </FormProvider>
  )
}
