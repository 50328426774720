import {
  Project,
  PaginatedListResponse,
  SearchRequestParams,
  APIErrorResponse,
} from '@/types'
import { UseQueryResult } from '@tanstack/react-query'
import { useQueryList } from './useQueryList'

export type UseProjectsParams = SearchRequestParams & {
  search?: string
  researchers?: string
  project_leader?: string
  status?: string
  exclude_status?: string
}

export const useProjects = (
  { search, status, researchers, project_leader, ...params }: UseProjectsParams,
  enabled = true
): UseQueryResult<PaginatedListResponse<Project>, APIErrorResponse> =>
  useQueryList({
    url: '/projects/',
    options: {
      enabled,
    },
    params: {
      ...params,
      ...(search ? { search } : {}),
      ...(!!status && {
        status,
      }),
      ...(!!researchers && {
        researchers,
      }),
      ...(!!project_leader && {
        project_leader,
      }),
    },
  })
