import { useApiClient } from '@/hooks'
import { unwrapErrorResponse, unwrapResponse } from '@/utils/unwrapResponse'
import { Project } from '@/types/api/entities'
import { APIErrorResponse } from '@/types'
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import { getUseProjectKey } from '@/hooks/api/useProject'
import { QUERY_KEYS } from '@/constants'

export const usePostProjectFromQuotation = (
  quotationId: number
): UseMutationResult<Project, APIErrorResponse, undefined> => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  return useMutation(
    () =>
      client
        .post(`/projects/create_from_quotation/${quotationId}/`)
        .then(unwrapResponse)
        .catch(unwrapErrorResponse),
    {
      onSuccess: async (data) => {
        const queryKey = getUseProjectKey(data.id)

        queryClient.setQueryData(queryKey, data)
        await queryClient.invalidateQueries(QUERY_KEYS.PROJECTS)
      },
    }
  )
}
