import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { useApiClient, getUseObservationLogsKey } from '@/hooks'
import {
  APIErrorResponse,
  ObservationLog,
  PostCloneObservationLog,
} from '@/types'
import { unwrapErrorResponse, unwrapResponse } from '@/utils'

export type UsePostCloneObservationLogProps = {
  id: number
}

export const usePostCloneObservationLog = ({
  id,
}: UsePostCloneObservationLogProps): UseMutationResult<
  ObservationLog,
  APIErrorResponse,
  PostCloneObservationLog
> => {
  const client = useApiClient()

  const queryClient = useQueryClient()

  const queryKey = getUseObservationLogsKey()

  return useMutation(
    (params) =>
      client
        .post(`plannings/observation_logs/${id}/clone/`, params)
        .then(unwrapResponse)
        .catch(unwrapErrorResponse),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKey)
      },
    }
  )
}
