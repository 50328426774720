import { useTranslation } from 'react-i18next'
import {
  Button,
  Icon,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { VisitLogsTableProps } from '@/features'
import { Indicator } from '@/components/ui'
import dayjs from 'dayjs'
import { getSpeciesWithResearchTypesString } from '@/utils/getSpeciesWithResearchTypes'
import { DATE_FORMAT_DMY } from '@/constants'
import { AuthorizationGuard } from '@/components'
import { UserGroups } from '@/types'

export const VisitLogsTableBody = ({
  rows,
  showVisitLogDetails,
}: VisitLogsTableProps): JSX.Element => {
  const { t } = useTranslation('dataValidation')

  return (
    <>
      <TableBody>
        {rows?.map(
          ({
            id,
            research_visit_event,
            start_datetime,
            researchers,
            status,
          }) => (
            <TableRow key={id}>
              <TableCell>{id}</TableCell>
              <TableCell component="th" scope="row">
                {research_visit_event?.research_area.project.client_name ?? '-'}
              </TableCell>
              <TableCell>
                {research_visit_event?.research_area.project.name ?? '-'}
              </TableCell>
              <TableCell>
                {research_visit_event?.research_area.project.project_number ??
                  '-'}
              </TableCell>
              <TableCell>
                <Indicator
                  isSmall={false}
                  status={status}
                  name={
                    research_visit_event?.research_area.id
                      ? `${research_visit_event?.research_area.id}`
                      : '-'
                  }
                />
              </TableCell>
              <TableCell>
                {research_visit_event?.is_event_approved
                  ? t(`visit-log.yes`)
                  : t(`visit-log.no`)}
              </TableCell>
              <TableCell>
                {research_visit_event &&
                  getSpeciesWithResearchTypesString(research_visit_event)}
              </TableCell>
              <TableCell>
                {researchers?.map(
                  ({ first_name, last_name }, i) =>
                    `${i > 0 ? ', ' : ''}${first_name} ${last_name}`
                ) ?? '-'}
              </TableCell>
              <TableCell>
                {start_datetime
                  ? dayjs(start_datetime).format(DATE_FORMAT_DMY)
                  : '-'}
              </TableCell>
              <TableCell align="right">
                <Button
                  variant="text"
                  onClick={() => showVisitLogDetails(id)}
                  endIcon={
                    <Icon
                      sx={{
                        fontSize: 14,
                        lineHeight: '21px',
                        fontWeight: 600,
                      }}
                    >
                      <ArrowForwardIcon sx={{ height: 14, width: 14 }} />
                    </Icon>
                  }
                >
                  <Typography variant="body2" fontWeight={600}>
                    <AuthorizationGuard
                      allowedGroups={[UserGroups.MANAGER]}
                      redirect={false}
                      fallback={t('buttons.view')}
                    >
                      {status === 'invalid'
                        ? t('buttons.validateData')
                        : t('buttons.view')}
                    </AuthorizationGuard>
                  </Typography>
                </Button>
              </TableCell>
            </TableRow>
          )
        )}
      </TableBody>
    </>
  )
}
