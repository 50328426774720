import { useFormContext, Controller } from 'react-hook-form'
import { PatchQuotation } from '@/types'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

export type QuotationInvoiceTextFormData = Pick<PatchQuotation, 'general_text'>

export const QuotationInvoiceTextForm = () => {
  const { control } = useFormContext<QuotationInvoiceTextFormData>()

  return (
    <Controller
      name="general_text"
      control={control}
      render={({ field }) => <ReactQuill {...field} theme="snow" />}
    />
  )
}
