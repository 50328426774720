import { useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import {
  Stack,
  Box,
  Typography,
  Button,
  IconButton,
  TextField,
} from '@mui/material'
import { Edit, X } from 'react-feather'
import {
  QuotationResearchRoundForm,
  QuotationResearchRoundFormData,
} from '@/features'
import { FormActionButtons } from '@/features/quotationCreate/FormActionButtons'
import {
  useMutationWrapper,
  usePatchQuotationResearchRound,
  useDeleteQuotationResearchRound,
  getUseQuotationDetailsKey,
} from '@/hooks'
import { useConfirmationModal } from '@/hooks/useConfirmationModal'
import { QuotationResearchDetails, QuotationResearchRound } from '@/types'
import { useQueryClient } from '@tanstack/react-query'
import { quotationResearchRoundsSchema } from './validationSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { theme } from '@/theme'
import { COUPLED_ROUND_VISIT_DURATION } from '@/constants'

type QuotationResearchRoundFormContainerProps = {
  quotationId: number
  data: QuotationResearchDetails
  onEdit: (id: number) => void
}

export const QuotationResearchRoundFormContainer = ({
  quotationId,
  data,
  onEdit,
}: QuotationResearchRoundFormContainerProps): JSX.Element => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const { setConfirmationOpen } = useConfirmationModal()

  const { mutateAsync: updateResearchRound, isLoading: isMutating } =
    useMutationWrapper(() =>
      usePatchQuotationResearchRound(quotationId, data.id)
    )

  const { mutateAsync: deleteResearchRound, isLoading: isDeleting } =
    useMutationWrapper(() =>
      useDeleteQuotationResearchRound(quotationId, data.id)
    )

  const defaultValues = useMemo(() => {
    const rounds: QuotationResearchRoundFormData = {}

    data.quotation_research_rounds?.forEach((round) => {
      rounds[`${round.id}`] = {
        id: round.id,
        description: round.description ?? '',
        number_of_researchers: round.number_of_researchers,
        number_of_rounds: round.number_of_rounds,
        round_duration_minutes: round.round_duration_minutes,
        price_per_hour: round.price_per_hour,
        coupled_rounds: round.coupled_rounds,
      }
    })

    return rounds
  }, [data.quotation_research_rounds])

  const methods = useForm<QuotationResearchRoundFormData>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(quotationResearchRoundsSchema),
  })

  useEffect(() => {
    if (data.quotation_research_rounds) {
      methods.reset(defaultValues)
    }
  }, [data.quotation_research_rounds, methods, defaultValues])

  const deleteRound = (roundId: number) => {
    setConfirmationOpen(
      t('quotations:titles.deleteConfirmation'),
      t('quotations:titles.deleteRound'),
      async () => {
        try {
          await deleteResearchRound({ id: roundId })

          const quotationQueryKey = getUseQuotationDetailsKey(quotationId)

          await queryClient.invalidateQueries(quotationQueryKey)
        } catch (e) {
          enqueueSnackbar(`${t('errors:api.execute')}: ${e}`, {
            variant: 'error',
          })
        }
      }
    )
  }

  const handleSubmit = methods.handleSubmit(async (formData) => {
    enqueueSnackbar('Saving...', {
      variant: 'default',
    })

    const updatedRounds: Promise<QuotationResearchRound>[] = []

    Object.keys(formData).forEach((roundId) => {
      updatedRounds.push(
        updateResearchRound({
          id: Number(roundId),
          description: formData[roundId].description,
          number_of_researchers: formData[roundId].number_of_researchers,
          number_of_rounds: formData[roundId].number_of_rounds,
          round_duration_minutes: formData[roundId].round_duration_minutes,
          price_per_hour: formData[roundId].price_per_hour,
          coupled_rounds: formData[roundId].coupled_rounds,
        })
      )
    })

    try {
      await Promise.all(updatedRounds)

      const quotationQueryKey = getUseQuotationDetailsKey(quotationId)

      await queryClient.invalidateQueries(quotationQueryKey)

      closeSnackbar()
    } catch (e) {
      enqueueSnackbar(`${t('errors:api.execute')}: ${e}`, {
        variant: 'error',
      })
    }
  })

  const handleReset = () =>
    methods.reset({
      ...defaultValues,
    })

  const rounds = methods.watch()

  const calculateRoundValue = (key: string) => {
    const numberOfRounds = methods.getValues(`${key}.number_of_rounds`) ?? 0
    const coupledRounds = methods.getValues(`${key}.coupled_rounds`) ?? 0
    const numberOfResearchers =
      methods.getValues(`${key}.number_of_researchers`) ?? 0
    const roundDurationMinutes =
      methods.getValues(`${key}.round_duration_minutes`) ?? 0

    return (
      ((numberOfRounds - coupledRounds) *
        numberOfResearchers *
        roundDurationMinutes +
        coupledRounds * numberOfResearchers * COUPLED_ROUND_VISIT_DURATION) /
      60
    )
  }

  const totalHours =
    rounds &&
    Object.keys(rounds)
      .map((key) => calculateRoundValue(key))
      .reduce((a, b) => a + b, 0)
      .toFixed(1)

  const totalPrice =
    rounds &&
    Object.keys(rounds)
      .map((key) => {
        const pricePerHour =
          Number(methods.getValues(`${key}.price_per_hour`)) ?? 0

        return calculateRoundValue(key) * pricePerHour
      })
      .reduce((a, b) => a + b, 0)
      .toFixed(1)

  return (
    <>
      <Typography variant="h6" sx={{ mt: 8 }}>
        {data.title}
      </Typography>
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          my: 3,
        }}
      >
        <Stack direction="row" justifyContent="flex-start" spacing={10}>
          <Box>
            <Typography variant="body2" color="grey">
              {t('quotations:titles.researchSpecies')}
            </Typography>
            <Typography variant="body1">{data.species}</Typography>
          </Box>
          <Box>
            <Typography variant="body2" color="grey">
              {t('quotations:titles.researchTypes')}
            </Typography>
            <Typography variant="body1">{data.research_types}</Typography>
          </Box>
        </Stack>
        <Button
          variant="text"
          onClick={() => onEdit(data.id)}
          startIcon={<Edit size="16px" />}
        >
          {t('quotations:buttons.editResearchTypes')}
        </Button>
      </Stack>
      <FormProvider {...methods}>
        <Stack spacing={5}>
          {data.quotation_research_rounds.map((round) => (
            <Stack direction="row" alignItems="center" key={round.id}>
              <QuotationResearchRoundForm round={round} />
              {data.quotation_research_rounds.length > 1 && (
                <IconButton onClick={() => deleteRound(round.id)}>
                  <X size="16px" color={theme.palette.green['500']} />
                </IconButton>
              )}
            </Stack>
          ))}
          <Stack direction="row" justifyContent="flex-end">
            <Box width={230}>
              <TextField
                value={totalHours}
                label={t('quotations:placeholders.totalHours')}
                disabled={true}
              />
            </Box>
          </Stack>
          <Stack direction="row" justifyContent="flex-end">
            <Box width={230}>
              <TextField
                value={totalPrice}
                label={t('quotations:placeholders.totalPrice')}
                disabled={true}
              />
            </Box>
          </Stack>
        </Stack>
        {methods.formState.isDirty && (
          <FormActionButtons
            cancelClick={handleReset}
            saveClick={handleSubmit}
            isLoading={isMutating || isDeleting}
            disabled={!methods.formState.isDirty}
          />
        )}
      </FormProvider>
    </>
  )
}
