import { useUsersManagers } from '@/hooks'
import {
  SearchAutocomplete,
  SearchAutocompleteOption,
} from '@/components/inputs'

type ProjectLeadersMultiSelectProps = {
  value?: string
  onChange: (value: string) => void
  placeholder: string
}

export const ProjectLeadersMultiSelect = ({
  value,
  onChange,
  placeholder,
}: ProjectLeadersMultiSelectProps): JSX.Element => {
  const { data: leaders } = useUsersManagers()

  return (
    <SearchAutocomplete
      value={value}
      onChange={onChange}
      options={leaders?.map(
        ({ id, first_name, last_name, email }) =>
          ({
            label: first_name
              ? `${id}: ${first_name} ${last_name}`
              : `${id}: ${email}`,
            value: id.toString(),
          } as SearchAutocompleteOption)
      )}
      sort={true}
      placeholder={placeholder}
      fieldSx={{ width: { xs: 390, md: 300 } }}
    />
  )
}
