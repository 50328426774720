import { useApiClient, getResearchAreasKey } from '@/hooks'
import { ApiErrorData, APIErrorResponse, ResearchArea } from '@/types'
import { unwrapErrorResponse, unwrapResponse } from '@/utils'
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'

export const usePatchProjectResearchArea = (
  projectId: number
): UseMutationResult<
  ResearchArea,
  APIErrorResponse<ApiErrorData>,
  Omit<ResearchArea, 'research_area_protocols'>
> => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  return useMutation(
    (params) =>
      client
        .patch<ResearchArea>(
          `/projects/${projectId}/research_areas/${params.id}/`,
          params
        )
        .then(unwrapResponse)
        .catch(unwrapErrorResponse),
    {
      onSuccess: (data) => {
        const queryKey = getResearchAreasKey(projectId)

        const queryData = queryClient.getQueryData(queryKey) as ResearchArea[]

        if (queryData) {
          const newData = queryData.map((item) =>
            item.id === data.id ? data : item
          )

          queryClient.setQueryData(queryKey, newData)
        }
      },
    }
  )
}
