import * as yup from 'yup'
import { mapRules } from '@/utils'
import translation from 'i18next'

export const quotationPaymentsTermsSchema = yup.lazy((map) =>
  yup.object(
    mapRules(
      map,
      yup.object({
        amount_percentage: yup
          .number()
          .typeError(translation.t('errors:validation.numberType'))
          .min(1, translation.t('errors:validation.min', { min: 1 }))
          .required(),
        description: yup.string().required(),
        due_date: yup.date().typeError('invalid_date').required().nullable(),
      })
    )
  )
)
