import {
  HoursReport,
  PaginatedListResponse,
  APIErrorResponse,
  SearchRequestParams,
} from '@/types'
import { UseQueryResult } from '@tanstack/react-query'
import { useQueryList } from '../useQueryList'
import { QUERY_KEYS } from '@/constants'

export type HoursReportsSearchParams = SearchRequestParams & {
  id?: string
  researchers?: string
  contractors?: string
  status?: string
  startDate?: string
  endDate?: string
}

export const getUseHoursReportsKey = (): string[] =>
  QUERY_KEYS.HOURS_REPORTS.slice(0, 2) as string[]

export const useHoursReports = (
  { ...params }: HoursReportsSearchParams,
  enabled = true
): UseQueryResult<PaginatedListResponse<HoursReport>, APIErrorResponse> =>
  useQueryList({
    url: '/hours_reporting/hr/',
    options: {
      enabled,
    },
    params: {
      ...(!!params.id && {
        id: params.id,
      }),
      ...(!!params.researchers && {
        researchers: params.researchers,
      }),
      ...(!!params.contractors && {
        contractors: params.contractors,
      }),
      ...(!!params.status && {
        status: params.status,
      }),
      ...(!!params.startDate && { date_start: params.startDate }),
      ...(!!params.endDate && { date_end: params.endDate }),
      limit: params.limit,
      offset: params.offset,
    },
  })
