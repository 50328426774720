import { useTranslation } from 'react-i18next'
import { Link, Stack, Typography } from '@mui/material'
import { Loader } from '@/components'
import { useQuotationDetails } from '@/hooks'
import { QuotationResearchRoundDetails } from '@/features'
import { getPath } from '@/utils'
import { ProjectQuotation } from '@/types'
import { ROUTES_PATH } from '@/constants'

type ProjectQuotationResearchesProps = {
  quotation: ProjectQuotation
  showRounds: boolean
}

export const ProjectQuotationResearches = ({
  quotation,
  showRounds = false,
}: ProjectQuotationResearchesProps): JSX.Element => {
  const { t } = useTranslation('projects')

  const { data: quotationDetails } = useQuotationDetails({ id: quotation.id })

  if (!quotationDetails) return <Loader />

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" mx={1}>
          {t('titles.quotationId', { id: quotation.id })}
        </Typography>
        <Link
          href={getPath(ROUTES_PATH.QUOTATION_DETAIL, {
            id: quotation.id,
          })}
          target="_blank"
          rel="noopener noreferrer"
          color="primary.light"
          alignItems="center"
        >
          {t('buttons.quotationDetails')}
        </Link>
      </Stack>
      {quotationDetails.researches.map((quotationResearch) => (
        <QuotationResearchRoundDetails
          key={quotationResearch.id}
          data={quotationResearch}
          showRounds={showRounds}
        />
      ))}
    </>
  )
}
