import { useTranslation } from 'react-i18next'
import {
  SearchAutocomplete,
  SearchAutocompleteOption,
} from '@/components/inputs'
import { UncertaintyReasons } from '@/types'

type UncertaintyReasonMultiSelectProps = {
  value?: string
  onChange: (value: string | undefined) => void
}

export enum UncertaintyReasonGroups {
  ALGEMENE_PLANNINGCONTROLE = 'planning_control_group',
  GIERZWALUW = 'gierzwaluw_group',
  MASSAWINTER = 'massawinter_group',
  MEERVLEERMUIS = 'meervleermuis_group',
  PAARONDERZOEK = 'paaronderzoek_group',
  RUIGE_DWERGVLEERMUIS = 'ruige_dwergvleermuis_group',
  VELDVERSLAG = 'veldverslag_group',
  VLEERMUIS_VOORJAAR = 'vleermuis_voorjaar_group',
  SMP_HAARLEM = 'smp_haarlem_group',
  GROTE_GEBIEDEN = 'grote_gebieden_group',
}

const UncertaintyReasonOtherGroup = 'other_group'

export const groupedUncertaintyReasons = [
  {
    group_name: UncertaintyReasonGroups.ALGEMENE_PLANNINGCONTROLE,
    values: [
      UncertaintyReasons.NUMBER_OF_RESEARCHES_IS_NOT_ACCORDING_TO_ROUND_DETAILS,
      UncertaintyReasons.RESEARCHER_HAS_ANOTHER_ROUND_PLANNED_ON_THE_SAME_DATE_TIME,
      UncertaintyReasons.INVALID_VISIT_LOG,
      UncertaintyReasons.DATE_RANGE,
      UncertaintyReasons.END_TIME_RELATIVE_TO_SUNRISE,
      UncertaintyReasons.PERIOD_BETWEEN_VISITS,
      UncertaintyReasons.INVALID_PLANNING,
      UncertaintyReasons.START_TIME_RELATIVE_TO_SUNSET,
      UncertaintyReasons.REJECTED_ROUND,
      UncertaintyReasons.PAST_ROUND_WITHOUT_CONDUCTED,
      UncertaintyReasons.NOT_PLANNED,
      UncertaintyReasons.REGENERATED_ROUND,
      UncertaintyReasons.RUN_OUT,
      UncertaintyReasons.RE_PLANNED,
    ],
  },
  {
    group_name: UncertaintyReasonGroups.GIERZWALUW,
    values: [
      UncertaintyReasons.DAYS_BETWEEN_FIRST_SWIFT_ROUND_AND_THIRD_IS_NOT_11,
      UncertaintyReasons.NO_ONE_SWIFT_ROUND_IN_JUNE,
      UncertaintyReasons.NO_ONE_SWIFT_ROUND_IN_JULY,
    ],
  },
  {
    group_name: UncertaintyReasonGroups.MASSAWINTER,
    values: [
      UncertaintyReasons.FIRST_MASSAWINTER_ROUND_IS_NOT_PLANNED_BEFORE_OR_ON_28_08,
      UncertaintyReasons.MASSAWINTER_ROUNDS_ARE_NOT_PLANNED_BEFORE_SEPTEMBER_10,
      UncertaintyReasons.DAYS_BETWEEN_NIGHT1_AND_NIGHT2_MASSAWINTER_IS_NOT_11,
      UncertaintyReasons.DAYS_BETWEEN_NIGHT2_AND_EVENING1_MASSAWINTER_AND_PAARONDERZOEK_IS_NOT_21,
      UncertaintyReasons.PAARROUND1_AND_MASSAWINTERRROUND1_RESEARCH_COMBINED_ARE_NOT_PLANNED_ON_SAME_DAY,
    ],
  },
  {
    group_name: UncertaintyReasonGroups.MEERVLEERMUIS,
    values: [
      UncertaintyReasons.MEERVLEERMUIS_ROUND_IS_NOT_PLANNED,
      UncertaintyReasons.FIRST_EVENING_ROUND_FOR_MEERVLEERMUIS_IS_NOT_PLANNED_BEFORE_AUGUST_20,
      UncertaintyReasons.FIRST_NIGHT_ROUND_FOR_GEWONE_WITH_MEERVLEERMUIS_IS_NOT_PLANNED_BEFORE_AUGUST_20,
      UncertaintyReasons.FIRST_PAARROUND_WITHOUT_MEERVLEERMUIS_IS_NOT_PLANNED_BEFORE_OR_ON_05_09,
      UncertaintyReasons.MEERVLEERMUIS_ROUNDS_ARE_NOT_PLANNED_BEFORE_SEPTEMBER_15,
    ],
  },
  {
    group_name: UncertaintyReasonGroups.PAARONDERZOEK,
    values: [
      UncertaintyReasons.DAYS_BETWEEN_EVENING1_AND_EVENING2_PAARONDERZOEK_IS_NOT_21,
    ],
  },
  {
    group_name: UncertaintyReasonGroups.RUIGE_DWERGVLEERMUIS,
    values: [UncertaintyReasons.TOUCH_TIME],
  },
  {
    group_name: UncertaintyReasonGroups.VELDVERSLAG,
    values: [UncertaintyReasons.VISIT_LOG_WITHOUT_REPORT],
  },
  {
    group_name: UncertaintyReasonGroups.VLEERMUIS_VOORJAAR,
    values: [
      UncertaintyReasons.DAYS_BETWEEN_BAT_EVENING_AND_MORNING_IS_NOT_21,
      UncertaintyReasons.DAYS_BETWEEN_BAT_EVENING_ROUNDS_IS_NOT_21,
      UncertaintyReasons.DAYS_BETWEEN_BAT_MORNING_ROUNDS_IS_NOT_21,
      UncertaintyReasons.NO_ONE_BAT_EVENING_ROUND_IN_JUNE,
      UncertaintyReasons.NO_ROUND_IN_A_MONTH,
    ],
  },
  {
    group_name: UncertaintyReasonGroups.SMP_HAARLEM,
    values: [
      UncertaintyReasons.FIRST_HAARLEM_MORNING_IS_NOT_PLANNED_BEFORE_OR_ON_20_06,
      UncertaintyReasons.FIRST_HAARLEM_HOUSE_SPARROW_MORNING_IS_NOT_PLANNED_BEFORE_OR_ON_04_05,
      UncertaintyReasons.MORNING_HAARLEM_HOUSE_SPARROW_ROUND_START_FROM_1H_AFTER_SUNRISE_AND_END_TILL_12,
    ],
  },
  {
    group_name: UncertaintyReasonGroups.GROTE_GEBIEDEN,
    values: [
      UncertaintyReasons.FIRST_LV_EVENING_IS_NOT_PLANNED_BEFORE_OR_ON_04_05,
      UncertaintyReasons.FIRST_MIDDERNACHTZWERMEN_EVENING_IS_NOT_PLANNED_BEFORE_OR_ON_20_08,
    ],
  },
]

const getGroupOfOption = (option: SearchAutocompleteOption) =>
  groupedUncertaintyReasons.find((g) =>
    g.values.includes(option.value as UncertaintyReasons)
  )?.group_name ??
  (Object.values(UncertaintyReasonGroups).includes(
    option.value as UncertaintyReasonGroups
  )
    ? option.value
    : UncertaintyReasonOtherGroup)

export const UncertaintyReasonMultiSelect = ({
  value,
  onChange,
}: UncertaintyReasonMultiSelectProps): JSX.Element => {
  const { t } = useTranslation('forms')

  const reasons = Object.keys(UncertaintyReasons).map((key) => {
    const reason = UncertaintyReasons[
      key as keyof typeof UncertaintyReasons
    ] as string

    return {
      label: t(`uncertaintyReason.${reason}`),
      value: reason,
    } as SearchAutocompleteOption
  })

  const groupOptions = Object.values(UncertaintyReasonGroups).map(
    (group) =>
      ({
        label: t(`uncertaintyReason.all_${group}`),
        value: group,
      } as SearchAutocompleteOption)
  )

  reasons.push(...groupOptions)
  reasons.sort((a, b) => {
    const a_group = getGroupOfOption(a)
    const b_group = getGroupOfOption(b)

    const compared = a_group.localeCompare(b_group)

    if (compared === 0) {
      return a.label.localeCompare(b.label)
    }

    return compared
  })

  const getOptionGroup = (option: SearchAutocompleteOption) =>
    t(`uncertaintyReason.${getGroupOfOption(option)}`)

  return (
    <SearchAutocomplete
      value={value}
      onChange={onChange}
      options={reasons}
      placeholder={t(`placeholders.uncertaintyReason`)}
      fieldSx={{ width: { xs: 390, md: 490 } }}
      grpupBy={getOptionGroup}
    />
  )
}
