import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { Stack, Box, TextField, Typography } from '@mui/material'
import { ControlledTextField } from '@/components/inputs'
import { QuotationResearchRound } from '@/types'
import { COUPLED_ROUND_VISIT_DURATION } from '@/constants'

export type QuotationResearchRoundFormData = {
  [key: string]: {
    id: number
    description: string
    number_of_researchers: number
    number_of_rounds: number
    round_duration_minutes: number
    price_per_hour: string
    coupled_rounds: number
  }
}

export type QuotationResearchRoundFormProps = {
  round: QuotationResearchRound
}

export const QuotationResearchRoundForm = ({
  round,
}: QuotationResearchRoundFormProps) => {
  const { t } = useTranslation()

  const { control, watch } = useFormContext<QuotationResearchRoundFormData>()

  return (
    <Stack
      direction={{ xs: 'column', xl: 'row' }}
      alignItems={{ xs: 'flex-start', xl: 'center' }}
      spacing={{ xs: 4, xl: 2 }}
      borderRadius={2.5}
      p={4}
      bgcolor="grey.50"
      width="100%"
    >
      <Typography width={80}>{round.round_type}</Typography>
      <Stack direction="row" spacing={2}>
        <Box width={320}>
          <ControlledTextField
            name={`${round.id}.description`}
            control={control}
            placeholder={t('quotations:placeholders.description')}
            errorMessagePrefix=""
          />
        </Box>
        <Box width={100}>
          <ControlledTextField
            name={`${round.id}.number_of_researchers`}
            control={control}
            type="number"
            placeholder={t('quotations:placeholders.numberOfResearchers')}
            inputProps={{ min: 1 }}
            errorMessagePrefix=""
          />
        </Box>
        <Box width={80}>
          <ControlledTextField
            name={`${round.id}.number_of_rounds`}
            control={control}
            type="number"
            placeholder={t('quotations:placeholders.numberOfRounds')}
            inputProps={{ min: 1 }}
            errorMessagePrefix=""
          />
        </Box>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Box width={90}>
          <TextField
            value={
              (watch(`${round.id}.number_of_rounds`) ?? 0) *
              (watch(`${round.id}.number_of_researchers`) ?? 0)
            }
            label={t('quotations:placeholders.visitTotal')}
            disabled={true}
          />
        </Box>
        <Box width={100}>
          <ControlledTextField
            name={`${round.id}.round_duration_minutes`}
            control={control}
            type="number"
            placeholder={t('quotations:placeholders.roundDuration')}
            inputProps={{ min: 1 }}
            errorMessagePrefix=""
          />
        </Box>
        <Box width={80}>
          <TextField
            value={(
              ((watch(`${round.id}.number_of_rounds`) -
                watch(`${round.id}.coupled_rounds`) ?? 0) *
                (watch(`${round.id}.number_of_researchers`) ?? 0) *
                (watch(`${round.id}.round_duration_minutes`) ?? 0) +
                (watch(`${round.id}.coupled_rounds`) ?? 0) *
                  (watch(`${round.id}.number_of_researchers`) ?? 0) *
                  COUPLED_ROUND_VISIT_DURATION) /
              60
            ).toFixed(1)}
            label={t('quotations:placeholders.hours')}
            disabled={true}
          />
        </Box>
        <Box width={90}>
          <ControlledTextField
            name={`${round.id}.price_per_hour`}
            control={control}
            type="number"
            placeholder={t('quotations:placeholders.price')}
            inputProps={{ min: 1 }}
            errorMessagePrefix=""
          />
        </Box>
        <Box width={110}>
          <ControlledTextField
            name={`${round.id}.coupled_rounds`}
            control={control}
            type="number"
            placeholder={t('quotations:placeholders.coupledRound')}
            inputProps={{
              min: 0,
              max: watch(`${round.id}.number_of_rounds`) - 1 || 0,
            }}
            errorMessagePrefix=""
          />
        </Box>
      </Stack>
    </Stack>
  )
}
