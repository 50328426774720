import { Stack, TextField, Box, Typography } from '@mui/material'
import { QuotationPaymentTerm } from '@/types'
import { useTranslation } from 'react-i18next'

type QuotationPaymentTermsSummaryProps = {
  data: QuotationPaymentTerm[]
}

export const QuotationPaymentsTermsSummary = ({
  data,
}: QuotationPaymentTermsSummaryProps): JSX.Element => {
  const { t } = useTranslation()

  return data.length > 0 ? (
    <>
      <Typography variant="h6" sx={{ mt: 8, mb: 4 }}>
        {t('quotations:titles.paymentTerms')}
      </Typography>
      <hr style={{ marginTop: 20, marginBottom: 20 }} />
      <Stack spacing={5}>
        {data.map((line, index) => (
          <Stack
            key={line.id}
            direction="row"
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
              borderRadius: 2.5,
              backgroundColor: 'grey.50',
              width: '100%',
              p: 4,
            }}
          >
            <Box width={110}>
              <TextField
                value={index + 1}
                label={t('quotations:placeholders.paymentOrder')}
                disabled={true}
              />
            </Box>
            <Box width={100}>
              <TextField
                value={line.amount_percentage}
                label={t('quotations:placeholders.amountPercentage')}
                disabled={true}
              />
            </Box>
            <Box width={280}>
              <TextField
                value={line.description}
                label={t('quotations:placeholders.description')}
                disabled={true}
                fullWidth={true}
              />
            </Box>
            <Box width={140}>
              <TextField
                value={line.due_date}
                label={t('quotations:placeholders.dueDate')}
                disabled={true}
                fullWidth={true}
              />
            </Box>
            <Box width={100}>
              <TextField
                value={line.subtotal_price}
                label={t('quotations:labels.totalExcVat')}
                disabled={true}
              />
            </Box>
            <Box width={100}>
              <TextField
                value={line.total_price}
                label={t('quotations:placeholders.totalPrice')}
                disabled={true}
              />
            </Box>
          </Stack>
        ))}
      </Stack>
    </>
  ) : (
    <></>
  )
}
