import * as yup from 'yup'
import translation from 'i18next'
import { mapRules } from '@/utils'

export const projectAreaSchema = yup.object({
  areaAddress: yup
    .object({
      locationName: yup.string(),
      locationLat: yup.string(),
      locationLong: yup.string(),
    })
    .nullable()
    .required(translation.t('errors:validation.invalid_place')),
  researchAreaProtocols: yup.lazy((map) =>
    yup
      .object(
        mapRules(
          map,
          yup.object().shape({
            protocol: yup.object({
              value: yup.object(),
              title: yup.string(),
            }),
            areaProtocolResearchers: yup.lazy((map) =>
              yup.object(
                mapRules(
                  map,
                  yup.object({
                    number_of_researchers: yup
                      .number()
                      .typeError(translation.t('errors:validation.numberType'))
                      .required()
                      .min(
                        1,
                        translation.t('errors:validation.min', { min: 1 })
                      )
                      .test((value, context) => {
                        const protocolNumberOfResearchers =
                          context.parent.required_number_of_researchers

                        if (value && value > protocolNumberOfResearchers) {
                          return context.createError({
                            message: translation.t(
                              'errors:validation.protocolNumberOfResearchers',
                              { protocolNumberOfResearchers }
                            ),
                          })
                        }

                        return true
                      }),
                    researchers: yup
                      .array()
                      .of(
                        yup.object({
                          value: yup.string(),
                          title: yup.string(),
                        })
                      )
                      .min(
                        1,
                        translation.t('errors:validation.array_min', {
                          min: 1,
                        })
                      ),
                    required_number_of_researchers: yup.number(),
                  })
                )
              )
            ),
          })
        )
      )
      .test((value, context) => {
        if (
          !value ||
          typeof value !== 'object' ||
          Object.keys(value).length === 0
        ) {
          return context.createError({
            message: translation.t('errors:validation.protocolsNumber'),
          })
        }

        return true
      })
  ),
})
