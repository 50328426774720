import { UserGroup, UserGroups } from '@/types'

export const isUserEmployee = (groups: UserGroup[]) =>
  groups.length === 1 && groups[0] === UserGroups.EMPLOYEE

export const isUserHR = (groups: UserGroup[]) =>
  groups.length === 1 && groups[0] === UserGroups.HR

export const isUserSoundTechnician = (groups: UserGroup[]) =>
  groups.length === 1 && groups[0] === UserGroups.SOUND_TECHNICIAN
