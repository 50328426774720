import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid, Stack, Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import {
  ControlledDateTimeField,
  ResearchersControlledAutocomplete,
  AutocompleteOption,
} from '@/components/inputs'
import { yupResolver } from '@hookform/resolvers/yup'
import { roundSchema } from '@/features/roundDetail/validationSchema'
import {
  FormComponentProps,
  ManagerResearchVisitEventWithProjectInfo,
} from '@/types'

export type RoundFormData = {
  startDatetime: string | null
  endDatetime: string | null
  researchers: AutocompleteOption[]
}

export type RoundFormProps = {
  round: ManagerResearchVisitEventWithProjectInfo
} & FormComponentProps<RoundFormData>

export const RoundForm = ({
  round,
  onSubmit,
  defaultValues,
  isLoading,
}: RoundFormProps): JSX.Element => {
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    watch,
    reset,
  } = useForm<RoundFormData>({
    mode: 'onChange',
    defaultValues: {
      startDatetime: null,
      endDatetime: null,
      researchers: [],
    },
    values: defaultValues,
    resolver: yupResolver(roundSchema),
  })

  const handleReset = () =>
    reset({
      ...defaultValues,
    })

  return (
    <form onSubmit={handleSubmit(async (data) => onSubmit(data))}>
      <Grid item={true} xs={12} container={true} my={2} spacing={4}>
        <Grid item={true} xs={12} xl={3}>
          <ControlledDateTimeField
            name="startDatetime"
            control={control}
            label={t('monitoring:titles.roundStartDate')}
            minutesStep={5}
            textFieldProps={{ fullWidth: false, sx: { width: 200 } }}
          />
        </Grid>
        <Grid item={true} xs={12} xl={3}>
          <ControlledDateTimeField
            name="endDatetime"
            control={control}
            label={t('monitoring:titles.roundEndDate')}
            minutesStep={5}
            textFieldProps={{ fullWidth: false, sx: { width: 200 } }}
          />
        </Grid>
        <Grid item={true} xs={12} xl={6}>
          <ResearchersControlledAutocomplete
            name="researchers"
            control={control}
            eventDate={watch('startDatetime')}
            eventEndDate={watch('endDatetime')}
            researchAreaId={round.research_area_id}
            protocolIds={[round.protocol_id]}
            eventId={round.id}
            latitude={round.research_area.location_lat}
            longitude={round.research_area.location_long}
          />
        </Grid>
        <Grid item={true} xl={12}>
          {isDirty && (
            <Stack direction="row" justifyContent="flex-end" spacing={5} pt={1}>
              <Button
                variant="textThin"
                color="secondary"
                onClick={handleReset}
              >
                {t('forms:buttons.cancel')}
              </Button>
              <LoadingButton
                variant="contained"
                color="secondary"
                type="submit"
                loading={isLoading}
                disableElevation={true}
                disabled={!isDirty}
              >
                <span>{t('forms:buttons.save')}</span>
              </LoadingButton>
            </Stack>
          )}
        </Grid>
      </Grid>
    </form>
  )
}
