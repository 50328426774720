import translation from 'i18next'
import * as yup from 'yup'
import { validateRecordingNumber } from '@/components/forms/ObservationLogFormContainer/validation'
import { photosSchema } from '@/components/inputs/ControlledMediaUpload/photosValidation'

const testSpecieDuplication = (
  value: string | undefined,
  context: yup.TestContext
) => {
  if (!!value) {
    const duplicate = Object.keys(context.parent).find(
      (key) =>
        key !== context.path &&
        key.search(/specie/) >= 0 &&
        context.parent[key] === value
    )

    if (duplicate) {
      const fieldIndex = duplicate.replace('specie', '') || '1'

      return context.createError({
        message: translation.t('errors:validation.specieDuplication', {
          specie: value,
          field:
            translation.t(
              'observationLogs:observation-log.fieldLabels.specie'
            ) + ` ${fieldIndex}`,
        }),
      })
    }
  }

  return true
}

export const schema = yup.object({
  specie: yup.string().required().test(testSpecieDuplication),
  specie2: yup.string().test(testSpecieDuplication),
  specie3: yup.string().test(testSpecieDuplication),
  specie4: yup.string().test(testSpecieDuplication),
  amount: yup.string().required(),
  amount2: yup
    .string()
    .when('specie2', (value, schema) => (value ? schema.required() : schema)),
  amount3: yup
    .string()
    .when('specie3', (value, schema) => (value ? schema.required() : schema)),
  amount4: yup
    .string()
    .when('specie4', (value, schema) => (value ? schema.required() : schema)),
  counting_method: yup.string().required(),
  counting_method2: yup
    .string()
    .when('specie2', (value, schema) => (value ? schema.required() : schema)),
  counting_method3: yup
    .string()
    .when('specie3', (value, schema) => (value ? schema.required() : schema)),
  counting_method4: yup
    .string()
    .when('specie4', (value, schema) => (value ? schema.required() : schema)),
  behaviour_notes: yup.string().max(256),
  recording_number: yup
    .string()
    .transform((value) => (value === '' || value === null ? undefined : value))
    .test(validateRecordingNumber),
  observed_at: yup.date().required(),
  photos: photosSchema,
})
