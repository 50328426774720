import { ModalLayout as EditResearchAreasModalLayout } from '@/components'
import { Box, CircularProgress, Stack } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { ProjectBasicInfoForm, ProjectBasicInfo } from '@/components/forms'
import {
  useProject,
  usePatchProject,
  useMutationWrapper,
  useLinkQuotationToProject,
} from '@/hooks'

export type EditInformationModalProps = {
  projectId: number
}

export const EditInformationModal = ({
  projectId,
}: EditInformationModalProps): JSX.Element => {
  const { t } = useTranslation('projects')

  const { enqueueSnackbar } = useSnackbar()

  const { data: project, isLoading } = useProject({ id: projectId })

  const { mutateAsync: extendProject, isLoading: mutateInProcess } =
    useMutationWrapper(usePatchProject.bind(null, projectId))

  const linkQuotations = useLinkQuotationToProject()

  const handleSubmit = async ({
    projectLeader,
    sharepointUrl,
    address,
    hasGisStatus,
    status,
    isSmp,
    isOnlyPlanning,
    isFieldReportRequired,
    quotations,
    reportingHoursPerVisit,
    travelTimePerVisit,
  }: ProjectBasicInfo) => {
    const projectExtendedData = {
      project_leader_id: projectLeader?.value || null,
      is_smp: isSmp,
      is_only_planning: isOnlyPlanning,
      is_field_report_required: isFieldReportRequired,
      sharepoint_url: sharepointUrl,
      address,
      reporting_hours_per_visit: reportingHoursPerVisit,
      travel_time_per_visit: travelTimePerVisit,
      is_in_gis: hasGisStatus,
      status,
    }

    try {
      const project = await extendProject(projectExtendedData, {
        successMessageKey: 'projects:detail.successEdit',
      })

      await linkQuotations(project, quotations)
    } catch (e) {
      enqueueSnackbar(`${t('errors:api.execute')}: ${e}`, {
        variant: 'error',
      })
    }
  }

  if (isLoading || !project)
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        <CircularProgress size="5rem" color="secondary" />
      </Stack>
    )

  return (
    <EditResearchAreasModalLayout title={t('detail.editProjectInfo')}>
      <Box width={{ md: 923, sm: 'auto' }} maxHeight="60vh" overflow="auto">
        <ProjectBasicInfoForm
          project={project}
          onSubmit={handleSubmit}
          controls={
            <LoadingButton
              variant="contained"
              color="secondary"
              type="submit"
              loading={mutateInProcess}
            >
              <span>{t('common:buttons.saveChanges')}</span>
            </LoadingButton>
          }
          isReadOnlyDate={true}
          hasStatusFields={true}
          showControlsOnDirty={true}
        />
      </Box>
    </EditResearchAreasModalLayout>
  )
}
