import { User, APIErrorResponse } from '@/types'
import { UseQueryResult } from '@tanstack/react-query'
import { useQueryList } from './useQueryList'

export const useUsersManagers = (
  enabled = true
): UseQueryResult<User[], APIErrorResponse> =>
  useQueryList({
    url: '/users/managers/',
    options: {
      enabled,
    },
  })
