import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CardLayout } from '@/components/layouts/CardLayout'
import { QuotationBasicInfo, QuotationBasicInfoForm } from '@/components/forms'
import { FooterActions } from '@/features'
import { usePostQuotation, useMutationWrapper } from '@/hooks'
import { getPath } from '@/utils'
import { ROUTES_PATH } from '@/constants'

export const QuotationCreateStep = (): JSX.Element => {
  const { t } = useTranslation('quotations')
  const navigate = useNavigate()

  const { mutateAsync: createQuotation, isLoading: mutateInProcess } =
    useMutationWrapper(() => usePostQuotation())

  const handleSubmit = async ({
    client_id,
    reference,
    is_smp,
    contact_person_ids,
    address,
    project_leader_id,
  }: QuotationBasicInfo) => {
    const quotationData = {
      client_id: Number(client_id?.value.id),
      reference,
      is_smp,
      contact_person_ids: contact_person_ids?.map((cp) => cp.value),
      address,
      project_leader_id: project_leader_id?.value || null,
    }

    await createQuotation(quotationData, {
      onSuccess: (data) => {
        const extendQuotationStep = getPath(ROUTES_PATH.QUOTATION_EXTEND, {
          id: data.id,
        })

        navigate(`${extendQuotationStep}?step=2`)
      },
    })
  }

  return (
    <CardLayout title={t('titles.clientInfo')} cardSx={{ pb: 0 }}>
      <QuotationBasicInfoForm
        onSubmit={handleSubmit}
        controls={
          <FooterActions
            isNextStepDisabled={false}
            mutateInProcess={mutateInProcess}
            onCancel={() => navigate(ROUTES_PATH.QUOTATIONS)}
          />
        }
      />
    </CardLayout>
  )
}
