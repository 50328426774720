import {
  SearchAutocomplete,
  SearchAutocompleteOption,
} from '@/components/inputs'
import { useUsersContractors } from '@/hooks/api/useUsersContractors'

type ContractorsMultiSelectProps = {
  value?: string
  onChange: (value: string | undefined) => void
  placeholder?: string
}

export const ContractorsMultiSelect = ({
  value,
  onChange,
  placeholder,
}: ContractorsMultiSelectProps): JSX.Element => {
  const { data: contractors } = useUsersContractors()

  return (
    <SearchAutocomplete
      value={value}
      onChange={onChange}
      options={contractors?.map(
        ({ id, name }) =>
          ({
            label: name,
            value: id.toString(),
          } as SearchAutocompleteOption)
      )}
      placeholder={placeholder}
      sort={true}
      fieldSx={{ width: 284 }}
    />
  )
}
