import { useState, useEffect, useRef, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { loadModules } from 'esri-loader'
import { Box, Stack, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { SPATIAL_REFERENCE } from './ResearchAreaGraphicMap.model'

let EsriRequest: typeof __esri.request

const ARCGIS_GENERATE_URL =
  'https://www.arcgis.com/sharing/rest/content/features/generate'

export type ResearchAreasImportMapProps = {
  addShapefileToMap: (responseData: __esri.RequestResponse['data']) => void
}

export const GraphicsMapShapesImport = ({
  addShapefileToMap,
}: ResearchAreasImportMapProps) => {
  const [file, setFile] = useState<File>()
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const [loadError, setLoadError] = useState()
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation()

  useEffect(() => {
    loadModules(['esri/request'], {
      css: true,
    })
      .then(([request]) => {
        EsriRequest = request
      })
      .catch((err) => {
        console.error(err)
      })
  }, [])

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLoadError(undefined)

    if (e.target.files) {
      setFile(e.target.files[0])
    }
  }

  const handleLoadFile = () => {
    if (!file || !EsriRequest) return
    setLoading(true)

    const formData = new FormData()

    formData.append('file', file)

    EsriRequest(ARCGIS_GENERATE_URL, {
      query: {
        filetype: 'shapefile',
        publishParameters: JSON.stringify({
          targetSR: {
            wkid: SPATIAL_REFERENCE,
          },
        }),
        f: 'json',
      },
      body: formData,
      responseType: 'json',
    })
      .then((response) => {
        addShapefileToMap(response.data)

        if (fileInputRef.current) {
          fileInputRef.current.value = ''
        }

        setFile(undefined)
      })
      .catch((e) => {
        setLoadError(e.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Stack direction="row" alignItems="center" spacing={4}>
      <Box>
        <Typography>{t('projects:areas.selectShapeFileText')}</Typography>
        <form encType="multipart/form-data" method="post">
          <input
            type="file"
            name="file"
            ref={fileInputRef}
            onChange={handleFileChange}
          />
        </form>
        <Typography color="error.main">{loadError}</Typography>
      </Box>
      <LoadingButton
        variant="contained"
        color="primary"
        onClick={handleLoadFile}
        loading={loading}
        disableElevation={true}
        disabled={!file}
      >
        {t('projects:areas.loadShapeFileBtn')}
      </LoadingButton>
    </Stack>
  )
}
