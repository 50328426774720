import { ResearchAreaProtocol, Researcher } from '@/types'

export const getUniqueResearchers = (
  data: ResearchAreaProtocol[]
): Researcher[] => {
  const uniqueResearchers = new Map<string, Researcher>()

  data.forEach((item) => {
    item.area_protocol_researchers.forEach(({ researchers }) => {
      if (researchers?.length) {
        researchers.forEach((researcher) => {
          const uniqueKey = `${researcher.id}`

          if (!uniqueResearchers.has(uniqueKey)) {
            uniqueResearchers.set(uniqueKey, researcher)
          }
        })
      }
    })
  })

  return Array.from(uniqueResearchers.values())
}
