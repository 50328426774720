import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { useApiClient } from '@/hooks'
import { unwrapErrorResponse, unwrapResponse } from '@/utils/unwrapResponse'
import {
  APIErrorResponse,
  Quotation,
  PostQuotationGenerateInvoice,
} from '@/types'

export const usePostQuotationGenerateInvoice = (
  quotationId: number
): UseMutationResult<
  Quotation,
  APIErrorResponse,
  PostQuotationGenerateInvoice
> => {
  const client = useApiClient()

  return useMutation((params) =>
    client
      .post(`/quotations/${quotationId}/generate_invoice/`, params)
      .then(unwrapResponse)
      .catch(unwrapErrorResponse)
  )
}
