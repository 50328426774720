import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { usePostEntityMedia, useDeleteEntityMedia } from '@/hooks'
import { MediaType, ModelsWithMedia, Photo, SoundRecord } from '@/types'

type UseUploadMediaParams = {
  modelKey: ModelsWithMedia
}

export type ChosenFile = {
  file: Blob
  link: string
}

export type PatchMedia = {
  chosenToUpload: ChosenFile[]
  uploadedToApi: Photo[] | SoundRecord[]
  stale: number[]
  mediaType: MediaType
}

type UploadMediaParams = {
  media: PatchMedia
  entityId: number
}

export const useUploadMedia = ({ modelKey }: UseUploadMediaParams) => {
  const { t } = useTranslation()

  const { enqueueSnackbar } = useSnackbar()

  const [isLoading, setIsLoading] = useState(false)

  const postMedia = usePostEntityMedia({ modelKey })

  const deleteMedia = useDeleteEntityMedia({ modelKey })

  const uploadMedia = async ({ media, entityId }: UploadMediaParams) => {
    setIsLoading(true)

    if (media && media.chosenToUpload) {
      const result = await postMedia({
        entityId,
        media,
      })

      const failedIndexes = [] as string[]

      result.forEach((response, index) => {
        if (response.status === 'rejected' || response.value.status !== 200) {
          failedIndexes.push(`#${index + 1}`)

          return
        }
      })

      if (failedIndexes.length) {
        enqueueSnackbar(
          t('errors:api.uploadFailed', {
            indexes: failedIndexes.join(', '),
          }),
          {
            variant: 'error',
            persist: true,
          }
        )
      }
    }

    if (media.stale) {
      for (const id of media.stale) {
        await deleteMedia({
          entityId,
          mediaId: id,
          mediaType: media.mediaType,
        })
      }
    }

    setIsLoading(false)
  }

  return { uploadMedia, isLoading }
}
