import { ModelsWithMedia } from '@/types'

export const getMediaBaseEndpoint = (modelKey: ModelsWithMedia) => {
  if (
    modelKey === ModelsWithMedia.visitLogs ||
    modelKey === ModelsWithMedia.observationLogs
  ) {
    return `/plannings/${modelKey}`
  }

  if (modelKey === ModelsWithMedia.fieldReports) {
    return '/field_reports'
  }

  throw new Error('Invalid model type')
}
