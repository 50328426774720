import { SearchAutocompleteOption } from '@/components/inputs'

const species = [
  {
    label: 'Baardvleermuis',
    value: 'Baardvleermuis',
  },
  {
    label: "Bechstein's vleermuis",
    value: "Bechstein's vleermuis",
  },
  {
    label: 'Bosvleermuis',
    value: 'Bosvleermuis',
  },
  {
    label: "Brandt's vleermuis",
    value: "Brandt's vleermuis",
  },
  {
    label: 'Franjestaart',
    value: 'Franjestaart',
  },
  {
    label: 'Gewone dwergvleermuis',
    value: 'Gewone dwergvleermuis',
  },
  {
    label: 'Gewone grootoorvleermuis',
    value: 'Gewone grootoorvleermuis',
  },
  {
    label: 'Grijze grootoorvleermuis',
    value: 'Grijze grootoorvleermuis',
  },
  {
    label: 'Ingekorven vleermuis',
    value: 'Ingekorven vleermuis',
  },
  {
    label: 'Kleine dwergvleermuis',
    value: 'Kleine dwergvleermuis',
  },
  {
    label: 'Laatvlieger',
    value: 'Laatvlieger',
  },
  {
    label: 'Meervleermuis',
    value: 'Meervleermuis',
  },
  {
    label: 'Rosse vleermuis',
    value: 'Rosse vleermuis',
  },
  {
    label: 'Ruige dwergvleermuis',
    value: 'Ruige dwergvleermuis',
  },
  {
    label: 'Tweekleurige vleermuis',
    value: 'Tweekleurige vleermuis',
  },
  {
    label: 'Vale vleermuis',
    value: 'Vale vleermuis',
  },
  {
    label: 'Watervleermuis',
    value: 'Watervleermuis',
  },
  {
    label: 'Onbekend', //OTHER
    value: 'Onbekend',
  },
  // BIRD
  {
    label: 'Boerenzwaluw',
    value: 'Boerenzwaluw',
  },
  {
    label: 'Boomklever',
    value: 'Boomklever',
  },
  {
    label: 'Boomkruiper',
    value: 'Boomkruiper',
  },
  {
    label: 'Bosuil',
    value: 'Bosuil',
  },
  {
    label: 'Ekster',
    value: 'Ekster',
  },
  {
    label: 'Gierzwaluw',
    value: 'Gierzwaluw',
  },
  {
    label: 'Houtduif',
    value: 'Houtduif',
  },
  {
    label: 'Huismus',
    value: 'Huismus',
  },
  {
    label: 'Huiszwaluw',
    value: 'Huiszwaluw',
  },
  {
    label: 'Kauw',
    value: 'Kauw',
  },
  {
    label: 'Kerkuil',
    value: 'Kerkuil',
  },
  {
    label: 'Koolmees',
    value: 'Koolmees',
  },
  {
    label: 'Ooievaar',
    value: 'Ooievaar',
  },
  {
    label: 'Pimpelmees',
    value: 'Pimpelmees',
  },
  {
    label: 'Roek',
    value: 'Roek',
  },
  {
    label: 'Spreeuw',
    value: 'Spreeuw',
  },
  {
    label: 'Stadsduif',
    value: 'Stadsduif',
  },
  {
    label: 'Steenuil',
    value: 'Steenuil',
  },
  {
    label: 'Zwarte roodstraat',
    value: 'Zwarte roodstraat',
  },
  {
    label: 'Duif spec.',
    value: 'Duif spec.',
  },
  {
    label: 'Torenvalk',
    value: 'Torenvalk',
  },
  {
    label: 'Turkse tortel',
    value: 'Turkse tortel',
  },
  {
    label: 'Kleine mantelmeeuw',
    value: 'Kleine mantelmeeuw',
  },
  {
    label: 'Meeuw spec.',
    value: 'Meeuw spec.',
  },
  {
    label: 'Scholekster',
    value: 'Scholekster',
  },
  {
    label: 'Visdief',
    value: 'Visdief',
  },
  {
    label: 'Zilvermeeuw',
    value: 'Zilvermeeuw',
  },
  {
    label: 'Boomvalk',
    value: 'Boomvalk',
  },
  {
    label: 'Buizerd',
    value: 'Buizerd',
  },
  {
    label: 'Grote gele kwikstaart',
    value: 'Grote gele kwikstaart',
  },
  {
    label: 'Havik',
    value: 'Havik',
  },
  {
    label: 'Heggemus',
    value: 'Heggemus',
  },
  {
    label: 'Oehoe',
    value: 'Oehoe',
  },
  {
    label: 'Ransuil',
    value: 'Ransuil',
  },
  {
    label: 'Roofvogel spec.',
    value: 'Roofvogel spec.',
  },
  {
    label: 'Slechtvalk',
    value: 'Slechtvalk',
  },
  {
    label: 'Sperwer',
    value: 'Sperwer',
  },
  {
    label: 'Zwarte kraai',
    value: 'Zwarte kraai',
  },
  {
    label: 'Anders, uitleg andere soort', //other
    value: 'Anders%2C uitleg andere soort',
  },
  //MAMALS
  {
    label: 'Boommarter',
    value: 'Boommarter',
  },
  {
    label: 'Bunzing',
    value: 'Bunzing',
  },
  {
    label: 'Bever',
    value: 'Bever',
  },
  {
    label: 'Eikelmuis',
    value: 'Eikelmuis',
  },
  {
    label: 'Konijn',
    value: 'Konijn',
  },
  {
    label: 'Korenwolf',
    value: 'Korenwolf',
  },
  {
    label: 'Mol',
    value: 'Mol',
  },
  {
    label: 'Muis spec.',
    value: 'Muis spec.',
  },
  {
    label: 'Rat',
    value: 'Rat',
  },
  {
    label: 'Waterspitsmuis',
    value: 'Waterspitsmuis',
  },
  {
    label: 'Das',
    value: 'Das',
  },
  {
    label: 'Eekhoorn',
    value: 'Eekhoorn',
  },
  {
    label: 'Egel',
    value: 'Egel',
  },
  {
    label: 'Haas',
    value: 'Haas',
  },
  {
    label: 'Hermelijn',
    value: 'Hermelijn',
  },
  {
    label: 'Steenmarter',
    value: 'Steenmarter',
  },
  {
    label: 'Vos',
    value: 'Vos',
  },
  {
    label: 'Wezel',
    value: 'Wezel',
  },
  {
    label: 'Wolf',
    value: 'Wolf',
  },
  // AMPHIBIANS
  {
    label: 'Alpenwatersalamander',
    value: 'Alpenwatersalamander',
  },
  {
    label: 'Bastaardkikker',
    value: 'Bastaardkikker',
  },
  {
    label: 'Boomkikker',
    value: 'Boomkikker',
  },
  {
    label: 'Bruine kikker',
    value: 'Bruine kikker',
  },
  {
    label: 'Geelbuikvuurpad',
    value: 'Geelbuikvuurpad',
  },
  {
    label: 'Gewone pad',
    value: 'Gewone pad',
  },
  {
    label: 'Groene kikker (complex)',
    value: 'Groene kikker (complex)',
  },
  {
    label: 'Heikikker',
    value: 'Heikikker',
  },
  {
    label: 'Kamsalamander',
    value: 'Kamsalamander',
  },
  {
    label: 'Kikker spec.',
    value: 'Kikker spec.',
  },
  {
    label: 'Kleine watersalamander',
    value: 'Kleine watersalamander',
  },
  {
    label: 'Knoflookpad',
    value: 'Knoflookpad',
  },
  {
    label: 'Meerkikker',
    value: 'Meerkikker',
  },
  {
    label: 'Pad spec.',
    value: 'Pad spec',
  },
  {
    label: 'Poelkikker',
    value: 'Poelkikker',
  },
  {
    label: 'Rugstreeppad',
    value: 'Rugstreeppad',
  },
  {
    label: 'Salamander spec.',
    value: 'Salamander spec.',
  },
  {
    label: 'Vinpootsalamander',
    value: 'Vinpootsalamander',
  },
  {
    label: 'Vroedmeesterpad',
    value: 'Vroedmeesterpad',
  },
  {
    label: 'Vuursalamander',
    value: 'Vuursalamander',
  },
  //Repitles
  {
    value: 'Adder',
    label: 'Adder',
  },
  {
    value: 'Gladde slang',
    label: 'Gladde slang',
  },
  {
    value: 'Hazelworm',
    label: 'Hazelworm',
  },
  {
    value: 'Levendbarende hagedis',
    label: 'Levendbarende hagedis',
  },
  {
    value: 'Muurhagedis',
    label: 'Muurhagedis',
  },
  {
    value: 'Ringslang',
    label: 'Ringslang',
  },
  {
    value: 'Zandhagedis',
    label: 'Zandhagedis',
  },
]

export const useSpecies = (): SearchAutocompleteOption[] =>
  species as SearchAutocompleteOption[]
