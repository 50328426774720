export enum UserGroups {
  CLIENT = 'client',
  MANAGER = 'manager',
  EMPLOYEE = 'employee',
  HR = 'hr',
  SOUND_TECHNICIAN = 'sound_technician',
}

export type UserGroup = `${UserGroups}`

export type User = {
  id: number
  groups: UserGroup[]
  email: string
  first_name: string | null
  last_name: string | null
  phone: string | null
  address_name: string | null
  address_long: string | null
  address_lat: string | null
  max_number_of_research_areas?: number | null
  is_senior: boolean | null
  notes: string | null
}

export enum QuotationStatuses {
  NEW = 'new',
  SENT = 'sent',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
}

export type QuotationStatus = `${QuotationStatuses}`

export type Quotation = {
  id: number
  client: Client
  project_leader?: User
  contact_persons?: ClientPerson[]
  reference: string
  is_smp: boolean
  address: string
  status: QuotationStatus
  created_at?: string
  updated_at?: string
  created_by?: User
  edited_by?: User
  invoice_link?: string
  project_id?: number
  general_text?: string
  bats_general_text?: string
  swift_general_text?: string
  housesparrow_general_text?: string
}

export type QuotationDetails = Quotation & {
  researches: QuotationResearchDetails[]
  local_date: string
  overhead: QuotationOverhead
  additional_lines: QuotationAdditional
  payment_terms: QuotationPaymentTerm[]
  subtotal_price: string
  tax_price: string
  total_price: string
}

export type PostQuotation = {
  client_id: number
  project_leader_id?: number | null
  contact_person_ids?: string[]
} & Pick<QuotationDetails, 'reference'> &
  Partial<Pick<QuotationDetails, 'is_smp' | 'address'>>

export type PatchQuotation = Partial<
  Pick<
    Quotation,
    'reference' | 'is_smp' | 'address' | 'status' | 'general_text'
  >
> &
  Pick<PostQuotation, 'contact_person_ids' | 'project_leader_id'>

export type QuotationResearch = {
  id: number
  title: string
}

export type QuotationResearchDetails = QuotationResearch & {
  quotation_research_rounds: QuotationResearchRound[]
  research_types: string
  species: string
  total_duration_minutes: number
  total_price: string
}

export type PostQuotationResearch = Pick<QuotationResearch, 'title'>

export type PatchQuotationResearch = PostQuotationResearch &
  Pick<QuotationResearch, 'id'>

export type PostQuotationResearchGenerate = Pick<QuotationResearch, 'id'>

export type QuotationResearchSpecie = {
  id: number
  research_type: ResearchType
  specie: Specie
}

export type PostQuotationResearchSpecie = {
  research_type_id: string
  specie_id: string
}

export type DeleteQuotationResearchSpecie = Pick<
  QuotationResearchSpecie,
  'id'
> & { research_id: number }

export type QuotationResearchRound = {
  id: number
  total_visits: string
  total_duration_minutes: number
  total_price: string
  description: string
  round_type: RoundType
  number_of_researchers: number
  number_of_rounds: number
  round_duration_minutes: number
  price_per_hour_currency: string
  price_per_hour: string
  coupled_rounds: number
}

export type PatchQuotationResearchRound = Pick<
  QuotationResearchRound,
  | 'id'
  | 'description'
  | 'number_of_researchers'
  | 'number_of_rounds'
  | 'round_duration_minutes'
  | 'price_per_hour'
  | 'coupled_rounds'
>

export type DeleteQuotationResearchRound = Pick<QuotationResearchRound, 'id'>

export type QuotationOverhead = {
  total_price: string
  lines: QuotationOverheadLine[]
}

export type QuotationOverheadLine = {
  id: number
  type: QuotationOverheadLineType
  amount: string
  number_of_visits: number
  amount_per_round: string
  price_per_unit: string
  total_price: string
}

export type PatchQuotationOverheadLine = Pick<
  QuotationOverheadLine,
  'id' | 'amount_per_round' | 'price_per_unit'
>

export type DeleteQuotationOverheadLine = Pick<QuotationOverheadLine, 'id'>

export type QuotationOverheadLineType =
  | 'travel_time'
  | 'reporting'
  | 'project_management'

export type QuotationAdditional = {
  total_price: string
  lines: QuotationAdditionalLine[]
}

export type QuotationAdditionalLine = {
  id: number
  description: string
  amount: string
  price_per_unit: string
  vat: string
  total_price: string
}

export type PostQuotationAdditionalLine = Pick<
  QuotationAdditionalLine,
  'description' | 'amount' | 'price_per_unit'
>

export type PatchQuotationAdditionalLine = Pick<QuotationAdditionalLine, 'id'> &
  PostQuotationAdditionalLine

export type DeleteQuotationAdditionalLine = Pick<QuotationAdditionalLine, 'id'>

export type QuotationPaymentTerm = {
  id: number
  amount_percentage: number
  description: string
  due_date: string
  total_price: string
  subtotal_price: string
}

export type PostQuotationPaymentTerm = Pick<
  QuotationPaymentTerm,
  'amount_percentage' | 'description' | 'due_date'
>

export type PatchQuotationPaymentTerm = Pick<QuotationPaymentTerm, 'id'> &
  PostQuotationPaymentTerm

export type DeleteQuotationPaymentTerm = Pick<QuotationPaymentTerm, 'id'>

export type PostQuotationGenerateInvoice = Pick<QuotationResearch, 'id'>

export type PostQuotationAttachProject = Required<
  Pick<Quotation, 'id' | 'project_id'>
>

export type PostQuotationDetachProject = Pick<Quotation, 'id'>

export enum ProjectStatus {
  New = 'new',
  InPlanning = 'in_planning',
  Plan = 'plan',
  Planned = 'planned',
  Fieldwork = 'fieldwork',
  Deskwork = 'deskwork',
  Delivered = 'delivered',
  Archived = 'archived',
}

export type ProjectQuotation = Pick<Quotation, 'id' | 'reference'>

export type Project = {
  id: number
  start_date: string | null
  end_date: string | null
  client_name: string
  client_id: number
  project_leader_id?: number | null
  project_leader?: User | null
  progress?: number
  events_count?: string
  protocol_events_count?: string
  quotations?: ProjectQuotation[]
  project_number: string
  name: string
  description?: string | null
  status: ProjectStatus | null
  sharepoint_url?: string | null
  address?: string | null
  is_in_gis: boolean
  is_smp: boolean
  is_only_planning: boolean
  is_field_report_required: boolean
  reporting_hours_per_visit?: string | null
  travel_time_per_visit?: string | null
}

export type PostProject = Omit<
  Project,
  | 'id'
  | 'client_name'
  | 'progress'
  | 'events_count'
  | 'protocol_events_count'
  | 'project_leader_id'
  | 'reporting_hours_per_visit'
  | 'travel_time_per_visit'
>

export type PatchProject = Partial<
  Omit<
    Project,
    | 'client_name'
    | 'progress'
    | 'events_count'
    | 'protocol_events_count'
    | 'project_leader_id'
    | 'reporting_hours_per_visit'
    | 'travel_time_per_visit'
  >
>

export type ProjectName = Pick<Project, 'id' | 'name' | 'project_number'>

export type ProjectSpecieBasic = {
  id: number | null
  specie_id: number | string
  research_type_id?: number
}

export type ProjectSpecie = ProjectSpecieBasic & {
  specie: Specie
  research_type: ResearchType | null
  created_date?: string
}

export type ResearchAreaGraphicGeometryPolygon = {
  spatialReference: {
    wkid: number
  }
  rings: Array<Array<[number, number]>>
}

export type ResearchAreaGraphicGeometryLine = {
  spatialReference: {
    wkid: number
  }
  paths: Array<Array<[number, number]>>
}

export type ResearchAreaGraphicGeometryPoint = {
  spatialReference: {
    wkid: number
  }
  x: number
  y: number
}

export type ResearchAreaGraphicGeometry =
  | ResearchAreaGraphicGeometryPolygon
  | ResearchAreaGraphicGeometryLine
  | ResearchAreaGraphicGeometryPoint

export enum ResearchAreaGraphicType {
  AREA = 'area',
  CYCLING_ROUTE = 'cycling_route',
  WALKING_ROUTE = 'walking_route',
  STAND_PLACE = 'stand_place',
}

export type ResearchAreaGraphic = {
  id?: number
  round_type?: RoundType | null
  animal?: string
  geometry: ResearchAreaGraphicGeometry
  type: ResearchAreaGraphicType
}

export type ResearchAreaProtocolResearchers = {
  id?: number
  number_of_researchers: number
  researchers?: EventResearcher[]
  researcher_ids: string[]
  round_type: RoundType
}

export type ResearchAreaProtocol = {
  id?: number
  protocol: ProjectProtocol
  protocol_id: number | string
  area_protocol_researchers: ResearchAreaProtocolResearchers[]
}

export type ResearchArea = {
  id: number
  location_lat?: string
  location_long?: string
  location_name?: string | null
  //name: string | null [in API endpoint response]
  client_area_number?: string | null
  protocol_events_total_duration?: number
  protocol_events_count?: number
  events_count?: number
  research_area_protocols: ResearchAreaProtocol[]
  graphics: ResearchAreaGraphic[]
  notes?: string
  project?: Project
  report_sharepoint_url?: string
}

export type ResearchAreaMonitoring = Pick<
  ResearchArea,
  | 'id'
  | 'location_lat'
  | 'location_long'
  | 'location_name'
  | 'client_area_number'
  | 'research_area_protocols'
  | 'project'
> & {
  has_events_with_reasons_of_uncertainty: string
}

export type Animal = {
  id: number
  name: string
}

export type Specie = {
  id: number
  name: string
  animal: Animal
  research_types?: ResearchType[]
  scientific_name: string
}

export type Photo = {
  id: number
  link: string
}

export enum ModelsWithMedia {
  visitLogs = 'visit_logs',
  observationLogs = 'observation_logs',
  fieldReports = 'field_reports',
}

export enum MediaType {
  photos = 'photos',
  sound_recordings = 'sound_recordings',
}

export type ResearchType = {
  id: number
  name: string
}

export type Researcher = {
  id: number
  first_name: string
  last_name: string
  email: string
  phone: string
  address_name: string
  is_senior: boolean
  notes: string
}

export type EventResearcher = Researcher & {
  distance: number
  is_available: boolean
  allowed_number_of_research_areas: number | null
}

export type EventVisitLog = Pick<VisitLog, 'id' | 'status' | 'is_manual_status'>

export type ResearchEventStatus =
  | 'ready_for_plan'
  | 'proposal_sent'
  | 'waiting_for_approval'
  | 'proposal_approved'
  | 'planned'

export enum ProcessingStatuses {
  TODO = 'todo',
  IN_PROGRESS = 'in_progress',
  DONE = 'done',
}

export type ProcessingStatus = `${ProcessingStatuses}`

export type SunCalendar = {
  date: string
  sunset: string
  sunrise: string
}

export type FieldReport = {
  id: number
  research_visit_event_id: number
  field_impression: string
  round_impression: string
  photos: Photo[]
}

export type PatchFieldReport = Pick<
  FieldReport,
  'research_visit_event_id' | 'field_impression' | 'round_impression'
>

export type ResearchVisitEvent = {
  id: number
  start_datetime: string
  end_datetime: string
  researchers: EventResearcher[]
  protocol_id: number
  original_protocols?: number[] | null
  research_area_id: number
  is_generated: boolean
  research_visit_log: EventVisitLog | null
  round_type: RoundType
  round_number?: number | null
  plan_proposed_by: number | null
  status: ResearchEventStatus
  sun_calendar: SunCalendar[]
  processing_status: ProcessingStatus | null
  processing_comment: string | null
  visit_protocol_id?: number
  is_event_approved: boolean
}

export type PutResearchVisitEventManagerProcessing = Pick<
  ResearchVisitEvent,
  'processing_status' | 'processing_comment'
>

export type ResearchVisitEventWithProjectInfo = ResearchVisitEvent & {
  research_area: ResearchArea & { project: Project }
  field_report?: FieldReport | null
}

export type ManagerResearchVisitEvent = ResearchVisitEvent & {
  project_name: string
  project_number: string
  is_event_passed: string
  is_event_going: string
  research_area_id: number
  protocol_start_date: string
  protocol_end_date: string
  //reason_of_uncertainty: UncertaintyReason
  reasons_of_uncertainty: UncertaintyReason[]
}

export type ManagerResearchVisitEventWithProjectInfo =
  ManagerResearchVisitEvent & ResearchVisitEventWithProjectInfo

export enum UncertaintyReasons {
  NOT_PLANNED = 'not_planned',
  RUN_OUT = 'run_out',
  INVALID_PLANNING = 'invalid_planning',
  NO_ROUND_IN_A_MONTH = 'no_round_in_a_month',
  RE_PLANNED = 're_planned',
  INVALID_VISIT_LOG = 'invalid_visit_log',
  REJECTED_ROUND = 'rejected_round',
  REGENERATED_ROUND = 'regenerated_round',
  NUMBER_OF_RESEARCHES_IS_NOT_ACCORDING_TO_ROUND_DETAILS = 'number_of_researches_is_not_according_to_round_details',
  RESEARCHER_HAS_ANOTHER_ROUND_PLANNED_ON_THE_SAME_DATE_TIME = 'researcher_has_another_round_planned_on_the_same_date_time',
  PAST_ROUND_WITHOUT_CONDUCTED = 'past_round_without_conducted',
  VISIT_LOG_WITHOUT_REPORT = 'visit_log_without_report',
  PERIOD_BETWEEN_VISITS = 'period_between_visits',
  DATE_RANGE = 'date_range',
  START_TIME_RELATIVE_TO_SUNSET = 'start_time_relative_to_sunset',
  END_TIME_RELATIVE_TO_SUNRISE = 'end_time_relative_to_sunrise',
  TOUCH_TIME = 'touch_time',
  DAYS_BETWEEN_BAT_EVENING_AND_MORNING_IS_NOT_21 = 'days_between_bat_evening_and_morning_is_not_21',
  DAYS_BETWEEN_BAT_EVENING_ROUNDS_IS_NOT_21 = 'days_between_bat_evening_rounds_is_not_21',
  DAYS_BETWEEN_BAT_MORNING_ROUNDS_IS_NOT_21 = 'days_between_bat_morning_rounds_is_not_21',
  NO_ONE_BAT_EVENING_ROUND_IN_JUNE = 'no_one_bat_evening_round_in_june',
  NO_ONE_SWIFT_ROUND_IN_JUNE = 'no_one_swift_round_in_june',
  NO_ONE_SWIFT_ROUND_IN_JULY = 'no_one_swift_round_in_july',
  DAYS_BETWEEN_FIRST_SWIFT_ROUND_AND_THIRD_IS_NOT_11 = 'days_between_first_swift_round_and_third_is_not_11',
  PAARROUND1_AND_MASSAWINTERRROUND1_RESEARCH_COMBINED_ARE_NOT_PLANNED_ON_SAME_DAY = 'paarround1_and_massawinterrround1_research_combined_are_not_planned_on_same_day',
  FIRST_NIGHT_ROUND_FOR_GEWONE_WITH_MEERVLEERMUIS_IS_NOT_PLANNED_BEFORE_AUGUST_20 = 'first_night_round_for_gewone_with_meervleermuis_is_not_planned_before_august_20',
  FIRST_EVENING_ROUND_FOR_MEERVLEERMUIS_IS_NOT_PLANNED_BEFORE_AUGUST_20 = 'first_evening_round_for_meervleermuis_is_not_planned_before_august_20',
  MEERVLEERMUIS_ROUNDS_ARE_NOT_PLANNED_BEFORE_SEPTEMBER_15 = 'meervleermuis_rounds_are_not_planned_before_september_15',
  MASSAWINTER_ROUNDS_ARE_NOT_PLANNED_BEFORE_SEPTEMBER_10 = 'massawinter_rounds_are_not_planned_before_september_10',
  MEERVLEERMUIS_ROUND_IS_NOT_PLANNED = 'meervleermuis_round_is_not_planned',
  DAYS_BETWEEN_NIGHT1_AND_NIGHT2_MASSAWINTER_IS_NOT_11 = 'days_between_night1_and_night2_massawinter_is_not_11',
  DAYS_BETWEEN_NIGHT2_AND_EVENING1_MASSAWINTER_AND_PAARONDERZOEK_IS_NOT_21 = 'days_between_night2_and_evening1_massawinter_and_paaronderzoek_is_not_21',
  DAYS_BETWEEN_EVENING1_AND_EVENING2_PAARONDERZOEK_IS_NOT_21 = 'days_between_evening1_and_evening2_paaronderzoek_is_not_21',
  FIRST_MASSAWINTER_ROUND_IS_NOT_PLANNED_BEFORE_OR_ON_28_08 = 'first_massawinter_round_is_not_planned_before_or_on_28_08',
  FIRST_PAARROUND_WITHOUT_MEERVLEERMUIS_IS_NOT_PLANNED_BEFORE_OR_ON_05_09 = 'first_paarround_without_meervleermuis_is_not_planned_before_or_on_05_09',
  FIRST_HAARLEM_MORNING_IS_NOT_PLANNED_BEFORE_OR_ON_20_06 = 'first_haarlem_morning_is_not_planned_before_or_on_20_06',
  FIRST_HAARLEM_HOUSE_SPARROW_MORNING_IS_NOT_PLANNED_BEFORE_OR_ON_04_05 = 'first_haarlem_house_sparrow_morning_is_not_planned_before_or_on_04_05',
  FIRST_LV_EVENING_IS_NOT_PLANNED_BEFORE_OR_ON_04_05 = 'first_lv_evening_is_not_planned_before_or_on_04_05',
  FIRST_MIDDERNACHTZWERMEN_EVENING_IS_NOT_PLANNED_BEFORE_OR_ON_20_08 = 'first_middernachtzwermen_evening_is_not_planned_before_or_on_20_08',
  MORNING_HAARLEM_HOUSE_SPARROW_ROUND_START_FROM_1H_AFTER_SUNRISE_AND_END_TILL_12 = 'morning_haarlem_house_sparrow_round_start_from_1h_after_sunrise_and_end_till_12',
}

export type UncertaintyReason = `${UncertaintyReasons}`

export type PatchResearchVisitEvent = Pick<
  ResearchVisitEvent,
  'id' | 'start_datetime' | 'end_datetime'
> & {
  researcher_ids: string[]
}

export type PatchManagerResearchVisitEvent = Pick<
  PatchResearchVisitEvent,
  'researcher_ids'
>

export type PostResearchVisitEvent = Pick<
  ResearchVisitEvent,
  'start_datetime' | 'research_area_id' | 'protocol_id'
> & {
  researcher_ids: string[]
}

export type PlanningResearchArea = ResearchArea & {
  research_visits_events: ResearchVisitEvent[]
}

export type VisitLog = {
  id: number
  research_visit_event?: ResearchVisitEventWithProjectInfo
  photos: Photo[]
  researchers: User[] | null
  created_by: User | null
  edited_by: User | null
  object_id: string | null
  global_id: number | null
  precipitation: string | null
  precipitation_duration: string | null
  wind_direction: string | null
  wind_force: number | null
  cloud_cover: string | null
  start_temperature: number | null
  end_temperature: number | null
  start_datetime: string | null
  end_datetime: string | null
  comment: string | null
  field_impression: string | null
  is_left_earlier: boolean
  reason_of_leaving: string | null
  created_at: string | null
  updated_at: string | null
  longitude: string | null
  latitude: string | null
  status: VisitLogStatus
  is_manual_status: boolean
  status_update_reason: string | null
  invalid_variables: string[] | null
  extra_research_visit_event: number | null
  processing_status: ProcessingStatus | null
  processing_comment: string | null
  is_field_report_added: boolean
}

export type PatchVisitLog = {
  research_visit_event_id: number
} & Required<
  Pick<
    VisitLog,
    | 'precipitation'
    | 'precipitation_duration'
    | 'wind_force'
    | 'cloud_cover'
    | 'start_temperature'
    | 'end_temperature'
    | 'start_datetime'
    | 'end_datetime'
  >
> &
  Partial<Pick<VisitLog, 'comment' | 'is_left_earlier' | 'reason_of_leaving'>>

export type PostRequestExtraVisitEvent = Pick<VisitLog, 'status_update_reason'>

export type PutVisitLogManagerProcessing = Pick<
  VisitLog,
  'processing_status' | 'processing_comment' | 'is_field_report_added'
>

export enum VisitLogStatuses {
  VALID = 'valid',
  VALID_WITHOUT_FIELD_REPORT = 'valid_without_field_report',
  INVALID = 'invalid',
  APPROVED = 'approved',
  APPROVED_WITHOUT_FIELD_REPORT = 'approved_without_field_report',
  EXTRA_EVENT_REQUESTED = 'extra_event_requested',
}

export type VisitLogStatus = `${VisitLogStatuses}`

export type Planning = Project & {
  research_areas: PlanningResearchArea[]
}

export type Plannings = Planning[]

export type VisitLogs = VisitLog[]

export type ResearcherAvailability = {
  date: string
  available_for_morning_round: boolean
  available_for_day_round: boolean
  available_for_evening_round: boolean
}

export type ResearcherCommitment = {
  year: number
  week: number
  max_days_available: number
}

export type DataValidationError<T> = {
  value: T
  has_error: boolean
}

export type DataValidationEnvironmentalData = {
  start_temperature: DataValidationError<number | null>
  end_temperature: DataValidationError<number | null>
  cloud_cover: DataValidationError<string | null>
  wind_force: DataValidationError<number | null>
  wind_direction: DataValidationError<string | null>
  precipitation: DataValidationError<string | null>
  precipitation_duration: DataValidationError<string | null>
}

export type ObservationTypeCode =
  | 'bats'
  | 'bats_feeding_area'
  | 'bats_flight_path'
  | 'courtship_territory'
  | 'birds_breeding'
  | 'mammals'
  | 'amphibian_fish'
  | 'reptiles'
  | 'insects'

export type ObservationType = {
  id: number
  code: ObservationTypeCode
  name: string
}

export type ObservationLog = {
  id: number
  research_visit_event: ResearchVisitEventWithProjectInfo
  research_observation_log_type: ObservationType
  photos: Photo[] | null
  sound_recordings: SoundRecord[] | null
  specie: string | null
  specie2: string | null
  specie3: string | null
  specie4: string | null
  other_specie: string | null
  amount: number | null
  amount2: number | null
  amount3: number | null
  amount4: number | null
  counting_method: string | null
  counting_method2: string | null
  counting_method3: string | null
  counting_method4: string | null
  behaviour: string | null
  other_behaviour: string | null
  behaviour_notes: string | null
  direction: string | null
  wind_direction: string | null
  is_sound_made: boolean
  recording_number: string | null
  amount_per_15_min: number | null
  is_place_found: boolean
  place_type: string | null
  place_type_notes: string | null
  first_fledgling_time: string | null
  photo: string | null
  notes: string | null
  address: string | null
  longitude: string | null
  latitude: string | null
  //geometry: {}
  object_id: string | null
  created_at: string | null
  updated_at: string | null
  observed_at: string | null
  processing_comment: string | null
  created_by: Researcher | null
  edited_by: Researcher | null
}

export type PatchObservationLog = {
  research_visit_event_id: number
  research_observation_log_type_id: number
} & Partial<
  Omit<ObservationLog, 'research_visit_event' | 'research_observation_log_type'>
>

export type PutObservationLogManagerProcessing = Pick<
  ObservationLog,
  'processing_comment'
>

export type SoundRecord = {
  id: number
  link: string
  spectrogram_link: string
  specie_identified: string
  analysed: boolean
  analysed_by: User
  analysed_at: string
  analysis_notes: string
  created_at: string
  updated_at: string
  created_by: Researcher
  edited_by: Researcher | null
  observation_log: ObservationLog
}

export type PostSoundRecord = Pick<
  SoundRecord,
  'specie_identified' | 'analysis_notes'
> & {
  spectrogram_link: File | undefined
}

export type PostCloneObservationLog = {
  move_sound_recording_ids: string[]
  copy_sound_recording_ids: string[]
}

export enum RoundType {
  MORNING = 'morning',
  DAY = 'day',
  EVENING = 'evening',
  NIGHT = 'night',
}

export type OriginProtocol = {
  id: number
  specie: Specie
  research_type: ResearchType
}

export type ProjectProtocolRound = {
  id: number
  round_type: RoundType
  number_of_visits: number
  required_number_of_researchers: number
  from_date: string | null
  to_date: string | null
  visit_duration: number
  from_time?: string | null
  to_time?: string | null
  touch_time?: string | null
  start_time_relative_to_sunset?: number | null
  end_time_relative_to_sunrise?: number | null
  period_between_visits_map?: Record<string, number>
  method_of_identification?: string | null
  from_wind_force?: number | null
  to_wind_force?: number | null
  from_temperature?: number | null
  to_temperature?: number | null
  max_precipitation?: string | null
  one_round_mandatory_in_a_month?: string | null
  original_protocols?: number[] | null
}

export type ProjectProtocol = {
  id: number
  project_id: number
  original_protocols: OriginProtocol[]
  is_customized: boolean
  rounds: ProjectProtocolRound[]
  required_checks: PlanningControlCheck[]
}

export enum PlanningControlChecks {
  DAYS_BETWEEN_BAT_ROUNDS = 'days_between_bat_rounds',
  MASSAWINTER_ROUND_BEFORE_SEPTEMBER_10 = 'massawinter_round_before_september_10',
  MEERVLEERMUIS_ROUND_BEFORE_SEPTEMBER_15 = 'meervleermuis_round_before_september_15',
  DAYS_BETWEEN_FIRST_AND_THIRD_SWIFT_ROUNDS = 'days_between_first_and_third_swift_rounds',
  FIRST_PARR_AND_FIRST_MASSAWINTER_SAME_DAY = 'first_parr_and_first_massawinter_same_day',
  FIRST_NIGHT_GEWONE_WITH_MEERVLEERMUIS_BEFORE_AUGUST_20 = 'first_night_gewone_with_meervleermuis_before_august_20',
  FIRST_EVENING_ROUND_FOR_MEERVLEERMUIS_BEFORE_AUGUST_20 = 'first_evening_round_for_meervleermuis_before_august_20',
  DAYS_BETWEEN_NIGHT_MASSAWINTER_ROUNDS = 'days_between_night_massawinter_rounds',
  DAYS_BETWEEN_PAAR_MASSAWINTER_ROUNDS = 'days_between_paar_massawinter_rounds',
  DAYS_BETWEEN_PAAR_EVENING_ROUNDS = 'days_between_paar_evening_rounds',
  FIRST_MASSAWINTER_ROUND_BEFORE_AUGUST_28 = 'first_massawinter_round_before_august_28',
  FIRST_PAAR_ROUND_BEFORE_SEPTEMBER_5 = 'first_paar_round_before_september_5',
  FIRST_HAARLEM_MORNING_ROUND_BEFORE_20_JUNE = 'first_haarlem_morning_round_before_20_june',
  FIRST_HAARLEM_HOUSE_SPARROW_MORNING_ROUND_BEFORE_4_MAY = 'first_haarlem_house_sparrow_morning_round_before_4_may',
  FIRST_LV_EVENING_ROUND_BEFORE_4_MAY = 'first_lv_evening_round_before_4_may',
  FIRST_MIDDERNACHTZWERMEN_EVENING_ROUND_BEFORE_20_AUGUST = 'first_middernchtzwermen_evening_round_before_20_august',
  MORNING_HAARLEM_HOUSE_SPARROW_ROUND_START_FROM_1H_AFTER_SUNRISE_AND_END_TILL_12 = 'morning_haarlem_house_sparrow_round_start_from_1h_after_sunrise_and_end_till_12',
}

export type PlanningControlCheck = `${PlanningControlChecks}`

export type Client = {
  id: number
  name: string
  coc_number?: string
  vat_id?: string
  address?: string
  contact_email?: string
  reporting_hours_per_visit?: string | null
  travel_time_per_visit?: string | null
}

export type ClientPerson = {
  id: number
  first_name: string
  last_name: string
  email: string
  projects: string
}

export enum HoursReportStatuses {
  VALID = 'valid',
  INVALID = 'invalid',
  APPROVED = 'approved',
}

export type HoursReportStatus = `${HoursReportStatuses}`

export type HoursReport = {
  id: number
  research_visit_event_id: number
  research_time_minutes?: number
  research_time_comment?: string | null
  research_visit_event: ResearchVisitEvent
  researcher: Researcher
  travel_time_minutes?: number
  travel_time_comment?: string | null
  reporting_time_minutes?: number
  reporting_time_comment?: string | null
  travel_distance_km?: number
  travel_distance_comment?: string | null
  status: HoursReportStatus
  invalid_variables: HoursReportInvalidVariable[]
  created_at: string
}

export enum HoursReportInvalidVariables {
  RESEARCH_TIME_IS_BIGGER_THAN_DEFINED_BY_PROTOCOL = 'research_time_is_bigger_than_defined_by_protocol',
  TRAVEL_TIME_IS_BIGGER_THAN_STANDARD = 'travel_time_is_bigger_than_standard',
  TRAVEL_TIME_IS_REPORTED_FOR_COUPLED_ROUND = 'travel_time_is_reported_for_coupled_round',
  TRAVEL_DISTANCE_IS_BIGGER_THAN_CALCULATED = 'travel_distance_is_bigger_than_calculated',
  REPORTING_TIME_IS_BIGGER_THAN_STANDARD = 'reporting_time_is_bigger_than_standard',
}

export type HoursReportInvalidVariable = `${HoursReportInvalidVariables}`

export type Contractor = {
  id: number
  name: string
  code: string
}
