import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Icon,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { QuotationsTableProps } from './QuotationsTable'
import { AuthorizationGuard } from '@/components'
import { getPath } from '@/utils'
import dayjs from 'dayjs'
import { UserGroups } from '@/types'
import { DATE_FORMAT_DMY, ROUTES_PATH } from '@/constants'

export const QuotationsTableBody = ({
  rows,
}: QuotationsTableProps): JSX.Element => {
  const { t } = useTranslation('quotations')

  return (
    <TableBody>
      {rows.map(
        ({
          id,
          client,
          reference,
          status,
          created_at,
          updated_at,
          created_by,
          edited_by,
          invoice_link,
        }) => {
          const detailPath = getPath(
            invoice_link
              ? ROUTES_PATH.QUOTATION_DETAIL
              : ROUTES_PATH.QUOTATION_EXTEND,
            { id }
          )

          return (
            <TableRow key={id}>
              <TableCell>{id}</TableCell>
              <TableCell>{client.name}</TableCell>
              <TableCell>{reference}</TableCell>
              <TableCell>{status}</TableCell>
              <TableCell>
                {dayjs(created_at).format(DATE_FORMAT_DMY) ?? '-'}
              </TableCell>
              <TableCell>
                {dayjs(updated_at).format(DATE_FORMAT_DMY) ?? '-'}
              </TableCell>
              <TableCell>{`${created_by?.first_name} ${created_by?.last_name}`}</TableCell>
              <TableCell>{`${edited_by?.first_name} ${edited_by?.last_name}`}</TableCell>
              <TableCell width="190px">
                <AuthorizationGuard
                  allowedGroups={[UserGroups.MANAGER]}
                  redirect={false}
                >
                  <Button
                    variant="text"
                    endIcon={
                      <Icon
                        sx={{
                          fontSize: 14,
                          lineHeight: '21px',
                          fontWeight: 600,
                        }}
                      >
                        <ArrowForwardIcon sx={{ height: 14, width: 14 }} />
                      </Icon>
                    }
                    component={Link}
                    to={detailPath}
                    data-testid="open-quotation-button"
                  >
                    <Typography variant="body2" fontWeight={600}>
                      {t(`action.${invoice_link ? 'view' : 'edit'}`)}
                    </Typography>
                  </Button>
                </AuthorizationGuard>
              </TableCell>
            </TableRow>
          )
        }
      )}
    </TableBody>
  )
}
