import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { useApiClient } from '@/hooks'
import { unwrapErrorResponse, unwrapResponse } from '@/utils/unwrapResponse'
import { QuotationResearch } from '@/types/api/entities'
import { QUERY_KEYS } from '@/constants'
import { APIErrorResponse } from '@/types'

export type UseQuotationResearchProps = {
  quotationId: number
  researchId?: number
}

export const getUseQuotationResearchKey = (
  quotationId: number,
  researchId?: number
): string[] =>
  researchId
    ? QUERY_KEYS.QUOTATION_RESEARCH.map((key) =>
        key === ':quotationId'
          ? quotationId.toString()
          : key === ':id'
          ? researchId.toString()
          : key
      )
    : ['no-research-id']

export const useQuotationResearch = ({
  quotationId,
  researchId,
}: UseQuotationResearchProps): UseQueryResult<
  QuotationResearch,
  APIErrorResponse
> => {
  const client = useApiClient()

  const queryKey = getUseQuotationResearchKey(quotationId, researchId)

  return useQuery(
    queryKey,
    () =>
      client
        .get(`/quotations/${quotationId}/researches/${researchId}/`)
        .then(unwrapResponse)
        .catch(unwrapErrorResponse),
    { enabled: !!researchId }
  )
}
