import { useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { Stack, Button, IconButton, TextField } from '@mui/material'
import { PlusCircle, X } from 'react-feather'
import {
  useMutationWrapper,
  usePostQuotationAdditionalLine,
  usePatchQuotationAdditionalLine,
  useDeleteQuotationAdditionalLine,
  getUseQuotationDetailsKey,
} from '@/hooks'
import { useConfirmationModal } from '@/hooks/useConfirmationModal'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  QuotationAdditionalForm,
  QuotationAdditionalFormData,
} from './QuotationAdditionalForm'
import { FormActionButtons } from '@/features/quotationCreate/FormActionButtons'
import { QuotationAdditional, QuotationAdditionalLine } from '@/types'
import { quotationAdditionalSchema } from './validationSchema'
import { theme } from '@/theme'

type QuotationAdditionalLineFormContainerProps = {
  quotationId: number
  data: QuotationAdditional
}

export const QuotationAdditionalFormContainer = ({
  quotationId,
  data,
}: QuotationAdditionalLineFormContainerProps): JSX.Element => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const { setConfirmationOpen } = useConfirmationModal()

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const { mutateAsync: createQuotationAdditionalLine, isLoading: isCreating } =
    useMutationWrapper(() => usePostQuotationAdditionalLine(quotationId))

  const { mutateAsync: updateQuotationAdditionalLine, isLoading: isMutating } =
    useMutationWrapper(() => usePatchQuotationAdditionalLine(quotationId))

  const { mutateAsync: deleteQuotationAdditionalLine, isLoading: isDeleting } =
    useMutationWrapper(() => useDeleteQuotationAdditionalLine(quotationId))

  const defaultValues = useMemo(() => {
    const lines: QuotationAdditionalFormData = {}

    data.lines?.forEach((line) => {
      lines[`${line.id}`] = {
        id: line.id,
        description: line.description,
        amount: line.amount,
        price_per_unit: line.price_per_unit,
        total_price: line.total_price,
      }
    })

    return lines
  }, [data])

  const methods = useForm<QuotationAdditionalFormData>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(quotationAdditionalSchema),
  })

  useEffect(() => {
    if (data) {
      methods.reset(defaultValues)
    }
  }, [data, methods, defaultValues])

  const deleteAdditionalLine = (index: string) => {
    setConfirmationOpen(
      t('quotations:titles.deleteConfirmation'),
      t('quotations:titles.deleteAdditionalItem'),
      async () => {
        try {
          await deleteQuotationAdditionalLine({ id: Number(index) })

          const quotationQueryKey = getUseQuotationDetailsKey(quotationId)

          await queryClient.invalidateQueries(quotationQueryKey)
        } catch (e) {
          enqueueSnackbar(`${t('errors:api.execute')}: ${e}`, {
            variant: 'error',
          })
        }
      }
    )
  }

  const handleSubmit = methods.handleSubmit(async (formData) => {
    enqueueSnackbar('Saving...', {
      variant: 'default',
    })

    const updatedLines: Promise<QuotationAdditionalLine>[] = []

    Object.keys(formData).forEach((lineId) => {
      const lineData = {
        description: formData[lineId].description,
        amount: formData[lineId].amount,
        price_per_unit: formData[lineId].price_per_unit,
      }

      if (lineId === 'new') {
        updatedLines.push(createQuotationAdditionalLine({ ...lineData }))
      } else {
        updatedLines.push(
          updateQuotationAdditionalLine({
            id: Number(lineId),
            ...lineData,
          })
        )
      }
    })

    try {
      await Promise.all(updatedLines)

      const quotationQueryKey = getUseQuotationDetailsKey(quotationId)

      await queryClient.invalidateQueries(quotationQueryKey)

      closeSnackbar()
    } catch (e) {
      enqueueSnackbar(`${t('errors:api.execute')}: ${e}`, {
        variant: 'error',
      })
    }
  })

  const handleReset = () =>
    methods.reset({
      ...defaultValues,
    })

  const handleAddNewLine = () => {
    methods.setValue('new', {
      id: undefined,
      description: '',
      amount: '',
      price_per_unit: '',
      total_price: '',
    })
  }

  const lines = methods.watch()

  const totalPrice =
    lines &&
    Object.keys(lines)
      .map(
        (key) =>
          (Number(methods.getValues(`${key}.amount`)) ?? 0) *
          (Number(methods.getValues(`${key}.price_per_unit`)) ?? 0)
      )
      .reduce((a, b) => a + b, 0)
      .toFixed(1)

  return (
    <>
      <FormProvider {...methods}>
        <Stack spacing={5}>
          {Object.entries(lines).map(([key]) => (
            <Stack direction="row" alignItems="center" key={key}>
              <QuotationAdditionalForm index={key} />
              {key !== 'new' && (
                <IconButton onClick={() => deleteAdditionalLine(key)}>
                  <X size="16px" color={theme.palette.green['500']} />
                </IconButton>
              )}
            </Stack>
          ))}
          {Object.entries(lines).length > 0 && (
            <Stack direction="row" justifyContent="flex-end">
              <TextField
                value={totalPrice}
                label={t('quotations:placeholders.totalAdditionalCost')}
                disabled={true}
              />
            </Stack>
          )}
        </Stack>
        {methods.formState.isDirty && (
          <FormActionButtons
            cancelClick={handleReset}
            saveClick={handleSubmit}
            isLoading={isCreating || isMutating || isDeleting}
            disabled={!methods.formState.isDirty}
          />
        )}
      </FormProvider>
      <Button
        variant="text"
        onClick={handleAddNewLine}
        startIcon={<PlusCircle size="16px" />}
        sx={{ mt: 6 }}
      >
        {t('quotations:buttons.addAdditionalLine')}
      </Button>
    </>
  )
}
