import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Edit } from 'react-feather'
import { Stack, Button } from '@mui/material'
import { getPath } from '@/utils'
import { QuotationDetails, QuotationStatuses } from '@/types'
import { ROUTES_PATH } from '@/constants'

type EditQuotationControlProps = { quotation: QuotationDetails }

export const EditQuotationControl = ({
  quotation,
}: EditQuotationControlProps): JSX.Element => {
  const { t } = useTranslation('quotations')

  const editEnabled =
    [QuotationStatuses.NEW, QuotationStatuses.SENT].includes(
      quotation.status as QuotationStatuses
    ) && !quotation.project_id

  return editEnabled ? (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={4}
      my={4}
    >
      <Button
        component={Link}
        to={getPath(ROUTES_PATH.QUOTATION_EXTEND, {
          id: quotation.id,
        })}
        variant="textThin"
        endIcon={<Edit size={14} />}
      >
        {t('quotations:buttons.editQuotation')}
      </Button>
    </Stack>
  ) : (
    <></>
  )
}
