import { useState, useMemo, ChangeEvent } from 'react'
import { useSearchParams } from 'react-router-dom'
import { SearchRequestParams, PaginatedListResponse } from '@/types'
import { DEFAULT_LIST_LIMIT } from '@/constants'

export const useFilters = <
  T extends SearchRequestParams,
  D extends PaginatedListResponse<R>,
  R extends Record<string, unknown>
>(
  params: T,
  lazy?: boolean
) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const defaultValues: T = useMemo(
    () =>
      Object.keys(params as T).reduce((acc, key) => {
        const typedKey = key as keyof T

        acc[typedKey] = undefined as T[keyof T]

        const defaultParam = params[key] ?? undefined
        const urlParam = searchParams.get(key) ?? undefined

        if (defaultParam && !urlParam) {
          acc[typedKey] = defaultParam as T[keyof T]
          if (key !== 'limit' && key !== 'offset') {
            searchParams.set(key, `${defaultParam}`)
            setSearchParams(searchParams)
          }

          return acc
        }

        if (!!urlParam) {
          if (urlParam === 'true') {
            acc[typedKey] = true as T[keyof T]
          } else if (urlParam === 'false') {
            acc[typedKey] = false as T[keyof T]
            // } else if (!Number.isNaN(Number(urlParam))) {
            //   acc[typedKey] = Number(urlParam) as T[keyof T]
          } else {
            acc[typedKey] = urlParam as T[keyof T]
          }
        }

        return acc
      }, {} as T),
    [params, searchParams]
  )

  const pageSearchParam = !!searchParams.get('page')
    ? Number(searchParams.get('page'))
    : undefined

  defaultValues.offset = pageSearchParam
    ? (pageSearchParam - 1) * (params.limit ?? DEFAULT_LIST_LIMIT)
    : 0

  const [filterParams, setFilterParams] = useState(defaultValues)

  const [page, setPage] = useState(pageSearchParam ?? 1)

  const [prevPage, setPrevPage] = useState<number | undefined>(undefined)

  const [pagesCount, setPagesCount] = useState(1)

  const [rows, setRows] = useState<R[]>([])

  const [nextItemsCount, setNextItemsCount] = useState(0)

  const handleFieldValueChange = (value: T[keyof T], field: keyof T) => {
    if (value !== undefined && value !== '') {
      searchParams.set(field as string, `${value}`)
    } else {
      searchParams.delete(field as string)
    }

    if (!lazy) {
      searchParams.delete('page')
    }

    setSearchParams(searchParams)

    setPage(1)

    setFilterParams((filterParams) => ({
      ...filterParams,
      [field]: value ?? undefined,
      offset: 0,
    }))
  }

  const initPagesCount = (itemsCount: number) => {
    const count = Math.ceil(Number(itemsCount) / Number(filterParams?.limit))

    setPagesCount(count)
  }

  const updateData = (data: D) => {
    if (data) {
      initPagesCount(data.count)

      setRows((prevRows) =>
        prevPage && page > prevPage
          ? [...prevRows, ...data.results]
          : [...data.results]
      )

      setPrevPage(page)

      setNextItemsCount(data.count - (rows.length + data.results.length))
    }
  }

  const onPageChange = (_: ChangeEvent<unknown>, page: number) => {
    setPage(page)

    if (!lazy) {
      if (page > 1) {
        searchParams.set('page', `${page}`)
      } else if (searchParams.get('page')) {
        searchParams.delete('page')
      }

      setSearchParams(searchParams)
    }

    setFilterParams((filterParams) => ({
      ...filterParams,
      offset: (page - 1) * (filterParams.limit ?? DEFAULT_LIST_LIMIT),
    }))
  }

  const handleResetFilters = () => {
    setPage(1)
    setSearchParams({})
    setFilterParams({ ...params })
  }

  const openDetail = () => {
    const id = searchParams.get('id')
    const detail = searchParams.get('detail')

    if (id && detail) {
      searchParams.delete('detail')
      setSearchParams(searchParams)

      return Number(id)
    }

    return false
  }

  return {
    filterParams,
    handleFieldValueChange,
    handleResetFilters,
    initPagesCount,
    pagesCount,
    page,
    onPageChange,
    openDetail,
    rows,
    updateData,
    nextItemsCount,
  }
}
