import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { useApiClient } from '@/hooks'
import { unwrapResponse, unwrapErrorResponse } from '@/utils/unwrapResponse'
import {
  APIErrorResponse,
  QuotationAdditionalLine,
  PatchQuotationAdditionalLine,
} from '@/types'

export const usePatchQuotationAdditionalLine = (
  quotationId: number
): UseMutationResult<
  QuotationAdditionalLine,
  APIErrorResponse,
  PatchQuotationAdditionalLine
> => {
  const client = useApiClient()

  return useMutation((params) =>
    client
      .patch(`/quotations/${quotationId}/additional/${params.id}/`, params)
      .then(unwrapResponse)
      .catch(unwrapErrorResponse)
  )
}
