import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Stack, Box, IconButton } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { X } from 'react-feather'
import { ModalLayout } from '@/components'
import {
  AutocompleteOption,
  ControlledAutocomplete,
  ControlledDateField,
} from '@/components/inputs'
import { useHoursReportsExport, useUsersContractors } from '@/hooks'
import * as yup from 'yup'
import dayjs from 'dayjs'
import { theme } from '@/theme'

type HoursReportsExportFormData = {
  contractor: AutocompleteOption | null
  startDate: string | null
  endDate: string | null
}

const exportSchema = yup.object({
  contractor: yup
    .object({
      value: yup.string(),
      title: yup.string(),
    })
    .typeError('required')
    .required(),
  startDate: yup.date().typeError('invalid_date').required(),
  endDate: yup.date().typeError('invalid_date').optional().nullable(),
})

export const HoursReportsExportForm = (): JSX.Element => {
  const { t } = useTranslation('hoursReporting')

  const { enqueueSnackbar } = useSnackbar()

  const { exportReport, loading: isExportInProcess } = useHoursReportsExport()

  const { data: contractors } = useUsersContractors()

  const options = useMemo(
    () =>
      contractors
        ?.sort((a, b) => (a.name < b.name ? -1 : 1))
        .map(({ id, name }) => ({
          title: name,
          value: id.toString(),
        })) || [],
    [contractors]
  )

  const defaultValues = {
    contractor: null,
    startDate: null,
    endDate: null,
  }

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm<HoursReportsExportFormData>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(exportSchema),
  })

  const handleReset = () =>
    reset({
      ...defaultValues,
    })

  const handleExportSubmit = async ({
    contractor,
    startDate,
    endDate,
  }: HoursReportsExportFormData) => {
    const exportData = {
      contractor: contractor?.value,
      start_date: startDate && dayjs(startDate).utc().format(),
      end_date: endDate && dayjs(endDate).utc().format(),
    }

    try {
      await exportReport(exportData)

      enqueueSnackbar(t('titles.exportedSuccessfully'), {
        variant: 'success',
      })

      handleReset()
    } catch (e) {
      enqueueSnackbar(`${t('errors:api.execute')}: ${e}`, {
        variant: 'error',
      })
    }
  }

  return (
    <ModalLayout title={t('titles.exportHoursReports')}>
      <form onSubmit={handleSubmit(async (data) => handleExportSubmit(data))}>
        <Stack
          direction="row"
          alignItems="flex-start"
          justifyContent="flex-start"
          spacing={2}
        >
          <Box width={300}>
            <ControlledAutocomplete
              name="contractor"
              options={options}
              control={control}
              textFieldProps={{
                placeholder: t('placeholders.contractor'),
              }}
              multiple={false}
              fullWidth={true}
            />
          </Box>
          <ControlledDateField
            name="startDate"
            placeholder={t('forms:placeholders.start-date')}
            control={control}
            apiFieldName="startDate"
          />
          <ControlledDateField
            name="endDate"
            placeholder={t('forms:placeholders.end-date')}
            control={control}
            apiFieldName="endDate"
          />
          <Stack direction="row" justifyContent="flex-end" spacing={5} pt={1}>
            <Box mt={1}>
              <IconButton
                onClick={handleReset}
                title={t('buttons.resetFilters')}
              >
                <X size="16px" color={theme.palette.green['500']} />
              </IconButton>
            </Box>
            <LoadingButton
              variant="contained"
              color="secondary"
              type="submit"
              loading={isExportInProcess}
              disableElevation={true}
              disabled={!isDirty}
            >
              <span> {t('buttons.export')}</span>
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
    </ModalLayout>
  )
}
