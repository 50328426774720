import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { useApiClient } from '@/hooks'
import { unwrapResponse, unwrapErrorResponse } from '@/utils/unwrapResponse'
import {
  APIErrorResponse,
  QuotationAdditionalLine,
  PostQuotationAdditionalLine,
} from '@/types'

export const usePostQuotationAdditionalLine = (
  quotationId: number
): UseMutationResult<
  QuotationAdditionalLine,
  APIErrorResponse,
  PostQuotationAdditionalLine
> => {
  const client = useApiClient()

  return useMutation((params) =>
    client
      .post(`/quotations/${quotationId}/additional/`, params)
      .then(unwrapResponse)
      .catch(unwrapErrorResponse)
  )
}
