import { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Stack, Box, Typography, IconButton } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { X } from 'react-feather'
import { MonitoringTable } from '@/features'
import { Loader, EmptyListData } from '@/components'
import {
  SearchTextField,
  SearchDateField,
  ResearchersMultiSelect,
  ProjectsMultiSelect,
  UncertaintyReasonMultiSelect,
  ClientsMultiSelect,
  AnimalSelect,
  ProjectLeadersMultiSelect,
  UncertaintyReasonGroups,
  groupedUncertaintyReasons,
} from '@/components/inputs'
import {
  useFilters,
  useResearchAreasMonitoring,
  UseResearchAreasMonitoringParams,
} from '@/hooks'
import { PaginatedListResponse, ResearchAreaMonitoring } from '@/types'
import { DEFAULT_LIST_LIMIT, DEFAULT_LIST_OFFSET } from '@/constants'
import { theme } from '@/theme'

export const MonitoringContainer = (): JSX.Element => {
  const { t } = useTranslation('monitoring')

  const [openedAreas, setOpenedAreasList] = useState<number[]>([])

  const defaultFiltersParams: UseResearchAreasMonitoringParams = {
    id: undefined,
    project: undefined,
    client: undefined,
    researchAreaResearchers: undefined,
    uncertaintyReason: undefined,
    animal: undefined,
    project_leader: undefined,
    startDate: undefined,
    endDate: undefined,
    limit: DEFAULT_LIST_LIMIT,
    offset: DEFAULT_LIST_OFFSET,
  }

  const {
    filterParams,
    handleFieldValueChange,
    handleResetFilters,
    page,
    onPageChange,
    rows,
    updateData,
    nextItemsCount,
  } = useFilters<
    UseResearchAreasMonitoringParams,
    PaginatedListResponse<ResearchAreaMonitoring>,
    ResearchAreaMonitoring
  >(defaultFiltersParams, true)

  const preparedFilterParams = useMemo(() => {
    const preparedUncertaintyReason: string[] = []

    filterParams.uncertaintyReason?.split(',')?.forEach((reason) => {
      if (
        Object.values(UncertaintyReasonGroups).includes(
          reason as UncertaintyReasonGroups
        )
      ) {
        const values =
          groupedUncertaintyReasons.find((gur) => gur.group_name === reason)
            ?.values ?? []

        preparedUncertaintyReason.push(...values)
      } else {
        preparedUncertaintyReason.push(reason)
      }
    })

    return {
      ...filterParams,
      uncertaintyReason: [...new Set(preparedUncertaintyReason)].join(','),
    }
  }, [filterParams])

  const { data, isLoading, isFetching } =
    useResearchAreasMonitoring(preparedFilterParams)

  useEffect(() => {
    if (data) {
      updateData(data)
    }
  }, [data])

  const toggleAreaDetails = (id: number): void => {
    setOpenedAreasList((list) =>
      openedAreas.includes(id)
        ? list.filter((areaId) => areaId !== id)
        : [...list, id]
    )
  }

  return (
    <Stack>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          mb={4}
          spacing={4}
        >
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'flex-start', md: 'center' }}
            justifyContent="flex-start"
            spacing={4}
            width="100%"
          >
            <SearchTextField
              searchValue={filterParams.id as string}
              onChange={(value) => handleFieldValueChange(value, 'id')}
              placeholder={t('placeholders.areaId')}
              searchIcon={false}
              width="100px"
            />
            <ClientsMultiSelect
              value={filterParams.client as string}
              onChange={(value) => handleFieldValueChange(value, 'client')}
              placeholder={t('placeholders.clients')}
            />
            <ProjectsMultiSelect
              value={filterParams.project as string}
              onChange={(value) => handleFieldValueChange(value, 'project')}
              placeholder={t('placeholders.projects')}
            />
            <SearchDateField
              searchStartValue={filterParams.startDate as string}
              searchEndValue={filterParams.endDate as string}
              onChangeStartDate={(date) =>
                handleFieldValueChange(date, 'startDate')
              }
              onChangeEndDate={(date) =>
                handleFieldValueChange(date, 'endDate')
              }
            />
          </Stack>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'flex-start', md: 'center' }}
            justifyContent="flex-start"
            spacing={4}
            width="100%"
          >
            <UncertaintyReasonMultiSelect
              value={filterParams.uncertaintyReason as string}
              onChange={(value) =>
                handleFieldValueChange(value, 'uncertaintyReason')
              }
            />
            <ResearchersMultiSelect
              value={filterParams.researchAreaResearchers as string}
              onChange={(value) =>
                handleFieldValueChange(value, 'researchAreaResearchers')
              }
              placeholder={t('placeholders.researchers')}
            />
            <AnimalSelect
              value={filterParams.animal as string}
              onChange={(value) => handleFieldValueChange(value, 'animal')}
              placeholder={t('placeholders.animals')}
            />
          </Stack>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'flex-start', md: 'center' }}
            justifyContent="flex-start"
            spacing={4}
            width="100%"
          >
            <ProjectLeadersMultiSelect
              value={filterParams.project_leader as string}
              onChange={(value) =>
                handleFieldValueChange(value, 'project_leader')
              }
              placeholder={t('placeholders.projectsLeaders')}
            />
          </Stack>
        </Stack>
        <Typography color="green.600">
          {t('amount', { amount: data?.count ?? 0 })}
        </Typography>
        <IconButton
          onClick={handleResetFilters}
          title={t('buttons.resetFilters')}
        >
          <X size="16px" color={theme.palette.green['500']} />
        </IconButton>
      </Stack>

      {data?.count ? (
        <>
          <MonitoringTable
            rows={rows}
            filterParams={preparedFilterParams}
            openedAreas={openedAreas}
            toggleAreaDetails={toggleAreaDetails}
          />

          {rows.length < data.count && (
            <Box mt={8} alignSelf="center">
              <LoadingButton
                variant="contained"
                color="secondary"
                onClick={(e) => {
                  onPageChange(e, page + 1)
                }}
                loading={isFetching}
              >
                {nextItemsCount > DEFAULT_LIST_LIMIT
                  ? t('buttons.showNextItems', { count: nextItemsCount })
                  : t('buttons.showLastItems', { count: nextItemsCount })}
              </LoadingButton>
            </Box>
          )}
        </>
      ) : (
        <Box my={20}>
          {isLoading && <Loader />}

          {!isLoading && !data?.count && (
            <EmptyListData textKey="monitoring:noEvents" />
          )}
        </Box>
      )}
    </Stack>
  )
}
