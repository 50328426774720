import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box, Stack, Typography } from '@mui/material'
import {
  ControlledAutocomplete,
  AutocompleteOption,
  ControlledTextField,
  ControlledCheckboxField,
  ProjectLeaderAutocomplete,
} from '@/components/inputs'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useClients, useClientPersons } from '@/hooks'
import { Quotation, Client, FormComponentProps } from '@/types'
import { ClientInfo } from '@/features/quotationCreate/ClientInfo'

export type QuotationBasicInfo = {
  client_id: AutocompleteOption<Client> | null
  reference: string
  is_smp: boolean
  contact_person_ids?: AutocompleteOption[]
  address?: string
  project_leader_id?: AutocompleteOption<number> | null
}

export type QuotationBasicInfoFormProps = {
  quotation?: Quotation
  showControlsOnDirty?: boolean
  controls: JSX.Element
} & FormComponentProps<QuotationBasicInfo>

const quotationCreateScheme = yup.object({
  client_id: yup
    .object({
      value: yup.object(),
      title: yup.string(),
    })
    .typeError('required')
    .required(),
  reference: yup.string().required(),
})

export const QuotationBasicInfoForm = ({
  quotation,
  controls,
  onSubmit,
  showControlsOnDirty = false,
}: QuotationBasicInfoFormProps): JSX.Element => {
  const { t } = useTranslation('quotations')

  const { data: clients } = useClients(
    {
      limit: 1000,
      offset: 0,
    },
    !quotation
  )

  const defaultValues = {
    client_id: null,
    reference: '',
    is_smp: false,
    contact_person_ids: [],
    address: '',
    project_leader_id: null,
  }

  const values = useMemo(
    () =>
      quotation
        ? {
            client_id: {
              value: quotation.client,
              title: quotation.client.name,
            } as AutocompleteOption<Client>,
            reference: quotation.reference,
            is_smp: quotation.is_smp,
            contact_person_ids: quotation.contact_persons?.map((cp) => ({
              value: cp.id.toString(),
              title: `${cp.first_name} ${cp.last_name}${
                cp.projects ? ` [${cp.projects}]` : ''
              }`,
            })) as AutocompleteOption[],
            address: quotation.address ?? '',
            project_leader_id:
              quotation && quotation.project_leader
                ? ({
                    title: `${quotation.project_leader.first_name} ${quotation.project_leader.last_name} [${quotation.project_leader.email}]`,
                    value: quotation.project_leader.id,
                  } as AutocompleteOption<number>)
                : null,
          }
        : defaultValues,
    [quotation]
  )

  const {
    handleSubmit,
    control,
    watch,
    formState: { isDirty },
  } = useForm<QuotationBasicInfo>({
    mode: 'onChange',
    defaultValues,
    values,
    resolver: yupResolver(quotationCreateScheme),
  })

  const client = watch('client_id')

  const { data: clientPersons } = useClientPersons({
    clientId: client?.value.id,
  })

  const clientOptions: AutocompleteOption<Client>[] = useMemo(
    () =>
      (clients?.results
        .map((client) => ({
          value: client,
          title: client.name,
        }))
        .sort((a, b) => (a.title < b.title ? -1 : 1)) ||
        []) as AutocompleteOption<Client>[],
    [clients]
  )

  const clientPersonsOptions: AutocompleteOption[] = useMemo(
    () =>
      (clientPersons
        ?.map((cp) => ({
          title: `${cp.first_name} ${cp.last_name}${
            cp.projects ? ` [${cp.projects}]` : ''
          }`,
          value: cp.id.toString(),
        }))
        .sort((a, b) => (a.title < b.title ? -1 : 1)) ||
        []) as AutocompleteOption[],
    [clientPersons]
  )

  return (
    <Box>
      <Stack
        direction="row"
        spacing={4}
        sx={{
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        }}
      >
        <Box width="50%">
          <form
            onSubmit={handleSubmit(async (data) => {
              onSubmit(data)
            })}
          >
            <Stack spacing={4} my={6}>
              <Box maxWidth="500px">
                <Typography fontWeight="700" mb={3}>
                  {t('placeholders.client')}
                </Typography>
                <ControlledAutocomplete
                  name="client_id"
                  control={control}
                  options={clientOptions}
                  placeholder={t('placeholders.client')}
                  isOptionEqualToValue={(option, value) =>
                    option.value.id === value.value.id
                  }
                  disabled={!!quotation}
                />
              </Box>
              <Box maxWidth="500px">
                <Typography fontWeight="700" mb={3}>
                  {t('placeholders.reference')}
                </Typography>
                <ControlledTextField
                  name="reference"
                  control={control}
                  placeholder={t('placeholders.reference')}
                />
              </Box>
              <Box maxWidth="500px">
                <Typography fontWeight="700">SMP</Typography>
                <ControlledCheckboxField
                  name="is_smp"
                  control={control}
                  label={t('placeholders.isSmp')}
                />
              </Box>
              <Box maxWidth="500px">
                <Typography fontWeight="700" mb={3}>
                  {t('placeholders.contactPersons')}
                </Typography>
                <ControlledAutocomplete
                  name="contact_person_ids"
                  control={control}
                  options={clientPersonsOptions}
                  fullWidth={true}
                  multiple={true}
                  textFieldProps={{
                    placeholder: t('placeholders.contactPersons'),
                  }}
                />
              </Box>
              <Box maxWidth="500px">
                <Typography fontWeight="700" mb={3}>
                  {t('placeholders.address')}
                </Typography>
                <ControlledTextField
                  name="address"
                  control={control}
                  placeholder={t('placeholders.address')}
                  multiline={true}
                  inputProps={{
                    style: {
                      padding: 0,
                    },
                  }}
                />
              </Box>
              <Box maxWidth="500px">
                <Typography fontWeight="700" mb={3}>
                  {t('placeholders.projectLeader')}
                </Typography>
                <ProjectLeaderAutocomplete
                  name="project_leader_id"
                  control={control}
                />
              </Box>
            </Stack>
            {(showControlsOnDirty && isDirty) || !showControlsOnDirty
              ? controls
              : null}
          </form>
        </Box>
        {client && (
          <Box width="50%" pt={18} pl={4}>
            <ClientInfo client={client.value} showEditClientLink={true} />
          </Box>
        )}
      </Stack>
    </Box>
  )
}
