import { useTranslation } from 'react-i18next'
import { Stack, Box, Typography, TextField } from '@mui/material'
import { QuotationResearchDetails } from '@/types'

type QuotationResearchRoundFormContainerProps = {
  data: QuotationResearchDetails
}

export const QuotationResearchRoundSummary = ({
  data,
}: QuotationResearchRoundFormContainerProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Stack
      sx={{
        borderRadius: 2.5,
        backgroundColor: 'grey.50',
        width: '100%',
        p: 4,
        mb: 3,
      }}
      spacing={2}
    >
      <Typography variant="h6">{data.title}</Typography>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Stack direction="row" spacing={4}>
          <Box>
            <Typography variant="body2" color="grey">
              {t('quotations:titles.researchSpecies')}
            </Typography>
            <Typography variant="body1">{data.species}</Typography>
          </Box>
          <Box>
            <Typography variant="body2" color="grey">
              {t('quotations:titles.researchTypes')}
            </Typography>
            <Typography variant="body1">{data.research_types}</Typography>
          </Box>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Box width={100}>
            <TextField
              value={(data.total_duration_minutes / 60).toFixed(1)}
              label={t('quotations:placeholders.totalHours')}
              disabled={true}
            />
          </Box>
          <Box width={100}>
            <TextField
              value={data.total_price}
              label={t('quotations:placeholders.totalPrice')}
              disabled={true}
            />
          </Box>
        </Stack>
      </Stack>
    </Stack>
  )
}
