import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { useApiClient, getUseHoursReportsKey } from '@/hooks'
import { APIErrorResponse, HoursReport } from '@/types'
import { unwrapErrorResponse, unwrapResponse } from '@/utils'

export const usePostHoursReportApprove = ({
  id,
}: Pick<HoursReport, 'id'>): UseMutationResult<
  HoursReport,
  APIErrorResponse
> => {
  const client = useApiClient()

  const queryClient = useQueryClient()

  const hoursReportsListKey = getUseHoursReportsKey()

  return useMutation(
    () =>
      client
        .post(`/hours_reporting/hr/${id}/approve/`)
        .then(unwrapResponse)
        .catch(unwrapErrorResponse),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(hoursReportsListKey)
      },
    }
  )
}
