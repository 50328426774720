import {
  usePostProject,
  useMutationWrapper,
  useLinkQuotationToProject,
} from '@/hooks'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CardLayout } from '@/components/layouts/CardLayout'
import { ProjectBasicInfo, ProjectBasicInfoForm } from '@/components/forms'
import dayjs from 'dayjs'
import { DATE_FORMAT_YMD, ROUTES_PATH } from '@/constants'
import { getPath } from '@/utils'
import { ProjectStatus } from '@/types'
import { FooterActions } from '@/features'
import { useSnackbar } from 'notistack'

export const ProjectCreateStep = (): JSX.Element => {
  const { t } = useTranslation()

  const { enqueueSnackbar } = useSnackbar()

  const navigate = useNavigate()

  const { mutateAsync: createProject, isLoading: mutateInProcess } =
    useMutationWrapper(usePostProject.bind(null))

  const linkQuotations = useLinkQuotationToProject()

  const handleSubmit = async ({
    client,
    projectLeader,
    projectNumber,
    name,
    description,
    startDate,
    endDate,
    sharepointUrl,
    address,
    isSmp,
    isOnlyPlanning,
    isFieldReportRequired,
    quotations,
    reportingHoursPerVisit,
    travelTimePerVisit,
  }: ProjectBasicInfo) => {
    const projectData = {
      client_id: Number(client?.value.id),
      project_leader_id: projectLeader?.value || null,
      project_number: projectNumber,
      name,
      description,
      start_date: dayjs(startDate).format(DATE_FORMAT_YMD),
      end_date: dayjs(endDate).format(DATE_FORMAT_YMD),
      status: ProjectStatus.New,
      is_smp: isSmp,
      is_only_planning: isOnlyPlanning,
      is_field_report_required: isFieldReportRequired,
      sharepoint_url: sharepointUrl,
      address,
      reporting_hours_per_visit: reportingHoursPerVisit,
      travel_time_per_visit: travelTimePerVisit,
      is_in_gis: false,
    }

    try {
      const project = await createProject(projectData)

      await linkQuotations(project, quotations)

      const extendProjectStep = getPath(ROUTES_PATH.PROJECT_EXTEND, {
        id: project.id,
      })

      navigate(`/${extendProjectStep}?step=2`)
    } catch (e) {
      enqueueSnackbar(`${t('errors:api.execute')}: ${e}`, {
        variant: 'error',
      })
    }
  }

  return (
    <CardLayout title={t('projects:titles.newProject')} cardSx={{ pb: 0 }}>
      <ProjectBasicInfoForm
        onSubmit={handleSubmit}
        controls={
          <FooterActions
            isNextStepDisabled={false}
            mutateInProcess={mutateInProcess}
            onCancel={() => navigate(ROUTES_PATH.PROJECTS_OVERVIEW)}
          />
        }
      />
    </CardLayout>
  )
}
