import { useTranslation } from 'react-i18next'
import { Stack, Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { UseFormReturn, FieldValues } from 'react-hook-form'
import { ObservationLogFormData } from '@/components/forms'
import { FormComponentProps } from '@/types'

type ObservationLogFormActionsProps<T extends FieldValues> = Pick<
  FormComponentProps<T>,
  'onSubmit' | 'defaultValues' | 'isLoading'
> & {
  methods: UseFormReturn<T>
}

export const ObservationLogFormActions = ({
  onSubmit,
  defaultValues,
  isLoading,
  methods,
}: ObservationLogFormActionsProps<ObservationLogFormData>): JSX.Element => {
  const { t } = useTranslation('forms')

  const handleSubmit = methods.handleSubmit((formData) => onSubmit(formData))

  const handleReset = () =>
    methods.reset({
      ...defaultValues,
    })

  return methods.formState.isDirty ? (
    <Stack direction="row" spacing={5} pt={1}>
      <Button variant="textThin" color="secondary" onClick={handleReset}>
        {t('buttons.cancel')}
      </Button>
      <LoadingButton
        variant="contained"
        color="secondary"
        onClick={handleSubmit}
        loading={isLoading}
        disableElevation={true}
        disabled={!methods.formState.isDirty || isLoading}
      >
        <span>{t('buttons.save')}</span>
      </LoadingButton>
    </Stack>
  ) : (
    <></>
  )
}
