import {
  useQueryClient,
  useMutation,
  UseMutationResult,
} from '@tanstack/react-query'
import { useApiClient, getUseQuotationKey } from '@/hooks'
import { unwrapErrorResponse, unwrapResponse } from '@/utils/unwrapResponse'
import { APIErrorResponse, PatchQuotation, Quotation } from '@/types'
import { QUERY_KEYS } from '@/constants'

export const usePatchQuotation = (
  id: number
): UseMutationResult<Quotation, APIErrorResponse, PatchQuotation> => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  return useMutation(
    (params) =>
      client
        .patch(`/quotations/${id}/`, params)
        .then(unwrapResponse)
        .catch(unwrapErrorResponse),
    {
      onSuccess: async (data) => {
        const queryKey = getUseQuotationKey(id)

        queryClient.setQueryData(queryKey, data)
        await queryClient.invalidateQueries(QUERY_KEYS.QUOTATIONS)
      },
    }
  )
}
