import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, FormProvider } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { Box, Stack, Typography } from '@mui/material'
import { Loader } from '@/components'
import {
  ControlledTextField,
  ControlledAutocomplete,
  AutocompleteOption,
} from '@/components/inputs'
import { FormActionButtons } from '@/features/quotationCreate/FormActionButtons'
import {
  useModal,
  useMutationWrapper,
  useProtocolsSpecies,
  useQuotationResearch,
  usePostQuotationResearch,
  usePatchQuotationResearch,
  useQuotationResearchSpecies,
  usePostQuotationResearchSpecie,
  useDeleteQuotationResearchSpecie,
  usePostQuotationResearchGenerate,
  getUseQuotationResearchKey,
  getUseQuotationDetailsKey,
  getUseQuotationResearchSpeciesKey,
} from '@/hooks'
import { quotationResearchSchema } from './validationScheme'
import { yupResolver } from '@hookform/resolvers/yup'
import { Animal, QuotationResearchSpecie, Specie } from '@/types'

type Species = AutocompleteOption<Specie>[]

type ResearchTypes = {
  [key: string]: AutocompleteOption[]
}

type QuotationResearchFormData = {
  name: string
  species: Species
  research_types: ResearchTypes
}

type QuotationResearchFormContainerProps = {
  quotationId: number
  researchId?: number
}

export const QuotationResearchFormContainer = ({
  quotationId,
  researchId,
}: QuotationResearchFormContainerProps): JSX.Element => {
  const { t } = useTranslation()
  const { close } = useModal()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const { data: speciesList } = useProtocolsSpecies()
  const speciesOptions = useMemo(() => {
    if (speciesList?.length) {
      return speciesList.map((specie) => ({
        value: { ...specie },
        title: `[${specie.animal.name}] ${specie.name}`,
      }))
    } else return []
  }, [speciesList])

  const { data: quotationResearch, isLoading: isQuotationResearchLoading } =
    useQuotationResearch({
      quotationId,
      researchId,
    })

  const { mutateAsync: createQuotationResearch } = useMutationWrapper(() =>
    usePostQuotationResearch(quotationId)
  )

  const { mutateAsync: updateQuotationResearch } = useMutationWrapper(() =>
    usePatchQuotationResearch(quotationId)
  )

  const {
    data: quotationResearchSpecies,
    isLoading: isQuotationResearchSpeciesLoading,
  } = useQuotationResearchSpecies(quotationId, researchId)

  const { mutateAsync: createQuotationResearchSpecie } = useMutationWrapper(
    () => usePostQuotationResearchSpecie(quotationId)
  )

  const { mutateAsync: deleteQuotationResearchSpecie } = useMutationWrapper(
    () => useDeleteQuotationResearchSpecie(quotationId)
  )

  const { mutateAsync: generateQuotationResearch } = useMutationWrapper(() =>
    usePostQuotationResearchGenerate(quotationId)
  )

  const defaultValues = useMemo(() => {
    if (speciesList && quotationResearch && quotationResearchSpecies) {
      const species = quotationResearchSpecies.reduce((acc, rs) => {
        if (!acc.some((specie) => specie.value.id === rs.specie.id)) {
          acc.push({
            value: {
              ...rs.specie,
              research_types:
                speciesList.find((s) => s.id === Number(rs.specie.id))
                  ?.research_types || [],
            },
            title: `[${rs.specie.animal.name}] ${rs.specie.name}`,
          })
        }

        return acc
      }, [] as Species)

      const researchTypes: ResearchTypes = {}

      species.forEach((specie) => {
        researchTypes[`${specie.value.id}`] = quotationResearchSpecies.reduce(
          (acc, rs) => {
            if (specie.value.id === rs.specie.id) {
              acc.push({
                value: rs.research_type.id.toString(),
                title: rs.research_type.name,
              })
            }

            return acc
          },
          [] as AutocompleteOption[]
        )
      })

      return {
        name: quotationResearch.title,
        species,
        research_types: researchTypes,
      }
    }

    return {
      name: '',
      species: [],
      research_types: {},
    }
  }, [speciesList, quotationResearch, quotationResearchSpecies])

  const methods = useForm<QuotationResearchFormData>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(quotationResearchSchema),
  })

  useEffect(() => {
    methods.reset(defaultValues)
  }, [methods, defaultValues])

  const selectedSpecies = methods.watch(`species`)

  const selectedAnimals: Animal[] = useMemo(
    () =>
      selectedSpecies?.reduce((acc, value) => {
        if (!acc.some((item) => item.id === value.value.animal.id)) {
          acc.push({ ...value.value.animal })
        }

        return acc
      }, [] as Animal[]),
    [selectedSpecies]
  )

  const onSpeciesChange = (species: Species) => {
    const speciesIds = species.map((specie) => specie.value.id)
    const researchTypes = Object.keys(methods.getValues(`research_types`))

    speciesIds.forEach((id) => {
      if (!researchTypes.some((rt) => Number(rt) === id)) {
        methods.register(`research_types.${id}`, { value: [] })
      }
    })

    researchTypes.forEach((key) => {
      if (!speciesIds.includes(Number(key))) {
        methods.unregister(`research_types.${key}`)
      }
    })

    if (methods.getValues(`research_types`) === undefined) {
      methods.setValue(`research_types`, {})
    }
  }

  const saveData = async (formData: QuotationResearchFormData) => {
    const formResearchTypes = Object.keys(formData.research_types).flatMap(
      (specieId) =>
        formData.research_types[specieId].map((item) => ({
          research_type_id: item.value.toString(),
          specie_id: specieId,
        }))
    )

    try {
      const researchData = await Promise.resolve(
        !quotationResearch
          ? createQuotationResearch({
              title: formData.name,
            })
          : updateQuotationResearch({
              id: quotationResearch.id,
              title: formData.name,
            })
      )

      const createdResearchTypes: Promise<QuotationResearchSpecie>[] = []

      formResearchTypes.forEach((researchType) => {
        if (
          !quotationResearchSpecies ||
          !quotationResearchSpecies.some(
            (rsp) =>
              rsp.research_type.id.toString() ===
                researchType.research_type_id &&
              rsp.specie.id.toString() === researchType.specie_id
          )
        ) {
          createdResearchTypes.push(
            createQuotationResearchSpecie({
              research_id: researchData.id,
              research_type_id: researchType.research_type_id,
              specie_id: researchType.specie_id,
            })
          )
        }
      })

      await Promise.all(createdResearchTypes)

      if (researchId && quotationResearch && quotationResearchSpecies) {
        const deleteResearchTypes: Promise<unknown>[] = []

        quotationResearchSpecies.forEach((item) => {
          if (
            !formResearchTypes.some(
              (row) =>
                item.research_type.id.toString() === row.research_type_id &&
                item.specie.id.toString() === row.specie_id
            )
          ) {
            deleteResearchTypes.push(
              deleteQuotationResearchSpecie({
                id: item.id,
                research_id: quotationResearch.id,
              })
            )
          }
        })

        await Promise.all(deleteResearchTypes)
      }

      await generateQuotationResearch({ id: researchData.id })

      const quotationResearchQueryKey = getUseQuotationResearchKey(
        researchData.id
      )

      await queryClient.invalidateQueries(quotationResearchQueryKey)

      const researchSpeciesQueryKey = getUseQuotationResearchSpeciesKey(
        quotationId,
        researchData.id
      )

      await queryClient.invalidateQueries(researchSpeciesQueryKey)

      const quotationDetailsQueryKey = getUseQuotationDetailsKey(quotationId)

      await queryClient.invalidateQueries(quotationDetailsQueryKey)

      close()
    } catch (e) {
      enqueueSnackbar(`${t('errors:api.execute')}: ${e}`, {
        variant: 'error',
      })
    }
  }

  const handleSubmit = methods.handleSubmit(async (formData) => {
    enqueueSnackbar(t('forms:labels.saving-data'))

    try {
      await saveData(formData)
    } finally {
      closeSnackbar()
    }
  })

  const handleReset = () =>
    methods.reset({
      ...defaultValues,
    })

  if (
    researchId &&
    (isQuotationResearchLoading || isQuotationResearchSpeciesLoading)
  )
    return <Loader />

  return (
    <FormProvider {...methods}>
      <Box width="460px">
        <ControlledTextField
          name="name"
          control={methods.control}
          type="text"
          placeholder={t('quotations:placeholders.researchName')}
          errorMessagePrefix=""
          sx={{ mb: 4 }}
        />
      </Box>
      <ControlledAutocomplete
        name="species"
        control={methods.control}
        options={speciesOptions}
        isOptionEqualToValue={(option, value) =>
          option.value.id === value.value.id
        }
        onChangeCustom={(items) => onSpeciesChange(items as Species)}
        multiple={true}
        fullWidth={true}
        textFieldProps={{
          label: t('quotations:placeholders.species'),
        }}
        sx={{ mb: 4 }}
      />
      <Stack>
        {selectedAnimals?.map(({ name, id }) => (
          <Stack key={id}>
            <Typography variant="h5" mb={2}>
              {name}
            </Typography>
            {selectedSpecies.map((specie) =>
              specie.value.animal.id === id ? (
                <Stack
                  key={specie.value.id}
                  direction="row"
                  spacing={2}
                  sx={{
                    borderRadius: 2.5,
                    backgroundColor: 'grey.50',
                    p: 4,
                    mb: 4,
                  }}
                >
                  <Box mt={2}>{specie.value.name}</Box>
                  <Box width="100%">
                    {specie.value.research_types && (
                      <ControlledAutocomplete
                        name={`research_types.${specie.value.id}`}
                        control={methods.control}
                        options={specie.value.research_types.map(
                          (researchType) => ({
                            value: researchType.id.toString(),
                            title: researchType.name,
                          })
                        )}
                        multiple={true}
                        fullWidth={true}
                        textFieldProps={{
                          label: t('quotations:placeholders.researchTypes'),
                        }}
                      />
                    )}
                  </Box>
                </Stack>
              ) : null
            )}
          </Stack>
        ))}
        {methods.formState.isDirty && (
          <FormActionButtons
            cancelClick={handleReset}
            saveClick={handleSubmit}
            isLoading={methods.formState.isSubmitting}
            disabled={
              !methods.formState.isDirty || methods.formState.isSubmitting
            }
          />
        )}
      </Stack>
    </FormProvider>
  )
}
