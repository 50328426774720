import { useTranslation } from 'react-i18next'
import { Box, Stack, Typography, Button, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { PlusCircle } from 'react-feather'
import { CardLayout, Loader } from '@/components'
import {
  QuotationInfo,
  QuotationResearchModal,
  QuotationResearchRoundFormContainer,
  QuotationOverheadFormContainer,
  QuotationAdditionalFormContainer,
} from '@/features'
import { useModal, useQuotationDetails, useStepperContext } from '@/hooks'

type QuotationRowsStepProps = { quotationId: number }

export const QuotationRowsStep = ({
  quotationId,
}: QuotationRowsStepProps): JSX.Element => {
  const { t } = useTranslation('quotations')
  const { open } = useModal()
  const { stepBack, stepForward } = useStepperContext()

  const { data: quotationDetails } = useQuotationDetails({ id: quotationId })

  const handleEditResearch = (researchId?: number) => {
    open(
      <QuotationResearchModal
        quotationId={quotationId}
        researchId={researchId}
      />
    )
  }

  if (!quotationDetails) return <Loader />

  return (
    <CardLayout
      title={t('titles.quotationRows')}
      footerAction={
        <Stack direction="row" spacing={6}>
          <Button variant="text" onClick={stepBack}>
            {t('buttons.cancel')}
          </Button>
          <LoadingButton variant="contained" onClick={stepForward}>
            <span>{t('buttons.confirmInformation')}</span>
          </LoadingButton>
        </Stack>
      }
    >
      <QuotationInfo quotationDetails={quotationDetails} />
      <Box mt={8}>
        <Typography variant="h6" sx={{ mt: 8 }}>
          {t('titles.financial')}
        </Typography>
        <hr style={{ marginTop: 20, marginBottom: 20 }} />

        {quotationDetails.researches.map((quotationResearch) => (
          <QuotationResearchRoundFormContainer
            key={quotationResearch.id}
            quotationId={quotationId}
            data={quotationResearch}
            onEdit={handleEditResearch}
          />
        ))}
        <Button
          variant="text"
          onClick={() => handleEditResearch()}
          startIcon={<PlusCircle size="16px" />}
          sx={{ mt: 6 }}
        >
          {t('buttons.addResearch')}
        </Button>
        {quotationDetails.researches.length > 0 && (
          <>
            <Typography variant="h6" sx={{ mt: 8, mb: 4 }}>
              {t('titles.overhead')}
            </Typography>
            <QuotationOverheadFormContainer
              quotationId={quotationId}
              data={quotationDetails.overhead}
            />
          </>
        )}
        <Typography variant="h6" sx={{ mt: 8 }}>
          {t('titles.additional')}
        </Typography>
        <hr style={{ marginTop: 20, marginBottom: 20 }} />
        <QuotationAdditionalFormContainer
          quotationId={quotationId}
          data={quotationDetails.additional_lines}
        />
      </Box>
      <Typography variant="h6" sx={{ mt: 8 }}>
        {t('titles.total')}
      </Typography>
      <hr style={{ marginTop: 20, marginBottom: 20 }} />
      <Stack direction="row" justifyContent="flex-end">
        <Stack spacing={4}>
          <TextField
            value={quotationDetails.subtotal_price}
            label={t('labels.totalExcVat')}
            disabled={true}
          />
          <TextField
            value={quotationDetails.tax_price}
            label={t('labels.21Vat')}
            disabled={true}
          />
          <TextField
            value={quotationDetails.total_price}
            label={t('labels.totalInclVat')}
            disabled={true}
          />
        </Stack>
      </Stack>
    </CardLayout>
  )
}
