import { UseQueryResult } from '@tanstack/react-query'
import { useQueryList } from '@/hooks/api/useQueryList'
import { APIErrorResponse, QuotationPaymentTerm } from '@/types'
import { QUERY_KEYS } from '@/constants'

export const getUseQuotationPaymentsTermsKey = (
  quotationId: number
): string[] =>
  QUERY_KEYS.QUOTATION_PAYMENT_TERMS.map((key) =>
    key === ':quotationId' ? quotationId.toString() : key
  )

export const useQuotationPaymentsTerms = (
  quotationId: number
): UseQueryResult<QuotationPaymentTerm[], APIErrorResponse> => {
  const queryKey = getUseQuotationPaymentsTermsKey(quotationId)

  return useQueryList({
    key: queryKey,
    url: `/quotations/${quotationId}/payment_terms/`,
    options: {
      staleTime: Infinity,
    },
  })
}
