import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Grid, Box, Button, Typography } from '@mui/material'
import { ArrowRight } from 'react-feather'
import {
  SpeciesResearchTypes,
  ProtocolRoundInfoAccordion,
  SunCalendarAccordion,
  RoundForm,
  RoundFormData,
  RoundVisitInfo,
} from '@/features'
import { CardLayout, CardAttribute } from '@/components'
import {
  ProcessingStatusForm,
  ProcessingStatusFormData,
} from '@/components/forms'
import { getResearcherOptionLabel } from '@/components/inputs'
import { usePatchRound, usePutRoundStatus, useMutationWrapper } from '@/hooks'
import { ManagerResearchVisitEventWithProjectInfo } from '@/types'
import { capitalizeFirstLetter, getPath } from '@/utils'
import dayjs from 'dayjs'
import { ROUTES_PATH } from '@/constants'

type RoundInfoProps = {
  round: ManagerResearchVisitEventWithProjectInfo
}

export const RoundInfo = ({ round }: RoundInfoProps): JSX.Element => {
  const { t } = useTranslation('monitoring')
  const navigate = useNavigate()

  const { mutateAsync: updateRound, isLoading: mutateRoundInProcess } =
    useMutationWrapper(usePatchRound.bind(null, round.id))

  const { mutateAsync: updateRoundStatus, isLoading: mutateStatusInProcess } =
    useMutationWrapper(usePutRoundStatus.bind(null, round.id))

  const roundFormDefaultValues = useMemo<RoundFormData>(
    () => ({
      startDatetime: round.start_datetime,
      endDatetime: round.end_datetime,
      researchers: round.researchers.map((researcher) =>
        getResearcherOptionLabel(researcher)
      ),
    }),
    [round.start_datetime, round.end_datetime, round.researchers]
  )

  const handleRoundDataSubmit = async ({
    startDatetime,
    endDatetime,
    researchers,
  }: RoundFormData) => {
    const roundData = {
      start_datetime: startDatetime && dayjs(startDatetime).utc().format(),
      end_datetime: endDatetime && dayjs(endDatetime).utc().format(),
      researcher_ids: researchers.map(({ value }) => value),
    }

    await updateRound(roundData)
  }

  const handleStatusSubmit = async ({
    processingStatus,
    processingComment,
  }: ProcessingStatusFormData) => {
    const roundStatusData = {
      processing_status: !!processingStatus ? processingStatus : null,
      processing_comment: !!processingComment ? processingComment : null,
    }

    await updateRoundStatus(roundStatusData, {
      successMessageKey: 'monitoring:notifications.processingStatusChanged',
    })
  }

  const areaProtocol = round.research_area.research_area_protocols.find(
    (rap) => rap.protocol.id === round.protocol_id
  )

  const protocol = areaProtocol?.protocol

  const roundResearchers = areaProtocol?.area_protocol_researchers?.find(
    (apr) => apr.round_type === round.round_type
  )

  return (
    <CardLayout title={t('titles.roundNumber', { roundNumber: round.id })}>
      <Grid container={true} rowSpacing={5} columnSpacing={4} mb={3}>
        <Grid item={true} xs={12} sm={6}>
          <CardAttribute
            title={t('titles.projectName')}
            value={
              <Button
                variant="textThin"
                endIcon={<ArrowRight size={16} />}
                onClick={() => {
                  if (round.research_area.project?.id)
                    navigate(
                      getPath(ROUTES_PATH.PROJECT_DETAIL, {
                        id: round.research_area.project?.id,
                      })
                    )
                }}
              >
                {round.project_name}
              </Button>
            }
          />
        </Grid>
        <Grid item={true} xs={6} sm={3}>
          <CardAttribute
            title={t('titles.projectNumber')}
            value={round.project_number}
          />
        </Grid>
        <Grid item={true} xs={6} sm={3}>
          <CardAttribute
            title={t('titles.projectClient')}
            value={round.research_area.project?.client_name}
          />
        </Grid>
        <Grid item={true} xs={12} sm={6}>
          <CardAttribute
            title={t('titles.researchArea')}
            value={`${t('titles.researchAreaNumber', {
              areaNumber: round.research_area_id,
            })} - ${round.research_area.location_name}`}
          />
        </Grid>
        <Grid item={true} xs={12} sm={6}>
          <CardAttribute
            title={t('titles.researchAreaNotes')}
            value={
              !!round.research_area.notes ? round.research_area.notes : '-'
            }
          />
        </Grid>
        <Grid item={true} xs={12} sm={6}>
          <CardAttribute
            title={t('titles.researchers')}
            value={
              roundResearchers?.researchers?.map(
                ({ first_name, last_name }, i) =>
                  `${i > 0 ? ', ' : ''}${first_name} ${last_name}`
              ) ?? '-'
            }
          />
        </Grid>
        {protocol && (
          <>
            <Grid item={true} xs={12}>
              <ProtocolRoundInfoAccordion
                title={t('titles.protocolInfo')}
                allRounds={protocol.rounds}
                round={protocol.rounds.find((r) =>
                  round.visit_protocol_id
                    ? r.id === round.visit_protocol_id
                    : r.round_type === round.round_type
                )}
              />
            </Grid>
            <Grid item={true} xs={12}>
              <SpeciesResearchTypes
                protocol={protocol}
                event_original_protocols={round.original_protocols}
                visit_protocol_id={round.visit_protocol_id}
                speciesColumnWidth="290px"
                researchTypesColumnWidth="100%"
              />
            </Grid>
          </>
        )}

        {round.sun_calendar && !!round.sun_calendar.length && (
          <Grid item={true} xs={12}>
            <SunCalendarAccordion
              title={t('titles.sunCalendarInfo')}
              sunCalendar={round.sun_calendar}
            />
          </Grid>
        )}

        <Grid item={true} xs={12}>
          <RoundForm
            round={round}
            defaultValues={roundFormDefaultValues}
            onSubmit={handleRoundDataSubmit}
            isLoading={mutateRoundInProcess}
          />
          {!!round.is_event_passed && (
            <Typography color="warning.dark">
              {t('titles.roundIsPassed')}
            </Typography>
          )}
        </Grid>

        <Grid item={true} xs={3}>
          <CardAttribute
            title={t('titles.roundType')}
            value={capitalizeFirstLetter(round.round_type)}
          />
        </Grid>
        <Grid item={true} xs={3}>
          <CardAttribute title={t('titles.round')} value={round.round_number} />
        </Grid>
        <Grid item={true} xs={6}>
          <CardAttribute
            title={t('titles.reasonsOfUncertainty')}
            color={
              round.reasons_of_uncertainty?.length ? 'error.main' : 'inherit'
            }
            value={
              round.reasons_of_uncertainty?.length
                ? round.reasons_of_uncertainty.map((rou, i) => (
                    <Typography key={i} variant="body2" color="error.main">
                      {t(`uncertaintyReason.${rou}`)}
                    </Typography>
                  ))
                : '-'
            }
          />
        </Grid>

        <Grid item={true} sm={12}>
          {round.research_visit_log?.id && (
            <CardAttribute
              title={t('titles.visitLog')}
              value={
                <Box ml={2}>
                  <RoundVisitInfo researchVisitLog={round.research_visit_log} />
                </Box>
              }
            />
          )}
        </Grid>
        <Grid item={true} sm={12}>
          <CardAttribute
            title={t('titles.processingStatus')}
            value={
              <ProcessingStatusForm
                statusSelect={true}
                defaultValues={{
                  processingStatus: round.processing_status ?? '',
                  processingComment: round.processing_comment ?? '',
                }}
                onSubmit={handleStatusSubmit}
                isLoading={mutateStatusInProcess}
              />
            }
          />
        </Grid>
      </Grid>
    </CardLayout>
  )
}
