import { ControlledFieldProps } from '@/types'
import { FieldValues } from 'react-hook-form/dist/types/fields'
import { AutocompleteOption, ControlledAutocomplete } from '@/components/inputs'
import { useMemo } from 'react'
import { useUsersManagers } from '@/hooks'

export type ProjectLeaderAutocompleteProps<D extends FieldValues> =
  ControlledFieldProps<D>

export const ProjectLeaderAutocomplete = <D extends FieldValues>({
  name,
  control,
}: ProjectLeaderAutocompleteProps<D>): JSX.Element => {
  const { data: leaders } = useUsersManagers()

  const leaderOptions: AutocompleteOption<number>[] = useMemo(() => {
    if (leaders && leaders.length > 0) {
      return leaders.map(
        (l) =>
          ({
            title: `${l.first_name} ${l.last_name} [${l.email}]`,
            value: l.id,
          } as AutocompleteOption<number>)
      )
    }

    return []
  }, [leaders])

  return (
    <ControlledAutocomplete
      options={leaderOptions}
      name={name}
      control={control}
      multiple={false}
    />
  )
}
