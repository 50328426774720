import { useQueryClient } from '@tanstack/react-query'
import { AutocompleteOption } from '@/components/inputs'
import {
  getUseProjectKey,
  useMutationWrapper,
  usePostQuotationAttachProject,
  usePostQuotationDetachProject,
} from '@/hooks'
import { Project, Quotation } from '@/types'

export const useLinkQuotationToProject = () => {
  const queryClient = useQueryClient()

  const { mutateAsync: attachProject } = useMutationWrapper(() =>
    usePostQuotationAttachProject()
  )

  const { mutateAsync: detachProject } = useMutationWrapper(() =>
    usePostQuotationDetachProject()
  )

  return async (
    project: Project,
    quotations: AutocompleteOption<number>[] | undefined
  ) => {
    const attachProjects: Promise<Quotation>[] = []

    if (quotations) {
      quotations.forEach((q) => {
        if (!project.quotations?.some((pq) => q.value === pq.id)) {
          attachProjects.push(
            attachProject({ id: q.value, project_id: project.id })
          )
        }
      })
    }

    if (project.quotations) {
      project.quotations.forEach((pq) => {
        if (!quotations?.some((q) => pq.id === q.value)) {
          attachProjects.push(detachProject({ id: pq.id }))
        }
      })
    }

    if (attachProjects.length > 0) {
      await Promise.all(attachProjects)

      const queryKey = getUseProjectKey(project.id)

      await queryClient.invalidateQueries(queryKey)
    }
  }
}
