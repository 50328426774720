import { useTranslation } from 'react-i18next'
import { Box, Link, Stack } from '@mui/material'
import { Client } from '@/types'

type ClientInfoProps = { client: Client; showEditClientLink?: boolean }

export const ClientInfo = ({
  client,
  showEditClientLink = false,
}: ClientInfoProps): JSX.Element => {
  const { t } = useTranslation('quotations')

  return (
    <Stack spacing={4}>
      <Box>
        {t('clientInfo.client')}:&nbsp;{client.name}
      </Box>
      <Box>
        {t('clientInfo.address')}:&nbsp;{client.address}
      </Box>
      <Box>
        {t('clientInfo.contact')}:&nbsp;{client.contact_email}
      </Box>
      <Box>
        {t('clientInfo.cocNumber')}:&nbsp;{client.coc_number}
      </Box>
      <Box>
        {t('clientInfo.vatID')}:&nbsp;{client.vat_id}
      </Box>
      {showEditClientLink && (
        <Box>
          <Link
            href={`${process.env.REACT_APP_EDIT_CLIENT_ADMIN_PAGE}${client.id}`}
            target="_blank"
            rel="noopener noreferrer"
            color="primary.light"
          >
            {t('clientInfo.editClientDataLink')}
          </Link>
        </Box>
      )}
    </Stack>
  )
}
