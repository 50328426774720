import { lazy } from 'react'
import { RouteObject } from 'react-router'

// Layouts
import { AuthLayout, PortalLayout } from '@/layouts'
import {
  AuthenticationGuard,
  AuthorizationGuard,
  GuestGuard,
} from '@/components'

// Auth pages
import SignIn from './pages/SignIn'
import ResetPassword from './pages/ResetPassword'
import ResetPasswordConfirmation from './pages/ResetPasswordConfirmation'
import Page404 from './pages/Page404'
import { ROUTES_PATH, ROUTES_PATH_PARTS } from '@/constants'
import { UserGroups } from '@/types'

// Portal pages
import Home from '@/pages/Home'
import RoundDetail from '@/pages/RoundDetail'

const ProjectCreate = lazy(() => import('./pages/ProjectCreate'))
const ProjectExtendInfo = lazy(() => import('./pages/ProjectExtendInfo'))
const ProjectDetail = lazy(() => import('./pages/ProjectDetail'))
const DataValidation = lazy(() => import('./pages/DataValidation'))
const Monitoring = lazy(() => import('./pages/Monitoring'))
const ObservationLogs = lazy(() => import('./pages/ObservationLogs'))
const Quotations = lazy(() => import('./pages/Quotations'))
const QuotationCreate = lazy(() => import('./pages/QuotationCreate'))
const QuotationDetail = lazy(() => import('./pages/QuotationDetail'))
const HoursReporting = lazy(() => import('./pages/HoursReporting'))
const SoundRecords = lazy(() => import('./pages/SoundRecords'))

const ProjectDetailPlanning = lazy(
  () => import('./pages/ProjectDetailPlanning/ProjectDetailPlanning')
)
const ProjectDetailInfo = lazy(() => import('./pages/ProjectDetailInfo'))
const Planning = lazy(() => import('./pages/Planning'))

export const routes: RouteObject[] = [
  {
    path: '/',
    element: (
      <AuthenticationGuard>
        <PortalLayout />
      </AuthenticationGuard>
    ),
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: ROUTES_PATH.PROJECT_CREATE,
        element: (
          <AuthorizationGuard allowedGroups={[UserGroups.MANAGER]}>
            <ProjectCreate />
          </AuthorizationGuard>
        ),
      },
      {
        path: ROUTES_PATH.PROJECT_EXTEND,
        element: (
          <AuthorizationGuard allowedGroups={[UserGroups.MANAGER]}>
            <ProjectExtendInfo />
          </AuthorizationGuard>
        ),
      },
      {
        path: ROUTES_PATH.PROJECT_DETAIL,
        element: (
          <AuthorizationGuard allowedGroups={[UserGroups.MANAGER]}>
            <ProjectDetail />
          </AuthorizationGuard>
        ),
        children: [
          {
            index: true,
            element: <ProjectDetailInfo />,
          },
          {
            path: ROUTES_PATH_PARTS.PLANNING,
            element: <ProjectDetailPlanning />,
          },
        ],
      },
      {
        path: ROUTES_PATH.PLANNING,
        element: (
          <AuthorizationGuard allowedGroups={[UserGroups.MANAGER]}>
            <Planning />
          </AuthorizationGuard>
        ),
      },
      {
        path: ROUTES_PATH_PARTS.DATA_VALIDATION,
        element: (
          <AuthorizationGuard
            allowedGroups={[UserGroups.MANAGER, UserGroups.EMPLOYEE]}
          >
            <DataValidation />
          </AuthorizationGuard>
        ),
      },
      {
        path: ROUTES_PATH.MONITORING,
        element: (
          <AuthorizationGuard allowedGroups={[UserGroups.MANAGER]}>
            <Monitoring />
          </AuthorizationGuard>
        ),
      },
      {
        path: ROUTES_PATH.MONITORING_DETAIL,
        element: (
          <AuthorizationGuard allowedGroups={[UserGroups.MANAGER]}>
            <RoundDetail />
          </AuthorizationGuard>
        ),
      },
      {
        path: ROUTES_PATH_PARTS.OBSERVATION_LOGS,
        element: (
          <AuthorizationGuard
            allowedGroups={[
              UserGroups.MANAGER,
              UserGroups.EMPLOYEE,
              UserGroups.SOUND_TECHNICIAN,
            ]}
          >
            <ObservationLogs />
          </AuthorizationGuard>
        ),
      },
      {
        path: ROUTES_PATH.QUOTATIONS,
        element: (
          <AuthorizationGuard allowedGroups={[UserGroups.MANAGER]}>
            <Quotations />
          </AuthorizationGuard>
        ),
      },
      {
        path: ROUTES_PATH.QUOTATION_CREATE,
        element: (
          <AuthorizationGuard allowedGroups={[UserGroups.MANAGER]}>
            <QuotationCreate />
          </AuthorizationGuard>
        ),
      },
      {
        path: ROUTES_PATH.QUOTATION_EXTEND,
        element: (
          <AuthorizationGuard allowedGroups={[UserGroups.MANAGER]}>
            <QuotationCreate />
          </AuthorizationGuard>
        ),
      },
      {
        path: ROUTES_PATH.QUOTATION_DETAIL,
        element: (
          <AuthorizationGuard allowedGroups={[UserGroups.MANAGER]}>
            <QuotationDetail />
          </AuthorizationGuard>
        ),
      },
      {
        path: ROUTES_PATH_PARTS.HOURS_REPORTING,
        element: (
          <AuthorizationGuard allowedGroups={[UserGroups.HR]}>
            <HoursReporting />
          </AuthorizationGuard>
        ),
      },
      {
        path: ROUTES_PATH_PARTS.SOUND_RECORDS,
        element: (
          <AuthorizationGuard allowedGroups={[UserGroups.SOUND_TECHNICIAN]}>
            <SoundRecords />
          </AuthorizationGuard>
        ),
      },
    ],
  },
  {
    path: ROUTES_PATH_PARTS.AUTH,
    element: <AuthLayout />,
    children: [
      {
        path: ROUTES_PATH_PARTS.SIGN_IN,
        element: (
          <GuestGuard>
            <SignIn />
          </GuestGuard>
        ),
      },
      {
        path: ROUTES_PATH_PARTS.RESET_PASSWORD,
        element: (
          <GuestGuard>
            <ResetPassword />
          </GuestGuard>
        ),
      },
      {
        path: ROUTES_PATH_PARTS.RESET_PASSWORD_CONFIRM,
        element: (
          <GuestGuard>
            <ResetPasswordConfirmation />
          </GuestGuard>
        ),
      },
    ],
  },
  {
    path: '*',
    children: [
      {
        path: '*',
        element: <Page404 />,
      },
    ],
  },
]
