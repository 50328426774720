import { APIErrorResponse, QuotationResearchSpecie } from '@/types'
import { UseQueryResult } from '@tanstack/react-query'
import { useQueryList } from '@/hooks/api/useQueryList'
import { QUERY_KEYS } from '@/constants'

export const getUseQuotationResearchSpeciesKey = (
  quotationId: number,
  researchId?: number
): string[] | undefined =>
  researchId
    ? QUERY_KEYS.QUOTATION_RESEARCH_SPECIES.map((key) =>
        key === ':quotationId'
          ? quotationId.toString()
          : key === ':id'
          ? researchId.toString()
          : key
      )
    : undefined

export const useQuotationResearchSpecies = (
  quotationId: number,
  researchId?: number
): UseQueryResult<QuotationResearchSpecie[], APIErrorResponse> => {
  const queryKey = getUseQuotationResearchSpeciesKey(quotationId, researchId)

  return useQueryList({
    key: queryKey,
    url: `/quotations/${quotationId}/researches/${researchId}/species/`,
    options: {
      enabled: !!researchId,
      staleTime: Infinity,
    },
  })
}
