import * as yup from 'yup'
import { mapRules } from '@/utils'
import translation from 'i18next'

export const quotationOverheadSchema = yup.lazy((map) =>
  yup.object(
    mapRules(
      map,
      yup.object({
        id: yup.number().required(),
        amount_per_round: yup
          .number()
          .typeError(translation.t('errors:validation.numberType'))
          .min(0, translation.t('errors:validation.min', { min: 0 }))
          .required(),
        price_per_unit: yup
          .number()
          .typeError(translation.t('errors:validation.numberType'))
          .min(1, translation.t('errors:validation.min', { min: 1 }))
          .required(),
      })
    )
  )
)
