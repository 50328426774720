import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Stack, Typography, Button, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import {
  QuotationInfo,
  QuotationInvoiceTextFormContainer,
  QuotationPaymentsTermsFormContainer,
  QuotationSummary,
} from '@/features'
import { CardLayout, Loader } from '@/components'
import { useSnackbar } from 'notistack'
import {
  useStepperContext,
  useQuotationDetails,
  useMutationWrapper,
  usePostQuotationGenerateInvoice,
  getUseQuotationDetailsKey,
} from '@/hooks'
import { useQueryClient } from '@tanstack/react-query'
import { getPath } from '@/utils'
import { ROUTES_PATH } from '@/constants'

type QuotationPaymentsStepProps = { quotationId: number }

export const QuotationPaymentsStep = ({
  quotationId,
}: QuotationPaymentsStepProps): JSX.Element => {
  const { t } = useTranslation('quotations')

  const { stepBack } = useStepperContext()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  const { data: quotationDetails } = useQuotationDetails({ id: quotationId })

  const {
    mutateAsync: generateQuotationInvoice,
    isLoading: isMutationInProcess,
  } = useMutationWrapper(() => usePostQuotationGenerateInvoice(quotationId))

  const invoice = async () => {
    try {
      await generateQuotationInvoice({ id: quotationId })

      const quotationQueryKey = getUseQuotationDetailsKey(quotationId)

      await queryClient.invalidateQueries(quotationQueryKey)

      navigate(
        getPath(ROUTES_PATH.QUOTATION_DETAIL, {
          id: quotationId,
        })
      )
    } catch (e) {
      enqueueSnackbar(`${t('errors:api.execute')}: ${e}`, {
        variant: 'error',
      })
    }
  }

  if (!quotationDetails) return <Loader />

  return (
    <CardLayout
      title={t('titles.quotationRows')}
      footerAction={
        <Stack direction="row" spacing={6}>
          <Button variant="text" onClick={stepBack}>
            {t('buttons.cancel')}
          </Button>
          <LoadingButton
            variant="contained"
            onClick={invoice}
            loading={isMutationInProcess}
          >
            <span>{t('buttons.generateInvoice')}</span>
          </LoadingButton>
        </Stack>
      }
    >
      <QuotationInfo quotationDetails={quotationDetails} />
      <hr style={{ marginTop: 20, marginBottom: 20 }} />
      <QuotationInvoiceTextFormContainer quotationDetails={quotationDetails} />
      <Box mt={8}>
        <QuotationSummary quotationDetails={quotationDetails} />
      </Box>
      <hr style={{ marginTop: 20, marginBottom: 20 }} />
      <Typography variant="h6" sx={{ my: 4 }}>
        {t('titles.paymentTerms')}
      </Typography>
      <QuotationPaymentsTermsFormContainer
        quotationId={quotationId}
        totalPrice={Number(quotationDetails.total_price)}
        subtotalPrice={Number(quotationDetails.subtotal_price)}
      />
    </CardLayout>
  )
}
