import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { Box, Button, Stack } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import {
  ControlledTextField,
  ControlledFileUpload,
  ControlledAutocomplete,
  AutocompleteOption,
} from '@/components/inputs'
import { useMutationWrapper, usePostSoundRecord } from '@/hooks'
import { ObservationTypeCode, SoundRecord } from '@/types'
import {
  AMPHIBIANS_SPECIE_OPTIONS,
  BATS_SPECIE_OPTIONS,
  BIRDS_BREEDING_SPECIE_OPTIONS,
  MAMMALS_SPECIE_OPTIONS,
  OTHER_BAT_SPECIE_OPTION_VALUE,
} from '@/components/forms'
import { SelectOption } from '@/components'

export type SoundRecordFormData = {
  spectrogram_link: File | undefined
  specie_identified: AutocompleteOption[]
  other_specie?: string
  analysis_notes: string
}

export type SoundRecordFormContainerProps = {
  researchObservationLogType: ObservationTypeCode
  data: SoundRecord
  setUpdatedSoundRecord?: (data: SoundRecord) => void
  onSuccessUpdate: () => void
}

const getSpeciesOptionsByType = (type: string): SelectOption[] | [] => {
  switch (type) {
    case 'bats':
      return BATS_SPECIE_OPTIONS
    case 'bats_feeding_area':
      return BATS_SPECIE_OPTIONS
    case 'bats_flight_path':
      return BATS_SPECIE_OPTIONS
    case 'courtship_territory':
      return []
    case 'birds_breeding':
      return BIRDS_BREEDING_SPECIE_OPTIONS
    case 'mammals':
      return MAMMALS_SPECIE_OPTIONS
    case 'amphibian_fish':
      return AMPHIBIANS_SPECIE_OPTIONS
    case 'reptiles':
      return []
    case 'insects':
      return []
    default:
      return []
  }
}

export const SoundRecordFormContainer = ({
  researchObservationLogType,
  data,
  setUpdatedSoundRecord,
  onSuccessUpdate,
}: SoundRecordFormContainerProps): JSX.Element => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const { mutateAsync: editSoundRecord, isLoading: mutateInProcess } =
    useMutationWrapper(() => usePostSoundRecord(data.id))

  let otherSpecies = ''

  const species = data.specie_identified?.split(',').reduce((acc, si) => {
    const fs = getSpeciesOptionsByType(researchObservationLogType).find(
      (s) => s.value === si.trim()
    )

    if (fs) {
      acc.push({
        value: fs?.value,
        title: fs?.name,
      })
    } else {
      otherSpecies = si.trim()
    }

    return acc
  }, [] as AutocompleteOption[])

  const defaultValues = useMemo(
    () => ({
      spectrogram_link: undefined,
      specie_identified: species ?? [],
      other_specie: otherSpecies,
      analysis_notes: data.analysis_notes ?? '',
    }),
    [data]
  )

  const methods = useForm<SoundRecordFormData>({
    mode: 'onChange',
    defaultValues,
  })

  useEffect(() => {
    methods.reset(defaultValues)
  }, [defaultValues])

  const handleSubmitForm = async (formData: SoundRecordFormData) => {
    enqueueSnackbar('Saving...', {
      variant: 'default',
    })

    const params = {
      spectrogram_link: formData.spectrogram_link,
      analysis_notes: formData.analysis_notes,
      specie_identified: formData.specie_identified.reduce(
        (acc, si) => `${acc?.length > 0 ? `${acc}, ` : ''}${si.value}`,
        `${formData.other_specie}`
      ),
    }

    try {
      await editSoundRecord(params, {
        successMessageKey: 'soundRecords:notifications.soundRecordUpdated',
        onSuccess: (data) => {
          if (setUpdatedSoundRecord) {
            setUpdatedSoundRecord(data)
          }

          onSuccessUpdate()
        },
      })
    } catch (e) {
      enqueueSnackbar(`${t('errors:api.execute')}: ${e}`, {
        variant: 'error',
      })
    }
  }

  const handleReset = () =>
    methods.reset({
      ...defaultValues,
    })

  const options = getSpeciesOptionsByType(researchObservationLogType).map(
    (option) => ({
      value: option.value,
      title: option.name,
    })
  )

  const watchedSpecie = methods.watch('specie_identified')

  return (
    <form onSubmit={methods.handleSubmit(handleSubmitForm)}>
      <Stack spacing={4}>
        <Box width={160}>
          <ControlledFileUpload
            name="spectrogram_link"
            control={methods.control}
            label={t('soundRecords:sound-record.fieldTitles.spectrogramLink')}
            errorMessagePrefix=""
          />
        </Box>
        <Stack direction="row" spacing={4}>
          <Box width={300}>
            <ControlledAutocomplete
              name="specie_identified"
              control={methods.control}
              options={options}
              multiple={true}
              placeholder={t(
                'soundRecords:sound-record.fieldTitles.specieIdentified'
              )}
              fullWidth={true}
            />
          </Box>
          {watchedSpecie?.some(
            (s) => s.value === OTHER_BAT_SPECIE_OPTION_VALUE
          ) && (
            <Box width={300}>
              <ControlledTextField
                control={methods.control}
                name="other_specie"
                placeholder={t(
                  'soundRecords:sound-record.fieldTitles.otherSpecie'
                )}
                errorMessagePrefix=""
              />
            </Box>
          )}
        </Stack>
        <Box width={540}>
          <ControlledTextField
            name="analysis_notes"
            control={methods.control}
            label={t('soundRecords:sound-record.fieldTitles.analysisNotes')}
            multiline={true}
            rows={3}
            inputProps={{
              style: {
                padding: 0,
              },
            }}
            errorMessagePrefix=""
          />
        </Box>
        {methods.formState.isDirty && (
          <Stack direction="row">
            <Button variant="textThin" color="secondary" onClick={handleReset}>
              {t('forms:buttons.cancel')}
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              color="secondary"
              loading={mutateInProcess}
              disabled={mutateInProcess}
              disableElevation={true}
            >
              <span>{t('forms:buttons.save')}</span>
            </LoadingButton>
          </Stack>
        )}
      </Stack>
    </form>
  )
}
