import { QUERY_KEYS } from '@/constants'
import { useApiClient } from '@/hooks'
import { ApiErrorData, APIErrorResponse, ResearchArea } from '@/types'
import { unwrapErrorResponse, unwrapResponse } from '@/utils'
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'

export const getResearchAreasKey = (id: number): (string | null)[] =>
  QUERY_KEYS.RESEARCH_AREAS.map((key) => (key === ':id' ? id.toString() : key))

export const usePostProjectResearchAreas = (
  projectId: number
): UseMutationResult<
  ResearchArea,
  APIErrorResponse<ApiErrorData>,
  Omit<ResearchArea, 'id' | 'research_area_protocols'>
> => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  return useMutation(
    (params) =>
      client
        .post<ResearchArea>(`/projects/${projectId}/research_areas/`, params)
        .then(unwrapResponse)
        .catch(unwrapErrorResponse),
    {
      onSuccess: (data) => {
        const queryKey = getResearchAreasKey(projectId)

        const queryData = queryClient.getQueryData(queryKey) as ResearchArea[]

        if (queryData) {
          // Note: This is a workaround, because when creating new research area
          // in react query empty research area was added
          // and after it is saved on server it should be replaced
          const oldQueryData = queryData[queryData.length - 1].id
            ? queryData
            : queryData.slice(0, -1)

          queryClient.setQueryData(queryKey, [...oldQueryData, data])
        }
      },
    }
  )
}
