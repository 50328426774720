import * as yup from 'yup'
import { mapRules } from '@/utils'
import translation from 'i18next'

export const quotationResearchRoundsSchema = yup.lazy((map) =>
  yup.object(
    mapRules(
      map,
      yup.object({
        id: yup.number().required(),
        number_of_researchers: yup
          .number()
          .typeError(translation.t('errors:validation.numberType'))
          .min(1, translation.t('errors:validation.min', { min: 1 }))
          .required(),
        number_of_rounds: yup
          .number()
          .typeError(translation.t('errors:validation.numberType'))
          .min(1, translation.t('errors:validation.min', { min: 1 }))
          .required(),
        round_duration_minutes: yup
          .number()
          .typeError(translation.t('errors:validation.numberType'))
          .min(1, translation.t('errors:validation.min', { min: 1 }))
          .required(),
        price_per_hour: yup
          .number()
          .typeError(translation.t('errors:validation.numberType'))
          .min(1, translation.t('errors:validation.min', { min: 1 }))
          .required(),
      })
    )
  )
)
