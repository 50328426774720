import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { useApiClient } from '@/hooks'
import { unwrapErrorResponse, unwrapResponse } from '@/utils/unwrapResponse'
import {
  APIErrorResponse,
  QuotationResearch,
  PostQuotationResearchGenerate,
} from '@/types'

export const usePostQuotationResearchGenerate = (
  quotationId: number
): UseMutationResult<
  QuotationResearch,
  APIErrorResponse,
  PostQuotationResearchGenerate
> => {
  const client = useApiClient()

  return useMutation((params) =>
    client
      .post(
        `/quotations/${quotationId}/researches/${params.id}/generate/`,
        params
      )
      .then(unwrapResponse)
      .catch(unwrapErrorResponse)
  )
}
