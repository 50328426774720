import { AxiosResponse } from 'axios'
import { useApiClient, getMediaBaseEndpoint, PatchMedia } from '@/hooks'
import { unwrapErrorResponse } from '@/utils'
import { ModelsWithMedia, Photo, SoundRecord } from '@/types'

type UsePostEntityMediaParams = {
  modelKey: ModelsWithMedia
}

type UsePostEntityMediaData = {
  entityId: number
  media: PatchMedia
}

export const usePostEntityMedia = ({
  modelKey,
}: UsePostEntityMediaParams): ((
  data: UsePostEntityMediaData
) => Promise<PromiseSettledResult<AxiosResponse<Photo | SoundRecord>>[]>) => {
  const client = useApiClient()

  return async (data) => {
    const requests = data.media.chosenToUpload.map((mediaItem) => {
      const formData = new FormData()

      formData.append('file', mediaItem.file)

      return client.post<Photo | SoundRecord>(
        `${getMediaBaseEndpoint(modelKey)}/${data.entityId}/${
          data.media.mediaType
        }/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
    })

    return Promise.allSettled(requests)
      .then((allResponses) => allResponses)
      .catch(unwrapErrorResponse)
  }
}
