import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { useApiClient } from '@/hooks'
import { unwrapResponse, unwrapErrorResponse } from '@/utils/unwrapResponse'
import {
  APIErrorResponse,
  QuotationResearchRound,
  PatchQuotationResearchRound,
} from '@/types'

export const usePatchQuotationResearchRound = (
  quotationId: number,
  researchId: number
): UseMutationResult<
  QuotationResearchRound,
  APIErrorResponse,
  PatchQuotationResearchRound
> => {
  const client = useApiClient()

  return useMutation((params) =>
    client
      .patch(
        `/quotations/${quotationId}/researches/${researchId}/rounds/${params.id}/`,
        params
      )
      .then(unwrapResponse)
      .catch(unwrapErrorResponse)
  )
}
