import { useTranslation } from 'react-i18next'
import {
  SearchAutocomplete,
  SearchAutocompleteOption,
} from '@/components/inputs'
import { QuotationStatuses } from '@/types'

type QuotationStatusSelectProps = {
  value?: string
  onChange: (value: string | undefined) => void
}

export const QuotationStatusSelect = ({
  value,
  onChange,
}: QuotationStatusSelectProps): JSX.Element => {
  const { t } = useTranslation('forms')

  const options = Object.keys(QuotationStatuses).map((key) => {
    const status = QuotationStatuses[
      key as keyof typeof QuotationStatuses
    ] as string

    return {
      label: t(`quotationStatus.${status}`),
      value: status,
    } as SearchAutocompleteOption
  })

  return (
    <SearchAutocomplete
      value={value}
      onChange={onChange}
      options={options}
      placeholder={t(`placeholders.quotationStatus`)}
      sort={false}
      fieldSx={{ width: 400 }}
    />
  )
}
