import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { ProjectQuotationResearches } from './ProjectQuotationResearches'
import { ProjectQuotation } from '@/types'

type ProjectSpeciesStepProps = {
  quotations: ProjectQuotation[]
  showRounds: boolean
}

export const ProjectQuotations = ({
  quotations,
  showRounds,
}: ProjectSpeciesStepProps): JSX.Element => {
  const { t } = useTranslation('projects')

  const [showQuotations, setShowQuotations] = useState(false)

  return (
    <Accordion onChange={(_event, expanded) => setShowQuotations(expanded)}>
      <AccordionSummary>{t('titles.quotationSpecies')}</AccordionSummary>
      <AccordionDetails>
        {showQuotations &&
          quotations.map((quotation) => (
            <ProjectQuotationResearches
              key={quotation.id}
              quotation={quotation}
              showRounds={showRounds}
            />
          ))}
      </AccordionDetails>
      <hr style={{ marginTop: 20, marginBottom: 20 }} />
    </Accordion>
  )
}
