import { useAuth } from '@/hooks/useAuth'
import { ReactNode } from 'react'
import { UserGroups } from '@/types'
import { Navigate } from 'react-router-dom'

interface AuthorizationGuardProps {
  children: ReactNode
  allowedGroups: UserGroups[]
  fallback?: ReactNode
  redirect?: boolean
}

/**
 * Wrapper for routes that only available for authorized users (with particular permissions)
 */
export const AuthorizationGuard = ({
  children,
  allowedGroups,
  redirect = true,
  fallback,
}: AuthorizationGuardProps) => {
  const { user, hasPermission } = useAuth()

  if (!user) {
    return null
  }

  const userHasGroup = hasPermission(allowedGroups)

  if (userHasGroup) {
    return <>{children}</>
  }

  if (redirect) return <Navigate to="/404" />
  else return <>{fallback && fallback}</>
}
