import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Grid, Stack, Typography } from '@mui/material'
import { Edit, XSquare } from 'react-feather'
import { AuthorizationGuard, CardAttribute } from '@/components'
import { SoundRecordFormContainer } from '@/components/forms'
import { getFileNameFromUrl } from '@/utils'
import dayjs from 'dayjs'
import {
  ObservationTypeCode,
  SoundRecord as SoundRecordType,
  SoundRecord,
  UserGroups,
} from '@/types'
import { DATE_FORMAT_DMY_TIME } from '@/constants'

export type EntitySoundRecordDetailsProps = {
  researchObservationLogType: ObservationTypeCode
  soundRecord: SoundRecord
  updateSoundRecord?: (id: number, updatedFields: Partial<SoundRecord>) => void
}

export const ObservationSoundRecordDetails = ({
  researchObservationLogType,
  soundRecord,
  updateSoundRecord,
}: EntitySoundRecordDetailsProps): JSX.Element => {
  const { t } = useTranslation('soundRecords')

  const [isSoundRecordEdit, setIsSoundRecordEdit] = useState(false)

  return (
    <Grid container={true} p={4}>
      <Grid xs={12} item={true} container={true} spacing={4}>
        <Grid xs={3} item={true}>
          <CardAttribute
            title={t('sound-record.fieldTitles.createdAt')}
            value={
              soundRecord.created_at
                ? dayjs(soundRecord.created_at).format(DATE_FORMAT_DMY_TIME)
                : '-'
            }
          />
        </Grid>
        <Grid xs={9} item={true}>
          <CardAttribute
            title={t('sound-record.fieldTitles.createdBy')}
            value={`${soundRecord.created_by?.first_name ?? '-'} ${
              soundRecord.created_by?.last_name ?? ''
            }`}
          />
        </Grid>

        <Grid xs={3} item={true}>
          <CardAttribute
            title={t('sound-record.fieldTitles.analysed')}
            value={
              soundRecord.analysed
                ? t('sound-record.yes')
                : t('sound-record.no')
            }
          />
        </Grid>
        <Grid xs={3} item={true}>
          <CardAttribute
            title={t('sound-record.fieldTitles.analysedAt')}
            value={
              soundRecord.analysed_at
                ? dayjs(soundRecord.analysed_at).format(DATE_FORMAT_DMY_TIME)
                : '-'
            }
          />
        </Grid>
        <Grid xs={6} item={true}>
          <CardAttribute
            title={t('sound-record.fieldTitles.analysedBy')}
            value={`${soundRecord.analysed_by?.first_name ?? '-'} ${
              soundRecord.analysed_by?.last_name ?? ''
            }`}
          />
        </Grid>
      </Grid>

      <Grid xs={12} item={true}>
        <Stack
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
          spacing={4}
          my={4}
        >
          <Typography>
            {t('sound-record.fieldTitles.resultOfAnalysis')}
          </Typography>
          <AuthorizationGuard
            allowedGroups={[UserGroups.SOUND_TECHNICIAN]}
            redirect={false}
          >
            <Button
              onClick={() => setIsSoundRecordEdit(!isSoundRecordEdit)}
              variant="textThin"
              endIcon={
                isSoundRecordEdit ? <XSquare size={14} /> : <Edit size={14} />
              }
            >
              {isSoundRecordEdit
                ? t('buttons.closeEditing')
                : t('buttons.editSoundRecord')}
            </Button>
          </AuthorizationGuard>
        </Stack>
      </Grid>

      <Grid xs={12} item={true} container={true} spacing={4}>
        {isSoundRecordEdit ? (
          <Grid xs={12} item={true}>
            <SoundRecordFormContainer
              researchObservationLogType={researchObservationLogType}
              data={soundRecord}
              setUpdatedSoundRecord={
                updateSoundRecord
                  ? (data: SoundRecordType) =>
                      updateSoundRecord(soundRecord.id, data)
                  : undefined
              }
              onSuccessUpdate={() => setIsSoundRecordEdit(false)}
            />
          </Grid>
        ) : (
          <>
            <Grid xs={12} item={true}>
              <CardAttribute
                title={t('sound-record.fieldTitles.spectrogramLink')}
                value={
                  !!soundRecord.spectrogram_link ? (
                    <a
                      href={soundRecord.spectrogram_link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {getFileNameFromUrl(soundRecord.spectrogram_link)}
                    </a>
                  ) : (
                    '-'
                  )
                }
              />
            </Grid>
            <Grid xs={12} item={true}>
              <CardAttribute
                title={t('sound-record.fieldTitles.specieIdentified')}
                value={
                  soundRecord.specie_identified
                    ? soundRecord.specie_identified
                    : '-'
                }
              />
            </Grid>
            <Grid xs={12} item={true}>
              <CardAttribute
                title={t('sound-record.fieldTitles.analysisNotes')}
                value={soundRecord.analysis_notes ?? '-'}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  )
}
