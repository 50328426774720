import * as yup from 'yup'
import { mapRules } from '@/utils'
import translation from 'i18next'

export const quotationAdditionalSchema = yup.lazy((map) =>
  yup.object(
    mapRules(
      map,
      yup.object({
        description: yup.string().required(),
        amount: yup
          .number()
          .typeError(translation.t('errors:validation.numberType'))
          .min(1, translation.t('errors:validation.min', { min: 1 }))
          .required(),
        price_per_unit: yup
          .number()
          .typeError(translation.t('errors:validation.numberType'))
          .min(0.1, translation.t('errors:validation.min', { min: 0.1 }))
          .required(),
      })
    )
  )
)
