import { Box, TextField, InputLabel, TextFieldProps } from '@mui/material'
import { Control, Controller, Path } from 'react-hook-form'
import { FieldValues } from 'react-hook-form/dist/types/fields'
import { SxProps } from '@mui/system'
import { ApiErrorData } from '@/types'
import { getHelpText, hasFieldError } from '@/utils'
import { Theme } from '@mui/material/styles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useTranslation } from 'react-i18next'
import { Dayjs } from 'dayjs'
import { DATE_FORMAT_DMY } from '@/constants'

export type ControlledDateFieldProps<T extends FieldValues> = {
  name: Path<T>
  control: Control<T>
  label?: string
  labelSx?: SxProps<Theme>
  placeholder?: string
  fullWidth?: boolean
  errorMessagePrefix?: string
  apiErrorData?: ApiErrorData
  apiFieldName?: string
  isOptional?: boolean
  isFieldStateErrorShown?: boolean
  minDate?: Dayjs
  readOnly?: boolean
  disablePast?: boolean
} & TextFieldProps

export const ControlledDateField = <T extends FieldValues>({
  label,
  labelSx,
  name,
  control,
  placeholder,
  fullWidth = true,
  errorMessagePrefix = 'errors:validation',
  apiErrorData,
  apiFieldName,
  isOptional = false,
  isFieldStateErrorShown = true,
  minDate,
  readOnly,
  disablePast = false,
}: ControlledDateFieldProps<T>): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Box>
      {label && (
        <InputLabel sx={{ mb: 2, ...labelSx }}>
          {label} {isOptional && t('forms:labels.optional')}
        </InputLabel>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={placeholder}
              value={value}
              readOnly={readOnly}
              onChange={onChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id={name}
                  fullWidth={fullWidth}
                  error={
                    !!error || hasFieldError(apiFieldName ?? name, apiErrorData)
                  }
                  helperText={getHelpText({
                    isFieldStateErrorShown,
                    error,
                    errorMessagePrefix,
                    apiFieldName,
                    name,
                    apiErrorData,
                  })}
                  inputProps={{ ...params.inputProps, 'data-testid': name }}
                />
              )}
              inputFormat={DATE_FORMAT_DMY}
              minDate={minDate}
              OpenPickerButtonProps={{
                sx: { color: 'green.500' },
              }}
              PaperProps={{
                sx: {
                  boxShadow: 2,
                },
              }}
              disablePast={disablePast}
            />
          </LocalizationProvider>
        )}
      />
    </Box>
  )
}
