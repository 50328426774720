import { Stack, Box, Typography, Tooltip } from '@mui/material'
import { Info } from 'react-feather'

type InvalidDataInfoProps = {
  title: string
  invalidData: string[]
}

export const InvalidDataInfo = ({
  title,
  invalidData,
}: InvalidDataInfoProps): JSX.Element => (
  <Tooltip
    placement="left"
    arrow={true}
    title={
      <Box m={2}>
        <Typography variant="body2" fontWeight="bold">
          {title}
        </Typography>
        {invalidData.map((item) => (
          <Typography variant="body2" key={item}>
            &bull;&nbsp;{item}
          </Typography>
        ))}
      </Box>
    }
    onClick={(event) => event.stopPropagation()}
  >
    <Stack
      alignItems="center"
      mr={1}
      sx={{
        color: 'error.main',
        cursor: 'pointer',
      }}
    >
      <Info size="14px" />
    </Stack>
  </Tooltip>
)
