import { APIErrorResponse, EventResearcher } from '@/types'
import { UseQueryResult } from '@tanstack/react-query'
import { useQueryList } from './useQueryList'
import { Dayjs } from 'dayjs'
import { useSnackbar } from 'notistack'

type UseAvailableResearchersParams = {
  date?: string | Dayjs | null
  endDate?: string | Dayjs | null
  protocolIds?: number[] | string[]
  researchAreaId?: number
  eventId?: number
  latitude?: string
  longitude?: string
}

export const useAvailableResearchers = ({
  protocolIds,
  researchAreaId,
  eventId,
  latitude,
  longitude,
}: UseAvailableResearchersParams): UseQueryResult<
  EventResearcher[],
  APIErrorResponse
> => {
  const { enqueueSnackbar } = useSnackbar()

  return useQueryList({
    url: '/plannings/researchers/',
    options: {
      // enabled:
      //   !!date &&
      //   dayjs(date).isValid() &&
      //   !!endDate &&
      //   dayjs(endDate).isValid(),
      staleTime: 0,
      onError: (error) =>
        enqueueSnackbar(error.data.detail, {
          variant: 'error',
        }),
    },
    params: {
      // start_datetime: date
      //   ? dayjs(date).utc().format(DATE_API_UTC_FORMAT)
      //   : undefined,
      // end_datetime: endDate
      //   ? dayjs(endDate).utc().format(DATE_API_UTC_FORMAT)
      //   : undefined,
      research_area_id: researchAreaId,
      protocol_ids: protocolIds,
      event_id: eventId,
      lat: latitude,
      long: longitude,
    },
  })
}
