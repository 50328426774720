import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { useApiClient } from '@/hooks'
import { unwrapErrorResponse, unwrapResponse } from '@/utils/unwrapResponse'
import { APIErrorResponse, Quotation } from '@/types'
import { QUERY_KEYS } from '@/constants'

export const getUseQuotationKey = (id: number): string[] =>
  QUERY_KEYS.QUOTATION.map((key) => (key === ':id' ? id.toString() : key))

export type UseQuotationProps = {
  id: number
  isEnabled?: boolean
}

export const useQuotation = ({
  id,
  isEnabled = true,
}: UseQuotationProps): UseQueryResult<Quotation, APIErrorResponse> => {
  const client = useApiClient()

  const queryKey = getUseQuotationKey(id)

  return useQuery(
    queryKey,
    () =>
      client
        .get(`/quotations/${id}/`)
        .then(unwrapResponse)
        .catch(unwrapErrorResponse),
    { enabled: isEnabled }
  )
}
