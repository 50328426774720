import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Divider,
  Icon,
  Stack,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import {
  AreaAddress,
  AreaProtocolFormResearchers,
  ResearchAreaForm,
  ResearchAreaFormData,
  ResearchAreaFormProtocol,
  sortAreaProtocolResearchers,
} from '@/components/forms'
import {
  getProtocolOptionLabel,
  getResearcherOptionLabel,
} from '@/components/inputs'
import {
  useMutationWrapper,
  usePatchProjectResearchArea,
  usePostProjectResearchAreas,
  useProjectProtocols,
  useProjectResearchAreas,
  usePostProjectEventsGenerateForArea,
} from '@/hooks'
import { useAddNewResearchArea } from './useAddNewResearchArea'
import { useDeleteResearchArea } from './useDeleteResearchArea'
import {
  ResearchArea,
  ResearchAreaGraphic,
  ResearchAreaProtocol,
  RoundType,
} from '@/types'
import { ImportResearchAreas } from '@/components/forms/ResearchAreaForm/ImportResearchAreas'
import { getGeoName } from '@/libs/googleMaps'
import { useSnackbar } from 'notistack'
import { getFirstGeometryPoint } from '@/utils/researchAreaGraphics'

type ResearchAreaFormContainerProps = {
  projectId: number
  setIsNextStepDisabled: Dispatch<SetStateAction<boolean>>
  isPlanningCreated?: boolean
}

type ResearchAreaFormsData = Record<number, ResearchAreaFormData>

export const ResearchAreaFormContainer = ({
  projectId,
  setIsNextStepDisabled,
  isPlanningCreated = false,
}: ResearchAreaFormContainerProps): JSX.Element => {
  const { t } = useTranslation('projects')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const [expandedIds, setExpandedIds] = useState<(number | undefined)[]>([
    undefined,
  ])

  const [isAddNewResearchAreaDisabled, setIsAddNewResearchAreaDisabled] =
    useState(false)

  const [loadingFormsList, setLoadingFormsList] = useState<(number | null)[]>(
    []
  )

  const showFormLoading = (id: number | null) =>
    setLoadingFormsList((list) => [...list, id])

  const hideFormLoading = (id: number | null) =>
    setLoadingFormsList((list) => list.filter((areaId) => areaId !== id))

  const { data: researchAreasData, isLoading } =
    useProjectResearchAreas(projectId)

  const { mutateAsync: createResearchArea } = useMutationWrapper(() =>
    usePostProjectResearchAreas(projectId)
  )

  const { mutateAsync: updateResearchArea } = useMutationWrapper(() =>
    usePatchProjectResearchArea(projectId)
  )

  const { mutateAsync: generateResearchAreaEvent } = useMutationWrapper(() =>
    usePostProjectEventsGenerateForArea(projectId)
  )

  const handleAddNewResearchArea = useAddNewResearchArea(
    projectId,
    setIsAddNewResearchAreaDisabled,
    setIsNextStepDisabled
  )

  const deleteResearchArea = useDeleteResearchArea({
    projectId,
    setIsAddNewResearchAreaDisabled,
    setIsNextStepDisabled,
    setExpandedIds,
  })

  const { data: projectProtocols } = useProjectProtocols({ projectId })

  useEffect(() => {
    if (researchAreasData) {
      if (researchAreasData.length === 0) {
        handleAddNewResearchArea()

        return
      }

      const areNoUnsavedData = researchAreasData.every(
        (researchArea) => researchArea.id
      )

      if (areNoUnsavedData) {
        setIsNextStepDisabled(false)
      }
    }
  }, [researchAreasData])

  const handleSubmitArea = async (
    data: ResearchAreaFormData
  ): Promise<void> => {
    const researchAreaData: Omit<
      ResearchArea,
      'id' | 'research_area_protocols'
    > & {
      research_area_protocols: Array<Omit<ResearchAreaProtocol, 'protocol'>>
    } = {
      location_name: data.areaAddress?.locationName ?? null,
      location_lat: data.areaAddress?.locationLat ?? undefined,
      location_long: data.areaAddress?.locationLong ?? undefined,
      client_area_number: data.clientAreaNumber,
      research_area_protocols: Object.keys(data.researchAreaProtocols).map(
        (key) => ({
          protocol_id: data.researchAreaProtocols[key].protocol.value.id,
          area_protocol_researchers: Object.keys(
            data.researchAreaProtocols[key].areaProtocolResearchers
          ).map((arp) => ({
            round_type: data.researchAreaProtocols[key].areaProtocolResearchers[
              arp
            ].round_type as RoundType,
            number_of_researchers:
              data.researchAreaProtocols[key].areaProtocolResearchers[arp]
                .number_of_researchers,
            researcher_ids: data.researchAreaProtocols[
              key
            ].areaProtocolResearchers[arp].researchers.map(
              ({ value }) => value
            ),
          })),
        })
      ),
      graphics: data.graphics,
      notes: data.notes,
    }

    if (data.id) {
      const { id } = data

      showFormLoading(id)

      await updateResearchArea(
        {
          ...researchAreaData,
          id,
        },
        {
          successMessageKey: 'forms:projectArea.notification.areaUpdated',
          onSuccess: () => {
            hideFormLoading(id)
          },
          onError: () => {
            hideFormLoading(id)
          },
        }
      )
    } else {
      showFormLoading(null)

      const createdArea = await createResearchArea(researchAreaData, {
        successMessageKey: 'forms:projectArea.notification.areaCreated',
        errorMessageKey: 'forms:projectArea.notification.areaIsNotCreated',
        onSuccess: (data) => {
          hideFormLoading(null)

          setExpandedIds((prev) => {
            const index = prev.findIndex((item) => item === undefined)

            const newExpandedIds = [...prev]

            newExpandedIds[index] = data.id

            return newExpandedIds
          })
        },
        onError: () => {
          hideFormLoading(null)
        },
      })

      if (isPlanningCreated && createdArea.id) {
        await generateResearchAreaEvent(
          { id: createdArea.id },
          {
            successMessageKey:
              'forms:projectArea.notification.eventForAreaCreated',
            errorMessageKey:
              'forms:projectArea.notification.eventForAreaIsNotCreated',
          }
        )
      }
    }

    setIsAddNewResearchAreaDisabled(false)
    setIsNextStepDisabled(false)
  }

  const handleAccordion = (id: number) =>
    setExpandedIds((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id)
      }

      return [...prev, id]
    })

  const handleImportAreas = async (graphics: ResearchAreaGraphic[]) => {
    enqueueSnackbar('Importing...', {
      variant: 'default',
      persist: true,
    })

    const createAreas = []

    for (const graphic of graphics) {
      const firstGeometryPoint = getFirstGeometryPoint(graphic)

      let locationName = null
      let lat = undefined
      let long = undefined

      if (firstGeometryPoint) {
        lat = firstGeometryPoint[1].toString()
        long = firstGeometryPoint[0].toString()

        try {
          const geoResult = await getGeoName({
            lat: +lat,
            lng: +long,
          })

          locationName =
            geoResult.street_address?.formatted_address ??
            geoResult.addressVariants[0].formatted_address
        } catch (e) {
          console.warn(e)
        }
      }

      const researchAreaData: Omit<
        ResearchArea,
        'id' | 'research_area_protocols'
      > & {
        research_area_protocols: Array<Omit<ResearchAreaProtocol, 'protocol'>>
      } = {
        location_name: locationName,
        location_lat: lat ?? undefined,
        location_long: long ?? undefined,
        research_area_protocols: [],
        graphics: [graphic],
      }

      createAreas.push(createResearchArea(researchAreaData))
    }

    try {
      const createdAreas = await Promise.all(createAreas)

      if (isPlanningCreated) {
        await Promise.all(
          createdAreas.map((researchArea) =>
            generateResearchAreaEvent({ id: researchArea.id })
          )
        )
      }
    } catch (error) {
      enqueueSnackbar(`${t('errors:api.execute')}: ${error}`, {
        variant: 'error',
        preventDuplicate: true,
      })
    } finally {
      closeSnackbar()
    }
  }

  const formsDefaultValues = useMemo<ResearchAreaFormsData>(() => {
    const values: ResearchAreaFormsData = {}

    researchAreasData?.forEach(
      ({
        id,
        location_name,
        location_lat,
        location_long,
        client_area_number,
        research_area_protocols,
        graphics,
        notes,
      }) => {
        const researchAreaProtocols: {
          [key: string]: ResearchAreaFormProtocol
        } = {}

        research_area_protocols.forEach((rap, key) => {
          const areaProtocolResearchers: {
            [key: string]: AreaProtocolFormResearchers
          } = {}

          rap.area_protocol_researchers.forEach((apr) => {
            areaProtocolResearchers[`${apr.round_type}`] = {
              round_type: apr.round_type,
              number_of_researchers: apr.number_of_researchers,
              researchers:
                apr.researchers?.map((researcher) =>
                  getResearcherOptionLabel(researcher)
                ) ?? [],
              required_number_of_researchers: rap.protocol.rounds
                .filter((r) => r.round_type === apr.round_type)
                .reduce(
                  (max, current) =>
                    current.required_number_of_researchers >
                    max.required_number_of_researchers
                      ? current
                      : max,
                  { required_number_of_researchers: 0 }
                ).required_number_of_researchers,
            }
          })

          researchAreaProtocols[`${key}`] = {
            protocol: getProtocolOptionLabel(rap.protocol),
            areaProtocolResearchers: sortAreaProtocolResearchers(
              areaProtocolResearchers
            ),
          }
        })

        values[id] = {
          id,
          areaAddress: location_name
            ? ({
                locationName: location_name,
                locationLat: location_lat ?? '',
                locationLong: location_long ?? '',
              } as AreaAddress)
            : null,
          researchAreaProtocols,
          clientAreaNumber: client_area_number ?? '',
          graphics: graphics ?? [],
          notes: notes ?? '',
        }
      }
    )

    return values
  }, [researchAreasData])

  return (
    <>
      <Stack>
        <ImportResearchAreas projectId={projectId} onSave={handleImportAreas} />
        {isLoading ? (
          <CircularProgress sx={{ alignSelf: 'center' }} color="success" />
        ) : (
          <>
            {researchAreasData &&
              !!researchAreasData.length &&
              researchAreasData
                .sort((a, b) => a.id - b.id)
                .map(({ id }) => (
                  <Accordion
                    key={`area${id}`}
                    expanded={expandedIds.includes(id)}
                    onChange={() => handleAccordion(id)}
                    sx={{ mb: 1 }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon sx={{ width: 21, height: 21 }} />
                      }
                      data-testid="research-area-accordion"
                    >
                      {id
                        ? t('projects:areas.researchAreaNumber', {
                            areaNumber: id,
                          })
                        : t('projects:areas.emptyResearchAreaName')}
                    </AccordionSummary>
                    <AccordionDetails>
                      <ResearchAreaForm
                        onSubmit={handleSubmitArea}
                        onDeleteArea={() => deleteResearchArea(id)}
                        projectId={projectId}
                        isLoading={
                          loadingFormsList.includes(id) ||
                          (!id && loadingFormsList.includes(null))
                        }
                        projectProtocols={projectProtocols}
                        defaultValues={formsDefaultValues[id]}
                        isNewData={!id}
                        setIsNextStepDisabled={setIsNextStepDisabled}
                        isPlanningCreated={isPlanningCreated}
                        researchAreasData={researchAreasData}
                      />
                      <Divider sx={{ my: 4 }} />
                    </AccordionDetails>
                  </Accordion>
                ))}
          </>
        )}

        <Button
          variant="textThin"
          sx={{ alignSelf: 'flex-start', ml: 2 }}
          startIcon={
            <Icon sx={{ width: 21, height: 21 }}>
              <AddCircleOutlineIcon sx={{ width: 21, height: 21 }} />
            </Icon>
          }
          onClick={handleAddNewResearchArea}
          disabled={isAddNewResearchAreaDisabled}
          data-testid="add-research-area-button"
        >
          {t('areas.addResearchArea')}
        </Button>
      </Stack>
    </>
  )
}
