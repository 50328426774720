import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { useApiClient } from '@/hooks'
import { unwrapResponse, unwrapErrorResponse } from '@/utils/unwrapResponse'
import {
  APIErrorResponse,
  QuotationOverheadLine,
  PatchQuotationOverheadLine,
} from '@/types'

export const usePatchQuotationOverheadLine = (
  quotationId: number
): UseMutationResult<
  QuotationOverheadLine,
  APIErrorResponse,
  PatchQuotationOverheadLine
> => {
  const client = useApiClient()

  return useMutation((params) =>
    client
      .patch(
        `/quotations/${quotationId}/research_overhead/${params.id}/`,
        params
      )
      .then(unwrapResponse)
      .catch(unwrapErrorResponse)
  )
}
