import { EventResearcher } from '@/types'
import dayjs from 'dayjs'
import { DATE_FORMAT_DMY } from '@/constants'
import { useTranslation } from 'react-i18next'

/**
 * Capitalizes first letter of a string
 * @return string
 */
export const capitalizeFirstLetter = (string: string): string =>
  string.charAt(0).toUpperCase() + string.slice(1)

export const isString = (str: unknown): boolean => typeof str === 'string'

/**
 * format name
 * @return string
 */
export const formatName = (
  name: string | undefined,
  format: 'initials' | 'shorten' = 'shorten'
): string | undefined => {
  if (!name) {
    return undefined
  }

  const splittedName = name
    .replace(/ +(?= )/g, '')
    .split(' ')
    .filter((value) => value.length > 0)
  const lastName = splittedName[splittedName.length - 1]
  const names = splittedName
    .slice(0, splittedName.length - 1)
    .map((name) => `${name[0]}.`)

  if (format === 'initials') {
    const nameInitials = names?.[0]?.[0] ?? ''

    return `${nameInitials}${lastName[0]}`
  }

  return `${names ? names.join(' ') : ''} ${lastName}`
}

export const getPath = (
  path: string,
  params: Record<string, string | number>
): string => {
  let result = path

  Object.keys(params).forEach((key) => {
    result = path.replace(`:${key}`, params[key].toString())
  })

  return result
}

export const formatEventResearcherName = (
  researcher: EventResearcher
): string => {
  const { first_name, last_name } = researcher

  return `${first_name} ${last_name}`
}

export const NamesList = (researchers: EventResearcher[]): string | undefined =>
  researchers
    .map((researcher) => researcher.first_name + ' ' + researcher.last_name)
    .join(', ')
    .toString()

export const formatDateInformation = (
  start_date: string | null,
  end_date: string | null
): string | undefined => {
  const { t } = useTranslation()
  const startDate = start_date ? dayjs(start_date).format(DATE_FORMAT_DMY) : '-'
  const endDate = end_date ? dayjs(end_date).format(DATE_FORMAT_DMY) : '-'

  return startDate + ' ' + t('projects:titles.to') + ' ' + endDate
}

export const isStringValidUrl = (str: string): boolean => {
  try {
    new URL(str)
  } catch (_) {
    return false
  }

  return true
}

export const getFileNameFromUrl = (url: string): string =>
  new URL(url).pathname.split('/').pop() || ''
