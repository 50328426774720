import { useCallback } from 'react'
import { ModalLayout } from '@/components'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useModal } from '@/hooks'

export const useConfirmationModal = () => {
  const { open, close } = useModal()
  const { t } = useTranslation('common')

  const setConfirmationOpen = useCallback(
    (title: string, message: string, callbackFunc: () => void) => {
      open(
        <ModalLayout
          title={title}
          cardContentSx={{
            maxHeight: '70vh',
            overflowY: 'auto',
            minWidth: { xs: 'auto', md: '640px' },
          }}
          footerActions={
            <>
              <Button onClick={() => close()} variant="text">
                {t('buttons.cancel')}
              </Button>
              <Button
                onClick={() => {
                  close()
                  callbackFunc()
                }}
              >
                {t('buttons.confirmDelete')}
              </Button>
            </>
          }
        >
          {message}
        </ModalLayout>
      )
    },
    [open, close]
  )

  return { setConfirmationOpen }
}
