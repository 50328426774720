import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Stack,
  Typography,
  Button,
  Pagination,
  IconButton,
} from '@mui/material'
import { ProjectsTable } from '@/features'
import { AuthorizationGuard, EmptyListData, Loader } from '@/components'
import {
  ProjectLeadersMultiSelect,
  ResearchersMultiSelect,
  SearchTextField,
  ProjectStatusMultiSelect,
  ProjectExcludeStatus,
} from '@/components/inputs'
import { useFilters, useProjects, UseProjectsParams } from '@/hooks'
import { ProjectStatus, UserGroups } from '@/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { theme } from '@/theme'
import {
  DEFAULT_PROJECT_LIMIT,
  DEFAULT_PROJECT_OFFSET,
  ROUTES_PATH,
} from '@/constants'
import { X } from 'react-feather'

export const ProjectsOverviewContainer = (): JSX.Element => {
  const { t } = useTranslation('projects')
  const navigate = useNavigate()

  const defaultFiltersParams: UseProjectsParams = {
    search: undefined,
    researchers: undefined,
    project_leader: undefined,
    status: undefined,
    exclude_status: ProjectStatus.Archived,
    limit: DEFAULT_PROJECT_LIMIT,
    offset: DEFAULT_PROJECT_OFFSET,
  }

  const {
    filterParams,
    handleFieldValueChange,
    handleResetFilters,
    initPagesCount,
    pagesCount,
    page,
    onPageChange,
  } = useFilters(defaultFiltersParams)

  const { data, isLoading } = useProjects(filterParams)

  useEffect(() => {
    if (data && data.count) {
      initPagesCount(data?.count)
    }
  }, [data])

  return (
    <Stack data-testid="projects-overview-table">
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          mb={4}
          spacing={4}
        >
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'flex-start', md: 'center' }}
            justifyContent="flex-start"
            spacing={4}
            width="100%"
          >
            <SearchTextField
              searchValue={filterParams.search}
              onChange={(value) => handleFieldValueChange(value, 'search')}
              placeholder={t('searchPlaceholder')}
              width="380px"
            />
            <ProjectStatusMultiSelect
              value={filterParams.status as string}
              onChange={(value) => handleFieldValueChange(value, 'status')}
              placeholder={t('placeholders.status')}
            />
            <ProjectExcludeStatus
              value={filterParams.exclude_status as string}
              onChange={(value) =>
                handleFieldValueChange(value, 'exclude_status')
              }
              placeholder={t('placeholders.excludeStatus')}
            />
          </Stack>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'flex-start', md: 'center' }}
            justifyContent="flex-start"
            spacing={4}
            width="100%"
          >
            <ProjectLeadersMultiSelect
              value={filterParams.project_leader as string}
              onChange={(value) =>
                handleFieldValueChange(value, 'project_leader')
              }
              placeholder={t('placeholders.projectsLeaders')}
            />
            <ResearchersMultiSelect
              value={filterParams.researchers as string}
              onChange={(value) => handleFieldValueChange(value, 'researchers')}
              placeholder={t('placeholders.researchers')}
            />
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={4}>
          <Typography color={theme.palette.green[600]}>
            {t('amount', { amount: data?.count ?? 0 })}
          </Typography>
          <IconButton
            onClick={handleResetFilters}
            title={t('buttons.resetFilters')}
          >
            <X size="16px" color={theme.palette.green['500']} />
          </IconButton>
        </Stack>
        <AuthorizationGuard
          allowedGroups={[UserGroups.MANAGER]}
          redirect={false}
        >
          <Button onClick={() => navigate(ROUTES_PATH.PROJECT_CREATE)}>
            {t('action.create')}
            <FontAwesomeIcon style={{ paddingLeft: '10px' }} icon={faPlus} />
          </Button>
        </AuthorizationGuard>
      </Stack>

      {data && data.count ? (
        <>
          <ProjectsTable rows={data.results} />

          {pagesCount > 1 && (
            <Box alignSelf="center" mt={16}>
              <Pagination
                count={pagesCount}
                page={page}
                onChange={onPageChange}
              />
            </Box>
          )}
        </>
      ) : (
        <Box my={20}>
          {isLoading && <Loader />}

          {!isLoading && !data?.count && (
            <EmptyListData textKey="projects:noProjects" />
          )}
        </Box>
      )}
    </Stack>
  )
}
