import { TableHead, TableRow, TableCell } from '@mui/material'
import { useTranslation } from 'react-i18next'

const columnNames = [
  'id',
  'client',
  'reference',
  'status',
  'created',
  'updated',
  'created_by',
  'edited_by',
]

export const QuotationsTableHead = (): JSX.Element => {
  const { t } = useTranslation('quotations')

  return (
    <TableHead sx={{ borderRadius: 8 }}>
      <TableRow>
        {columnNames.map((columnName) => (
          <TableCell key={columnName}>
            {t(`columnNames.${columnName}`)}
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  )
}
