import { Box, Stack } from '@mui/material'
import { Step } from '../Step'
import { useStepperContext } from '@/hooks/useStepperContext'

export type StepData = {
  step: number
  description: string
  component: JSX.Element
}

type StepperContainerProps = {
  steps: StepData[]
  projectCreateStep?: boolean
}

export const StepperContainer = ({
  steps,
}: StepperContainerProps): JSX.Element => {
  const { activeStep } = useStepperContext()

  const StepComponent = steps[activeStep - 1]?.component || null

  return (
    <Stack direction={{ md: 'row' }} spacing={{ xs: 4, md: 9 }}>
      <Stack
        spacing={3}
        minWidth={{ xs: 'auto', md: '280px' }}
        data-testid="steps"
        data-active-step={activeStep}
      >
        {steps.map(({ step, description }) => (
          <Step
            key={step}
            step={step}
            description={description}
            isActive={step === activeStep}
            isPassed={step < activeStep}
          />
        ))}
      </Stack>

      <Box width="100%">{StepComponent}</Box>
    </Stack>
  )
}
