import { Helmet } from 'react-helmet-async'
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { PageBodyLayout, PageHeaderLayout } from '@/components/layouts'
import {
  HoursReportingContainer,
  ObservationLogsContainer,
  ProjectsOverviewContainer,
} from '@/features'
import { useAuth } from '@/hooks/useAuth'
import { isUserEmployee, isUserHR, isUserSoundTechnician } from '@/utils'
import { ResearcherPlanning } from '@/features/planning'

function Home() {
  const { t } = useTranslation('common')
  const { user } = useAuth()

  if (!user) return null

  const isEmployee = isUserEmployee(user.groups)
  const isHR = isUserHR(user.groups)
  const isSoundTechnician = isUserSoundTechnician(user.groups)

  return (
    <>
      <Helmet
        title={
          isEmployee
            ? t('title.planning')
            : isHR
            ? t('title.hoursReporting')
            : isSoundTechnician
            ? t('title.observationLogs')
            : t('title.projects')
        }
      />

      <Stack height="100%">
        <PageHeaderLayout
          subtitle={t('title.welcome')}
          title={`${user.first_name} ${user.last_name} `}
        />

        <Stack flex={1} sx={{ overflowY: 'auto' }}>
          <PageBodyLayout minWidth="900px">
            {isEmployee ? (
              <ResearcherPlanning />
            ) : isHR ? (
              <HoursReportingContainer />
            ) : isSoundTechnician ? (
              <ObservationLogsContainer />
            ) : (
              <ProjectsOverviewContainer />
            )}
          </PageBodyLayout>
        </Stack>
      </Stack>
    </>
  )
}

export default Home
