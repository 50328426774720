import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { useApiClient } from '@/hooks'
import { unwrapErrorResponse } from '@/utils'
import { APIErrorResponse, DeleteQuotationAdditionalLine } from '@/types'

export const useDeleteQuotationAdditionalLine = (
  quotationId: number
): UseMutationResult<
  unknown,
  APIErrorResponse,
  DeleteQuotationAdditionalLine
> => {
  const client = useApiClient()

  return useMutation((params) =>
    client
      .delete(`/quotations/${quotationId}/additional/${params.id}/`)
      .catch(unwrapErrorResponse)
  )
}
