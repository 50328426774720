import { useApiClient } from '@/hooks'
import { unwrapErrorResponse, unwrapResponse } from '@/utils/unwrapResponse'
import { PostQuotation, Quotation } from '@/types/api/entities'
import { APIErrorResponse } from '@/types'
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import { getUseQuotationKey } from '@/hooks'
import { QUERY_KEYS } from '@/constants'

export const usePostQuotation = (): UseMutationResult<
  Quotation,
  APIErrorResponse,
  PostQuotation
> => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  return useMutation(
    (params) =>
      client
        .post(`/quotations/`, params)
        .then(unwrapResponse)
        .catch(unwrapErrorResponse),
    {
      onSuccess: async (data) => {
        const queryKey = getUseQuotationKey(data.id)

        queryClient.setQueryData(queryKey, data)
        await queryClient.invalidateQueries(QUERY_KEYS.QUOTATIONS)
      },
    }
  )
}
