import { TableHead, TableRow, TableCell } from '@mui/material'
import { useTranslation } from 'react-i18next'

const columnNames = [
  'id',
  'projectName',
  'projectNumber',
  'createdBy',
  'createdAt',
  'analysed',
  'analysedBy',
  'analysedAt',
  'specieIdentified',
]

export const SoundRecordsTableHead = (): JSX.Element => {
  const { t } = useTranslation('soundRecords')

  return (
    <TableHead sx={{ borderRadius: 8 }}>
      <TableRow>
        {columnNames.map((columnName) => (
          <TableCell key={columnName}>
            {t(`columnNames.${columnName}`)}
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  )
}
