import { Button, Stack } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'

type FormActionButtonsProps = {
  cancelClick: () => void
  saveClick: () => void
  isLoading: boolean
  disabled: boolean
}

export const FormActionButtons = ({
  cancelClick,
  saveClick,
  isLoading,
  disabled,
}: FormActionButtonsProps) => {
  const { t } = useTranslation('quotations')

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: 'center',
        justifyContent: 'flex-end',
        spacing: 5,
        mb: 3,
        p: 4,
      }}
    >
      <Button variant="textThin" color="secondary" onClick={cancelClick}>
        {t('buttons.cancel')}
      </Button>
      <LoadingButton
        variant="contained"
        color="secondary"
        loading={isLoading}
        disableElevation={true}
        disabled={disabled}
        onClick={saveClick}
        sx={{ ml: 6 }}
      >
        <span>{t('buttons.save')}</span>
      </LoadingButton>
    </Stack>
  )
}
