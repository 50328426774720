import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Stack, Typography, Button } from '@mui/material'
import { useSnackbar } from 'notistack'
import { Edit, XSquare } from 'react-feather'
import parse from 'html-react-parser'
import { useQueryClient } from '@tanstack/react-query'
import { FormProvider, useForm } from 'react-hook-form'
import { FormActionButtons } from '@/features/quotationCreate/FormActionButtons'
import {
  QuotationInvoiceTextForm,
  QuotationInvoiceTextFormData,
} from './QuotationInvoiceTextForm'
import {
  usePatchQuotation,
  useMutationWrapper,
  getUseQuotationKey,
} from '@/hooks'
import { QuotationDetails } from '@/types'

type QuotationInvoiceTextFormContainerProps = {
  quotationDetails: QuotationDetails
}

export const QuotationInvoiceTextFormContainer = ({
  quotationDetails,
}: QuotationInvoiceTextFormContainerProps): JSX.Element => {
  const { t } = useTranslation('quotations')

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const queryClient = useQueryClient()

  const [edit, setEdit] = useState(false)

  const { mutateAsync: editQuotation, isLoading: isMutating } =
    useMutationWrapper(() => usePatchQuotation(quotationDetails.id))

  const defaultValues = {
    general_text: quotationDetails.general_text,
  }

  const methods = useForm<QuotationInvoiceTextFormData>({
    mode: 'onChange',
    defaultValues: {},
    values: defaultValues,
  })

  const handleSubmit = methods.handleSubmit(async (formData) => {
    enqueueSnackbar('Saving...', {
      variant: 'default',
    })

    const updatedText = {
      general_text: formData.general_text,
    }

    try {
      await editQuotation(updatedText)
      const quotationDetailsQueryKey = getUseQuotationKey(quotationDetails.id)

      await queryClient.invalidateQueries(quotationDetailsQueryKey)

      setEdit(false)

      closeSnackbar()
    } catch (e) {
      enqueueSnackbar(`${t('errors:api.execute')}: ${e}`, {
        variant: 'error',
      })
    }
  })

  const handleReset = () =>
    methods.reset({
      ...defaultValues,
    })

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mt={4}
        mb={2}
      >
        <Typography variant="h6">{t('titles.generalText')}</Typography>
        <Button
          variant="text"
          onClick={() => {
            setEdit((v) => !v)
            handleReset()
          }}
          startIcon={!edit ? <Edit size="16px" /> : <XSquare size={14} />}
        >
          {!edit
            ? t('quotations:buttons.editInvoiceText')
            : t('quotations:buttons.closeEditor')}
        </Button>
      </Stack>
      <FormProvider {...methods}>
        {edit ? (
          <>
            <QuotationInvoiceTextForm />
            {methods.formState.isDirty && (
              <FormActionButtons
                cancelClick={handleReset}
                saveClick={handleSubmit}
                isLoading={isMutating}
                disabled={!methods.formState.isDirty}
              />
            )}
          </>
        ) : (
          <>
            {!!quotationDetails.general_text &&
              parse(quotationDetails.general_text)}
          </>
        )}
      </FormProvider>
    </>
  )
}
