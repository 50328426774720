import translation from 'i18next'
import * as yup from 'yup'
import { validateRecordingNumber } from '@/components/forms/ObservationLogFormContainer/validation'
import {
  BATS_BEHAVIOUR_REQUIRE_PLACE_FOUND,
  OTHER_BAT_SPECIE_OPTION_VALUE,
  OTHER_GEDRAG_OPTION_VALUE,
  PLACE_FOUND_OPTION_VALUE,
} from '../fieldsOptions'
import {
  photosSchema,
  validatePhotos,
} from '@/components/inputs/ControlledMediaUpload/photosValidation'

export const schema = yup.object({
  specie: yup.string().required(),
  other_specie: yup.string().when('specie', {
    is: OTHER_BAT_SPECIE_OPTION_VALUE,
    then: (schema) => schema.required(),
  }),
  amount: yup.string().required(),
  counting_method: yup.string().required(),
  behaviour: yup.string().required(),
  other_behaviour: yup.string().when('behaviour', {
    is: OTHER_GEDRAG_OPTION_VALUE,
    then: (schema) => schema.required(),
  }),
  recording_number: yup
    .string()
    .transform((value) => (value === '' || value === null ? undefined : value))
    .test(validateRecordingNumber),
  is_place_found: yup
    .string()
    .required()
    .test((value, context) => {
      const behavior = context.parent.behaviour

      if (
        BATS_BEHAVIOUR_REQUIRE_PLACE_FOUND.includes(behavior) &&
        value !== PLACE_FOUND_OPTION_VALUE
      ) {
        return context.createError({
          message: translation.t(
            'errors:validation.isPlaceFoundIncorrectBehaviour',
            {
              placeFound: value,
              behavior,
            }
          ),
        })
      }

      return true
    }),
  place_type: yup.string().when('is_place_found', {
    is: PLACE_FOUND_OPTION_VALUE,
    then: (schema) => schema.required(),
  }),
  first_fledgling_time: yup.date().when('is_place_found', {
    is: PLACE_FOUND_OPTION_VALUE,
    then: (schema) => schema.required(),
  }),
  place_type_notes: yup
    .string()
    .max(256)
    .when('is_place_found', {
      is: PLACE_FOUND_OPTION_VALUE,
      then: (schema) => schema.required(),
    }),
  notes: yup.string().max(256),
  address: yup.string().when('is_place_found', {
    is: PLACE_FOUND_OPTION_VALUE,
    then: (schema) => schema.required(),
  }),
  observed_at: yup.date().required(),
  photos: photosSchema.when('is_place_found', {
    is: PLACE_FOUND_OPTION_VALUE,
    then: (schema) =>
      schema.test(
        'valid-photos',
        translation.t('errors:validation.requiredImagesWhenPlaceFound'),
        validatePhotos
      ),
    otherwise: (schema) => schema.optional(),
  }),
})
