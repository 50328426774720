import { Checkbox, FormControlLabel } from '@mui/material'
import { ProjectStatus } from '@/types'

type ProjectExcludeStatusProps = {
  value?: string
  onChange: (value: string) => void
  placeholder: string
}

export const ProjectExcludeStatus = ({
  value,
  onChange,
  placeholder,
}: ProjectExcludeStatusProps): JSX.Element => (
  <FormControlLabel
    control={
      <Checkbox
        checked={value === ProjectStatus.Archived}
        onChange={(_, checked) =>
          onChange(checked ? ProjectStatus.Archived : '')
        }
      />
    }
    label={placeholder}
  />
)
