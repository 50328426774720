import { Paper, Table, TableContainer } from '@mui/material'
import { SoundRecordsTableHead } from './SoundRecordsTableHead'
import { SoundRecordsTableBody } from './SoundRecordsTableBody'
import { SoundRecord } from '@/types'

export type SoundRecordsTableProps = {
  rows: SoundRecord[]
  showSoundRecordDetails: (id: number) => void
}

export const SoundRecordsTable = ({
  rows,
  showSoundRecordDetails,
}: SoundRecordsTableProps): JSX.Element => (
  <TableContainer component={Paper}>
    <Table stickyHeader={true}>
      <SoundRecordsTableHead />
      <SoundRecordsTableBody
        rows={rows}
        showSoundRecordDetails={showSoundRecordDetails}
      />
    </Table>
  </TableContainer>
)
