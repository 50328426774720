import * as yup from 'yup'
import { photosSchema } from '@/components/inputs/ControlledMediaUpload/photosValidation'
import {
  OTHER_GEDRAG_OPTION_VALUE,
  OTHER_SPECIE_OPTION_VALUE,
} from '../fieldsOptions'

export const schema = yup.object({
  specie: yup.string().required(),
  other_specie: yup.string().when('specie', {
    is: OTHER_SPECIE_OPTION_VALUE,
    then: (schema) => schema.required(),
  }),
  amount: yup.string().required(),
  counting_method: yup.string().required(),
  behaviour: yup.string().required(),
  other_behaviour: yup.string().when('behaviour', {
    is: OTHER_GEDRAG_OPTION_VALUE,
    then: (schema) => schema.required(),
  }),
  behaviour_notes: yup.string().max(256),
  observed_at: yup.date().required(),
  photos: photosSchema,
})
