import { useTranslation } from 'react-i18next'
import { Box, Stack, Typography, IconButton, Pagination } from '@mui/material'
import { X } from 'react-feather'
import { SoundRecord, SoundRecordsTable } from '@/features'
import { Loader } from '@/components'
import { EmptyListData } from '@/components/ui'
import { SearchTextField } from '@/components/inputs'
import {
  useModal,
  useFilters,
  useSoundRecords,
  UseObservationLogsSearchParams,
} from '@/hooks'
import { theme } from '@/theme'
import { DEFAULT_LIST_LIMIT, DEFAULT_LIST_OFFSET } from '@/constants'
import { useEffect } from 'react'
import { PaginatedListResponse, ObservationLog } from '@/types'

export const SoundRecordsContainer = (): JSX.Element => {
  const { t } = useTranslation('soundRecords')
  const { open } = useModal()

  const defaultFiltersParams: UseObservationLogsSearchParams = {
    id: undefined,
    limit: DEFAULT_LIST_LIMIT,
    offset: DEFAULT_LIST_OFFSET,
  }

  const openSoundRecord = (id: number) => {
    if (data && data.results) {
      const index = data.results.findIndex((row) => row.id === id)

      if (index !== -1) {
        open(<SoundRecord soundRecord={data.results[index]} />)
      }
    }
  }

  const {
    filterParams,
    handleFieldValueChange,
    handleResetFilters,
    initPagesCount,
    pagesCount,
    page,
    onPageChange,
    openDetail,
  } = useFilters<
    UseObservationLogsSearchParams,
    PaginatedListResponse<ObservationLog>,
    ObservationLog
  >(defaultFiltersParams)

  const { data, isLoading } = useSoundRecords(filterParams)

  useEffect(() => {
    if (data && data.count) {
      initPagesCount(data?.count)

      const detailItemId = openDetail()

      if (detailItemId) {
        openSoundRecord(detailItemId)
      }
    }
  }, [data])

  return (
    <Stack>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          mb={4}
          spacing={4}
        >
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'flex-start', md: 'center' }}
            justifyContent="flex-start"
            spacing={4}
            width="100%"
          >
            <SearchTextField
              searchValue={filterParams.id as string}
              onChange={(value) => handleFieldValueChange(value, 'id')}
              placeholder="ID"
              searchIcon={false}
              width="80px"
            />
            {/*<SearchTextField*/}
            {/*  searchValue={filterParams.search}*/}
            {/*  onChange={(value) => handleFieldValueChange(value, 'search')}*/}
            {/*  placeholder={t('searchPlaceholder')}*/}
            {/*  width="460px"*/}
            {/*/>*/}
            {/*<BooleanSelect*/}
            {/*  label={t('forms:placeholders.hasProcessingComment')}*/}
            {/*  value={filterParams.hasProcessingComment as boolean}*/}
            {/*  onChange={(value) =>*/}
            {/*    handleFieldValueChange(value, 'hasProcessingComment')*/}
            {/*  }*/}
            {/*/>*/}
          </Stack>
        </Stack>
        <Typography color="green.600">
          {t('amount', { amount: data?.count ?? 0 })}
        </Typography>
        <IconButton
          onClick={handleResetFilters}
          title={t('buttons.resetFilters')}
        >
          <X size="16px" color={theme.palette.green['500']} />
        </IconButton>
      </Stack>

      {data?.count ? (
        <>
          <SoundRecordsTable
            rows={data.results}
            showSoundRecordDetails={openSoundRecord}
          />

          {pagesCount > 1 && (
            <Box alignSelf="center" mt={16}>
              <Pagination
                count={pagesCount}
                page={page}
                onChange={onPageChange}
              />
            </Box>
          )}
        </>
      ) : (
        <Box my={20}>
          {isLoading && <Loader />}

          {!isLoading && !data?.count && (
            <EmptyListData textKey="sound-record:noData" />
          )}
        </Box>
      )}
    </Stack>
  )
}
