import { Paper, Table, TableContainer } from '@mui/material'
import { HoursReportingTableHead } from './HoursReportingTableHead'
import { HoursReportingTableBody } from './HoursReportingTableBody'
import { HoursReport } from '@/types'

export type HoursReportingTableProps = {
  rows: HoursReport[]
  showHoursReportDetails: (id: number) => void
}

export const HoursReportingTable = ({
  rows,
  showHoursReportDetails,
}: HoursReportingTableProps): JSX.Element => (
  <TableContainer component={Paper}>
    <Table stickyHeader={true}>
      <HoursReportingTableHead />
      <HoursReportingTableBody
        rows={rows}
        showHoursReportDetails={showHoursReportDetails}
      />
    </Table>
  </TableContainer>
)
