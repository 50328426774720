import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { Stack, Box, Typography, TextField } from '@mui/material'
import { ControlledTextField } from '@/components/inputs'
import { QuotationOverheadLine, QuotationOverheadLineType } from '@/types'

export type QuotationOverheadFormData = {
  [key: string]: {
    id: number
    type: QuotationOverheadLineType
    amount: string
    number_of_visits: number
    amount_per_round: string
    price_per_unit: string
    total_price: string
  }
}

export type QuotationOverheadFormDataProps = {
  line: QuotationOverheadLine
}

export const QuotationOverheadForm = ({
  line,
}: QuotationOverheadFormDataProps) => {
  const { t } = useTranslation()

  const { control, getValues } = useFormContext<QuotationOverheadFormData>()

  const amount = (
    Number(getValues(`${line.id}.amount_per_round`)) *
    getValues(`${line.id}.number_of_visits`)
  ).toFixed(2)

  const total_price = (
    Number(amount) * (Number(getValues(`${line.id}.price_per_unit`)) ?? 0)
  ).toFixed(2)

  return (
    <Stack
      direction="row"
      spacing={2}
      borderRadius={2.5}
      p={4}
      bgcolor="grey.50"
      width="100%"
    >
      <Typography mt={2.5} width={120}>
        {t(`quotations:overhead.${line.type}`)}
      </Typography>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        width="100%"
      >
        <Box width={120}>
          <ControlledTextField
            name={`${line.id}.amount_per_round`}
            control={control}
            type="number"
            placeholder={t('quotations:placeholders.hoursPerVisit')}
            inputProps={{ min: 0 }}
            errorMessagePrefix=""
          />
        </Box>
        <Stack direction="row" spacing={2}>
          <Box width={120}>
            <TextField
              value={amount}
              label={t('quotations:placeholders.numberOfHours')}
              disabled={true}
            />
          </Box>
          <Box width={120}>
            <ControlledTextField
              name={`${line.id}.price_per_unit`}
              control={control}
              type="number"
              placeholder={t('quotations:placeholders.rate')}
              inputProps={{ min: 1 }}
              errorMessagePrefix=""
            />
          </Box>
          <Box width={120}>
            <TextField
              value={total_price}
              label={t('quotations:placeholders.totalPrice')}
              disabled={true}
            />
          </Box>
        </Stack>
      </Stack>
    </Stack>
  )
}
