import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { useApiClient } from '@/hooks'
import { unwrapErrorResponse } from '@/utils'
import { APIErrorResponse, DeleteQuotationResearchRound } from '@/types'

export const useDeleteQuotationResearchRound = (
  quotationId: number,
  researchId: number
): UseMutationResult<
  unknown,
  APIErrorResponse,
  DeleteQuotationResearchRound
> => {
  const client = useApiClient()

  return useMutation((params) =>
    client
      .delete<DeleteQuotationResearchRound>(
        `/quotations/${quotationId}/researches/${researchId}/rounds/${params.id}/`
      )
      .catch(unwrapErrorResponse)
  )
}
