import { useTranslation } from 'react-i18next'
import { Box, Stack, Typography, Link } from '@mui/material'
import { ClientInfo } from '@/features/quotationCreate/ClientInfo'
import { QuotationDetails } from '@/types'

type QuotationInfoProps = { quotationDetails: QuotationDetails }

export const QuotationInfo = ({
  quotationDetails,
}: QuotationInfoProps): JSX.Element => {
  const { t } = useTranslation('quotations')

  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: 'space-between',
        alignItems: 'flex-start',
      }}
    >
      <Box width="50%">
        <Typography variant="h6" mb={2}>
          {t('titles.debtor')}
        </Typography>
        <ClientInfo client={quotationDetails.client} />
      </Box>
      <Box width="50%">
        <Typography variant="h6" mb={2}>
          {t('titles.quotation')}
        </Typography>
        <Stack spacing={4}>
          {quotationDetails.project_leader && (
            <Box>
              {t('placeholders.projectLeader')}:&nbsp;
              {`${quotationDetails.project_leader.first_name} ${quotationDetails.project_leader.last_name} [${quotationDetails.project_leader.email}]`}
            </Box>
          )}
          <Box>
            {t('quotationDetails.reference')}:&nbsp;
            {quotationDetails.reference}
          </Box>
          {quotationDetails.invoice_link && (
            <Box>
              {t('quotationDetails.invoice')}:&nbsp;
              <Link
                href={quotationDetails.invoice_link}
                target="_blank"
                rel="noopener noreferrer"
                color="primary.light"
              >
                {t('quotationDetails.invoiceLink')}
              </Link>
            </Box>
          )}
        </Stack>
      </Box>
    </Stack>
  )
}
