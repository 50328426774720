import { Dispatch, SetStateAction, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Stack, Box, Button, Typography, IconButton } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { PlusCircle, X } from 'react-feather'
import {
  LocationAutocomplete,
  AutocompleteOption,
  ControlledTextField,
  ResearchersControlledAutocomplete,
  getProtocolOptionLabel,
} from '@/components/inputs'
import { ProtocolsAutocomplete } from '@/components/inputs/ProtocolsAutocomplete'
import { DrawResearchAreaGraphics } from '@/components/forms/ResearchAreaForm/DrawResearchAreaGraphics'
import { yupResolver } from '@hookform/resolvers/yup'
import { projectAreaSchema } from './validationSchema'
import { getGeoName } from '@/libs/googleMaps'
import { capitalizeFirstLetter } from '@/utils'
import { getFirstGeometryPoint } from '@/utils/researchAreaGraphics'
import {
  FormComponentProps,
  ProjectProtocol,
  ResearchArea,
  ResearchAreaGraphic,
  ResearchAreaProtocolResearchers,
  ProjectProtocolRound,
} from '@/types'
import { theme } from '@/theme'

export type AreaAddress = {
  locationName: string
  locationLat?: string
  locationLong?: string
}

export type AreaProtocolFormResearchers = Pick<
  ResearchAreaProtocolResearchers,
  'round_type' | 'number_of_researchers'
> &
  Pick<ProjectProtocolRound, 'required_number_of_researchers'> & {
    researchers: AutocompleteOption[]
  }

export type ResearchAreaFormProtocol = {
  protocol: AutocompleteOption<ProjectProtocol>
  areaProtocolResearchers: { [key: string]: AreaProtocolFormResearchers }
}

export type ResearchAreaFormData = {
  id?: number
  areaAddress: AreaAddress | null
  clientAreaNumber?: string
  researchAreaProtocols: { [key: string]: ResearchAreaFormProtocol }
  graphics: ResearchAreaGraphic[]
  notes?: string
}

export type ResearchAreaFormProps = {
  projectId: number
  onDeleteArea: (id?: number) => void
  isNewData?: boolean
  setIsNextStepDisabled?: Dispatch<SetStateAction<boolean>>
  isPlanningCreated?: boolean
  researchAreasData: ResearchArea[]
  projectProtocols?: ProjectProtocol[]
} & FormComponentProps<ResearchAreaFormData>

export const sortAreaProtocolResearchers = (
  areaProtocolResearchers: ResearchAreaFormProtocol[`areaProtocolResearchers`]
) => {
  const order = ['morning', 'day', 'evening', 'night']

  return Object.fromEntries(
    Object.entries(areaProtocolResearchers).sort(
      ([keyA], [keyB]) => order.indexOf(keyA) - order.indexOf(keyB)
    )
  )
}

export const ResearchAreaForm = ({
  projectId,
  onSubmit,
  defaultValues,
  isLoading,
  apiErrorData,
  onDeleteArea,
  isNewData = true,
  setIsNextStepDisabled,
  isPlanningCreated = false,
  researchAreasData,
  projectProtocols,
}: ResearchAreaFormProps): JSX.Element => {
  const { t } = useTranslation('forms')

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { isDirty, errors, isValid },
    reset,
  } = useForm<ResearchAreaFormData>({
    mode: 'onChange',
    defaultValues: {
      graphics: [],
      areaAddress: null,
      researchAreaProtocols: {},
      clientAreaNumber: '',
      notes: '',
    },
    values: defaultValues,
    resolver: yupResolver(projectAreaSchema),
  })

  const researchAreaProtocols = watch('researchAreaProtocols')

  useEffect(() => {
    if (isDirty) {
      setIsNextStepDisabled?.(true)
    } else {
      setIsNextStepDisabled?.(false)
    }
  }, [isDirty])

  const updateLocation = async (lat: string, long: string) => {
    try {
      const { addressVariants, street_address } = await getGeoName({
        lat: +lat,
        lng: +long,
      })

      if (addressVariants) {
        setValue(
          'areaAddress',
          {
            locationName:
              street_address?.formatted_address ??
              addressVariants[0].formatted_address,
            locationLat: lat,
            locationLong: long,
          } as AreaAddress,
          { shouldDirty: true }
        )
      }
    } catch (error) {
      console.error('Error fetching location name:', error)
    }
  }

  const handleSaveGraphics = (graphics: ResearchAreaGraphic[]) => {
    setValue('graphics', graphics, {
      shouldDirty: true,
      shouldValidate: true,
    })

    if (!graphics.length) return

    const firstGeometryPoint = getFirstGeometryPoint(graphics[0])

    if (firstGeometryPoint) {
      const lat = firstGeometryPoint[1].toString()
      const long = firstGeometryPoint[0].toString()

      if (
        lat !== getValues('areaAddress.locationLat') ||
        long !== getValues('areaAddress.locationLong')
      ) {
        updateLocation(lat, long)
      }
    }
  }

  const handleReset = () =>
    reset({
      ...defaultValues,
    })

  const getAreaProtocolResearchers = (
    rounds: ProjectProtocolRound[]
  ): ResearchAreaFormProtocol[`areaProtocolResearchers`] => {
    const areaProtocolResearchers: {
      [key: string]: AreaProtocolFormResearchers
    } = {}

    rounds.forEach((pr) => {
      areaProtocolResearchers[`${pr.round_type}`] = {
        round_type: pr.round_type,
        number_of_researchers: 1,
        researchers: [],
        required_number_of_researchers:
          areaProtocolResearchers[`${pr.round_type}`] &&
          areaProtocolResearchers[`${pr.round_type}`]
            .required_number_of_researchers > pr.required_number_of_researchers
            ? areaProtocolResearchers[`${pr.round_type}`]
                .required_number_of_researchers
            : pr.required_number_of_researchers,
      }
    })

    return sortAreaProtocolResearchers(areaProtocolResearchers)
  }

  const handleAddProtocol = () => {
    const protocol = projectProtocols?.find(
      (pp) =>
        !Object.keys(researchAreaProtocols).some(
          (key) => researchAreaProtocols[key].protocol.value.id === pp.id
        )
    )

    if (protocol) {
      const keys = Object.keys(researchAreaProtocols)

      const lastKey = keys[keys.length - 1]

      setValue(`researchAreaProtocols.${Number(lastKey) + 1}`, {
        protocol: getProtocolOptionLabel(protocol),
        areaProtocolResearchers: getAreaProtocolResearchers(protocol.rounds),
      })
    }
  }

  const handleChangeProtocol = (
    key: string,
    protocolOption: AutocompleteOption<ProjectProtocol>
  ) => {
    setValue(`researchAreaProtocols.${key}`, {
      protocol: protocolOption,
      areaProtocolResearchers: getAreaProtocolResearchers(
        protocolOption.value.rounds
      ),
    })
  }

  const handleRemoveProtocol = (key: string) => {
    if (researchAreaProtocols) {
      const updatedProtocols = {
        ...researchAreaProtocols,
      }

      delete updatedProtocols[key]

      setValue('researchAreaProtocols', updatedProtocols, {
        shouldDirty: true,
        shouldValidate: true,
      })
    }
  }

  return (
    <form
      onSubmit={handleSubmit(async (data) =>
        onSubmit({ ...data, id: defaultValues?.id })
      )}
      data-testid="research-area-form"
    >
      <Stack spacing={7.5} sx={{ backgroundColor: 'transparent' }}>
        <>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Box>
              <DrawResearchAreaGraphics
                projectId={projectId}
                onSave={handleSaveGraphics}
                otherResearchAreas={researchAreasData.filter(
                  (ra) => ra.id !== defaultValues?.id
                )}
                graphics={watch('graphics')}
                areaAddress={watch('areaAddress')}
                areaId={defaultValues?.id}
              />
            </Box>
            {!isValid && errors.graphics?.message && (
              <Typography variant="body2" color="error.main" ml={1}>
                {errors.graphics.message}
              </Typography>
            )}
          </Stack>
          <LocationAutocomplete name="areaAddress" control={control} />

          <Stack
            spacing={6}
            sx={{ border: 'solid 1px #ccc', borderRadius: 1, p: 4 }}
          >
            {researchAreaProtocols &&
              Object.keys(researchAreaProtocols).map((key, i) => (
                <Stack key={key}>
                  {i > 0 && <hr style={{ marginBottom: 30, width: '100%' }} />}
                  <Stack direction="row" justifyContent="space-between">
                    <Stack width="100%">
                      <ProtocolsAutocomplete
                        name={`researchAreaProtocols.${key}.protocol`}
                        control={control}
                        protocols={projectProtocols?.filter(
                          (pp) =>
                            !Object.keys(researchAreaProtocols).some(
                              (rap) =>
                                researchAreaProtocols[rap].protocol.value.id ===
                                pp.id
                            ) ||
                            pp.id ===
                              researchAreaProtocols[key].protocol.value.id
                        )}
                        onChange={(
                          value: AutocompleteOption<ProjectProtocol>
                        ) => {
                          handleChangeProtocol(key, value)
                        }}
                        disabled={!isNewData && isPlanningCreated}
                      />
                      <Stack spacing={5} marginTop={4}>
                        {Object.keys(
                          researchAreaProtocols[key].areaProtocolResearchers
                        ).map((aprId) => (
                          <Box key={aprId}>
                            <Stack direction="row" spacing={4}>
                              <Box width={100} mt={2}>
                                {capitalizeFirstLetter(
                                  researchAreaProtocols[key]
                                    .areaProtocolResearchers[aprId].round_type
                                )}
                              </Box>
                              <Box width={200}>
                                <ControlledTextField
                                  name={`researchAreaProtocols.${key}.areaProtocolResearchers.${aprId}.number_of_researchers`}
                                  control={control}
                                  type="number"
                                  inputProps={{ min: 1 }}
                                  placeholder={t(
                                    'placeholders.roundNumberOfResearchers',
                                    {
                                      round: capitalizeFirstLetter(
                                        researchAreaProtocols[key]
                                          .areaProtocolResearchers[aprId]
                                          .round_type
                                      ),
                                      researchers: getValues(
                                        `researchAreaProtocols.${key}.areaProtocolResearchers.${aprId}.required_number_of_researchers`
                                      ),
                                    }
                                  )}
                                  apiErrorData={apiErrorData}
                                  errorMessagePrefix=""
                                />
                              </Box>
                              <Box width="100%">
                                <ResearchersControlledAutocomplete
                                  name={`researchAreaProtocols.${key}.areaProtocolResearchers.${aprId}.researchers`}
                                  control={control}
                                  latitude={watch('areaAddress.locationLat')}
                                  longitude={watch('areaAddress.locationLong')}
                                  protocolIds={[
                                    getValues(
                                      `researchAreaProtocols.${key}.protocol`
                                    ).value.id,
                                  ]}
                                />
                                {getValues(
                                  `researchAreaProtocols.${key}.areaProtocolResearchers.${aprId}.researchers`
                                ).length >
                                  getValues(
                                    `researchAreaProtocols.${key}.areaProtocolResearchers.${aprId}.required_number_of_researchers`
                                  ) && (
                                  <Typography
                                    variant="body2"
                                    color="error.main"
                                    ml={1}
                                    mt={1}
                                  >
                                    {t(
                                      'projectArea.notification.numberOfResearchers'
                                    )}
                                  </Typography>
                                )}
                              </Box>
                            </Stack>
                          </Box>
                        ))}
                      </Stack>
                    </Stack>
                    <Box mt={2} ml={2}>
                      <IconButton onClick={() => handleRemoveProtocol(key)}>
                        <X size="16px" color={theme.palette.green['500']} />
                      </IconButton>
                    </Box>
                  </Stack>
                </Stack>
              ))}
            {errors.researchAreaProtocols?.message && (
              <Typography variant="body2" color="error.main" ml={1}>
                {String(errors.researchAreaProtocols.message)}
              </Typography>
            )}
            {projectProtocols &&
              projectProtocols?.length >
                Object.keys(researchAreaProtocols).length && (
                <Box>
                  <Button
                    variant="text"
                    onClick={handleAddProtocol}
                    startIcon={<PlusCircle size="16px" />}
                  >
                    {t('projectArea.addProtocol')}
                  </Button>
                </Box>
              )}
          </Stack>

          <Box width={200}>
            <ControlledTextField
              name="clientAreaNumber"
              control={control}
              placeholder={t('placeholders.clientAreaNumber')}
            />
          </Box>
          <ControlledTextField
            name="notes"
            control={control}
            placeholder={t('placeholders.notes')}
            multiline={true}
            inputProps={{
              style: {
                padding: 2,
              },
            }}
          />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={5}
          >
            {isDirty && (
              <Stack direction="row" alignItems="center" spacing={5} mb={3}>
                <Button
                  variant="textThin"
                  color="secondary"
                  onClick={handleReset}
                  data-testid="cancel-changes-button"
                >
                  {t('projectArea.cancelChanges')}
                </Button>
                <LoadingButton
                  variant="contained"
                  color="secondary"
                  type="submit"
                  loading={isLoading}
                  disableElevation={true}
                  disabled={!isDirty}
                  data-testid="submit-button"
                >
                  <span>
                    {t(
                      defaultValues?.id
                        ? 'projectArea.updateArea'
                        : 'projectArea.saveArea'
                    )}
                  </span>
                </LoadingButton>
              </Stack>
            )}

            {!isNewData && !isPlanningCreated && (
              <Button
                variant="textThin"
                color="buttonGrey"
                onClick={() => onDeleteArea(defaultValues?.id)}
                data-testid="delete-area-button"
                sx={{ ml: 'auto' }}
              >
                {t('projectArea.deleteArea')}
              </Button>
            )}
          </Stack>
        </>
      </Stack>
    </form>
  )
}
