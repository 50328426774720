import {
  SoundRecord,
  PaginatedListResponse,
  APIErrorResponse,
  SearchRequestParams,
} from '@/types'
import { UseQueryResult } from '@tanstack/react-query'
import { useQueryList } from '../useQueryList'
import { QUERY_KEYS } from '@/constants'

export type UseSoundsRecordsSearchParams = SearchRequestParams & {
  id?: string
  createdBy?: string
}

export const getUseSoundRecordsKey = (): string[] =>
  QUERY_KEYS.SOUND_RECORDS.slice(0, 2) as string[]

export const useSoundRecords = (
  { ...params }: UseSoundsRecordsSearchParams,
  enabled = true
): UseQueryResult<PaginatedListResponse<SoundRecord>, APIErrorResponse> =>
  useQueryList({
    url: '/plannings/sound_recordings/',
    options: {
      enabled,
    },
    params: {
      ...(!!params.id && { id: params.id }),
      ...(!!params.createdBy && { created_by: params.createdBy }),
      limit: params.limit,
      offset: params.offset,
    },
  })
