import { useTranslation } from 'react-i18next'
import {
  Button,
  Icon,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { SoundRecordsTableProps } from '@/features'
import dayjs from 'dayjs'
import { DATE_FORMAT_DMY_TIME } from '@/constants'

export const SoundRecordsTableBody = ({
  rows,
  showSoundRecordDetails,
}: SoundRecordsTableProps): JSX.Element => {
  const { t } = useTranslation('soundRecords')

  return (
    <TableBody>
      {rows?.map(
        ({
          id,
          specie_identified,
          analysed,
          analysed_by,
          analysed_at,
          created_by,
          created_at,
          observation_log,
        }) => (
          <TableRow key={id}>
            <TableCell>{id}</TableCell>
            <TableCell>
              {observation_log.research_visit_event?.research_area?.project
                ?.name ?? '-'}
            </TableCell>
            <TableCell>
              {observation_log.research_visit_event?.research_area?.project
                ?.project_number ?? '-'}
            </TableCell>
            <TableCell>
              {created_by
                ? `${created_by.first_name} ${created_by.last_name}`
                : '-'}
            </TableCell>
            <TableCell>
              {created_at
                ? dayjs(created_at).format(DATE_FORMAT_DMY_TIME)
                : '-'}
            </TableCell>
            <TableCell>
              {analysed ? t('sound-record.yes') : t('sound-record.no')}
            </TableCell>
            <TableCell>
              {analysed_by
                ? `${analysed_by.first_name} ${analysed_by.last_name}`
                : '-'}
            </TableCell>
            <TableCell>
              {analysed_at
                ? dayjs(analysed_at).format(DATE_FORMAT_DMY_TIME)
                : '-'}
            </TableCell>
            <TableCell>{specie_identified}</TableCell>
            <TableCell align="right">
              <Button
                variant="text"
                onClick={() => showSoundRecordDetails(id)}
                endIcon={
                  <Icon
                    sx={{
                      fontSize: 14,
                      lineHeight: '21px',
                      fontWeight: 600,
                    }}
                  >
                    <ArrowForwardIcon sx={{ height: 14, width: 14 }} />
                  </Icon>
                }
              >
                <Typography variant="body2" fontWeight={600}>
                  {t('buttons.view')}
                </Typography>
              </Button>
            </TableCell>
          </TableRow>
        )
      )}
    </TableBody>
  )
}
