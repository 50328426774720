import { useTranslation } from 'react-i18next'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from '@mui/material'
import { SoundRecord } from '@/types'
import { getFileNameFromUrl } from '@/utils'

export type EntitySoundRecordsProps = {
  soundRecords: SoundRecord[]
  onDelete?: (id: number) => void
}

const columnNames = [
  'id',
  'fileName',
  'createdBy',
  'analysed',
  'analysedBy',
  'specieIdentified',
]

export const EntitySoundRecords = ({
  soundRecords,
  onDelete,
}: EntitySoundRecordsProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Table>
      <TableHead>
        <TableRow>
          {columnNames.map((columnName) => (
            <TableCell key={columnName} sx={{ backgroundColor: 'white' }}>
              {t(`soundRecords:columnNames.${columnName}`)}
            </TableCell>
          ))}
          <TableCell sx={{ backgroundColor: 'white' }} />
        </TableRow>
      </TableHead>
      <TableBody>
        {soundRecords.map((sr, index) => (
          <TableRow key={index}>
            <TableCell>{sr.id}</TableCell>
            <TableCell>
              <a href={sr.link} target="_blank" rel="noreferrer">
                {getFileNameFromUrl(sr.link)}
              </a>
            </TableCell>
            <TableCell>
              {sr.created_by
                ? `${sr.created_by.first_name} ${sr.created_by.last_name}`
                : '-'}
            </TableCell>
            <TableCell>
              {sr.analysed
                ? t('soundRecords:sound-record.yes')
                : t('soundRecords:sound-record.no')}
            </TableCell>
            <TableCell>
              {sr.analysed_by
                ? `${sr.analysed_by.first_name} ${sr.analysed_by.last_name}`
                : '-'}
            </TableCell>
            <TableCell>{sr.specie_identified}</TableCell>
            <TableCell>
              {!!onDelete && !sr.analysed && (
                <Button
                  onClick={() => onDelete(sr.id)}
                  variant="contained"
                  size="small"
                  color="error"
                >
                  {t('common:buttons.delete')}
                </Button>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
