import { useTranslation } from 'react-i18next'
import { Box, Stack, TextField, Typography } from '@mui/material'
import { QuotationAdditional } from '@/types'

type QuotationAdditionalSummaryProps = {
  data: QuotationAdditional
}

export const QuotationAdditionalSummary = ({
  data,
}: QuotationAdditionalSummaryProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Stack spacing={4}>
      {data.lines.map((line) => (
        <Stack
          key={line.id}
          direction="row"
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: 2.5,
            backgroundColor: 'grey.50',
            width: '100%',
            p: 4,
          }}
        >
          <Box>
            <Typography variant="body2" color="grey">
              {t('quotations:titles.description')}
            </Typography>
            <Typography>{line.description}</Typography>
          </Box>
          <Stack direction="row" spacing={2}>
            <Box width={100}>
              <TextField
                value={line.amount}
                label={t('quotations:placeholders.amount')}
                disabled={true}
              />
            </Box>
            <Box width={100}>
              <TextField
                value={line.price_per_unit}
                label={t('quotations:placeholders.pricePerUnit')}
                disabled={true}
              />
            </Box>
            <Box width={100}>
              <TextField
                value={line.total_price}
                label={t('quotations:placeholders.totalPrice')}
                disabled={true}
              />
            </Box>
          </Stack>
        </Stack>
      ))}
    </Stack>
  )
}
