import { Stack, Box, CircularProgress } from '@mui/material'
import { ProjectQuotations, ProjectSpeciesFormContainer } from '@/features'
import { CardLayout, Loader } from '@/components'
import { useTranslation } from 'react-i18next'
import { useProject, useProjectSpecies } from '@/hooks'

import { QUERY_KEYS } from '@/constants'

type ProjectSpeciesStepProps = {
  projectId: number
}

export const ProjectSpeciesStep = ({
  projectId,
}: ProjectSpeciesStepProps): JSX.Element => {
  const { t } = useTranslation('projects')

  const { data: project, isLoading: isProjectLoading } = useProject({
    id: projectId,
  })

  const { data: projectSpeciesData, isLoading } = useProjectSpecies(
    projectId,
    QUERY_KEYS.RESEARCH_SPECIES.map((key) =>
      key === ':id' ? projectId.toString() : key
    )
  )

  if (!project && isProjectLoading) return <Loader />

  return (
    <CardLayout
      title={t('titles.researchType')}
      dataTestId="research-types-container"
      cardSx={{ pb: 0 }}
    >
      {!!project?.quotations?.length && (
        <Box mb={4}>
          <ProjectQuotations
            quotations={project?.quotations}
            showRounds={false}
          />
        </Box>
      )}
      <Stack spacing={5}>
        {isLoading ? (
          <CircularProgress sx={{ alignSelf: 'center' }} color="success" />
        ) : (
          <ProjectSpeciesFormContainer
            projectId={projectId}
            projectSpeciesData={projectSpeciesData ?? []}
          />
        )}
      </Stack>
    </CardLayout>
  )
}
