import { Box, Button, Grid, Stack, Typography, Link } from '@mui/material'
import { Project } from '@/types'
import { useTranslation } from 'react-i18next'
import {
  CardAttribute,
  ProgressBar,
  CardLayout,
  SharepointUrlLink,
} from '@/components'
import { ProjectStatus } from '@/features/projectDetail'
import { EditInformationModal } from './EditInformationModal'
import dayjs from 'dayjs'
import { DATE_FORMAT_DMY, ROUTES_PATH } from '@/constants'
import { useModal } from '@/hooks'
import { Edit } from 'react-feather'
import { getPath } from '@/utils'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import DoneIcon from '@mui/icons-material/Done'

export type CardLayoutProps = {
  project: Project
}

export const Information = ({ project }: CardLayoutProps): JSX.Element => {
  const { t } = useTranslation('projects')
  const { open } = useModal()

  const eventToBePlaned =
    +(project.protocol_events_count ?? 0) - +(project.events_count ?? 0) < 0
      ? 0
      : +(project.protocol_events_count ?? 0) - +(project.events_count ?? 0)

  const handleEditProjectInformation = () =>
    open(<EditInformationModal projectId={project.id} />, {
      isBackdropDisabled: false,
      isEscapeButtonDisabled: false,
    })

  return (
    <CardLayout
      title={t('detail.title')}
      headerAction={
        <Box m={2}>
          <Button
            onClick={handleEditProjectInformation}
            variant="textThin"
            endIcon={<Edit size={14} />}
          >
            {t('detail.editInfo')}
          </Button>
        </Box>
      }
    >
      <Typography variant="h6" pb={4}>
        {t('detail.project')}
      </Typography>
      <Grid container={true} rowSpacing={5} columnSpacing={4} mb={3}>
        <Grid item={true} xs={6} sm={3}>
          <CardAttribute
            title={t('detail.id')}
            value={project.project_number}
          />
        </Grid>

        <Grid item={true} xs={6} sm={6}>
          <CardAttribute title={t('detail.projectName')} value={project.name} />
        </Grid>

        <Grid item={true} xs={6} sm={3}>
          <CardAttribute
            title={t('detail.business')}
            value={project.client_name}
          />
        </Grid>

        {project.project_leader ? (
          <Grid item={true} xs={12}>
            <CardAttribute
              title={t('detail.projectLeader')}
              value={`${project.project_leader.first_name} ${project.project_leader.last_name} [${project.project_leader.email}]`}
            />
          </Grid>
        ) : null}

        <Grid item={true} xs={6} sm={3}>
          <CardAttribute
            title={t('detail.status')}
            value={<ProjectStatus status={project.status} />}
          />
        </Grid>

        <Grid item={true} xs={6} sm={3}>
          <CardAttribute
            title={t('detail.isInGis')}
            value={project.is_in_gis ? 'Yes' : 'No'}
          />
        </Grid>

        <Grid item={true} xs={6} sm={3}>
          <CardAttribute
            title={t('detail.isSmp')}
            value={project.is_smp ? 'Yes' : 'No'}
          />
        </Grid>

        <Grid item={true} xs={6} sm={3}>
          <CardAttribute
            title={t('detail.isOnlyPlanning')}
            value={project.is_only_planning ? 'Yes' : 'No'}
          />
        </Grid>

        <Grid item={true} xs={6} sm={3}>
          <CardAttribute
            title={t('detail.isFieldReportRequired')}
            value={project.is_field_report_required ? 'Yes' : 'No'}
          />
        </Grid>

        {project.sharepoint_url && (
          <Grid item={true} xs={12} sm={12}>
            <Typography variant="caption" color="grey.600">
              {t('detail.sharepointUrl')}
            </Typography>
            <Box>
              <SharepointUrlLink sharepointUrl={project.sharepoint_url} />
            </Box>
          </Grid>
        )}

        <Grid container={true} item={true} xs={12}>
          <Grid item={true} xs={12} sm={6}>
            <CardAttribute
              title={t('detail.address')}
              value={project.address}
            />
          </Grid>
          <Grid item={true} xs={6} sm={3}>
            <CardAttribute
              title={t('placeholders.reportingHoursPerVisit')}
              value={project.reporting_hours_per_visit}
            />
          </Grid>
          <Grid item={true} xs={6} sm={3}>
            <CardAttribute
              title={t('placeholders.travelTimePerVisit')}
              value={project.travel_time_per_visit}
            />
          </Grid>
        </Grid>
      </Grid>

      <Typography variant="h6" py={6}>
        {t('detail.progress')}
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={12}
      >
        <Box>
          <CardAttribute
            title={t('detail.startDate')}
            value={dayjs(project.start_date).format(DATE_FORMAT_DMY) ?? '-'}
          />
        </Box>

        <Box flex={1}>
          <ProgressBar progress={project.progress ?? 0} />
        </Box>

        <Box>
          <CardAttribute
            title={t('detail.endDate')}
            value={dayjs(project.end_date).format(DATE_FORMAT_DMY) ?? '-'}
          />
        </Box>
      </Stack>
      <Typography variant="h6" pt={12} pb={4}>
        {t('detail.planning')}
      </Typography>
      <Grid container={true} rowSpacing={5} columnSpacing={4} mb={3}>
        <Grid item={true} xs={6} sm={3}>
          <CardAttribute
            title={t('detail.eventsToPlan')}
            value={project.protocol_events_count ?? '-'}
          />
        </Grid>
        <Grid item={true} xs={6} sm={3}>
          <CardAttribute
            title={t('detail.eventsToScheduled')}
            value={project.events_count ?? '-'}
          />
        </Grid>
        <Grid item={true} xs={6} sm={3}>
          <CardAttribute
            title={t('detail.eventsToBePlan')}
            value={eventToBePlaned}
            icon={eventToBePlaned <= 0 ? DoneIcon : WarningAmberIcon}
            colorIcon={eventToBePlaned <= 0 ? 'success.main' : 'warning.main'}
          />
        </Grid>
      </Grid>

      {!!project.quotations?.length && (
        <>
          <Typography variant="h6" pt={12} pb={4}>
            {t('detail.linkedQuotations')}
          </Typography>
          <Grid item={true} xs={12} sm={12}>
            {project.quotations.map((quotation) => (
              <Link
                key={quotation.id}
                href={getPath(ROUTES_PATH.QUOTATION_DETAIL, {
                  id: quotation.id,
                })}
                target="_blank"
                color="primary.light"
                mr={4}
              >
                {t('buttons.quotation', { id: quotation.id })}
              </Link>
            ))}
          </Grid>
        </>
      )}
    </CardLayout>
  )
}
