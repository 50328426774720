import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { Stack, Box, TextField } from '@mui/material'
import { ControlledTextField } from '@/components/inputs'

export type QuotationAdditionalFormData = {
  [key: string]: {
    id?: number
    description: string
    amount: string
    price_per_unit: string
    total_price: string
  }
}

export type QuotationAdditionalFormDataProps = {
  index: string
}

export const QuotationAdditionalForm = ({
  index,
}: QuotationAdditionalFormDataProps) => {
  const { t } = useTranslation()

  const { control, getValues } = useFormContext<QuotationAdditionalFormData>()

  return (
    <Stack
      direction={{ xs: 'column', xl: 'row' }}
      justifyContent="space-between"
      spacing={{ xs: 4 }}
      borderRadius={2.5}
      p={4}
      bgcolor="grey.50"
      width="100%"
    >
      <Stack direction="row" spacing={2}>
        <Box width={100}>
          <ControlledTextField
            name={`${index}.amount`}
            control={control}
            type="number"
            placeholder={t('quotations:placeholders.amount')}
            inputProps={{ min: 1 }}
            errorMessagePrefix=""
          />
        </Box>
        <Box width={500}>
          <ControlledTextField
            name={`${index}.description`}
            control={control}
            placeholder={t('quotations:placeholders.description')}
            errorMessagePrefix=""
          />
        </Box>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Box width={120}>
          <ControlledTextField
            name={`${index}.price_per_unit`}
            control={control}
            placeholder={t('quotations:placeholders.pricePerUnit')}
            errorMessagePrefix=""
          />
        </Box>
        <Box width={120}>
          <TextField
            value={
              (Number(getValues(`${index}.amount`)) ?? 0) *
              (Number(getValues(`${index}.price_per_unit`)) ?? 0)
            }
            label={t('quotations:placeholders.totalPrice')}
            disabled={true}
          />
        </Box>
      </Stack>
    </Stack>
  )
}
