import { Dispatch, SetStateAction } from 'react'
import {
  AmphibianFishForm,
  BatsFeedingAreaForm,
  BatsFlightPathForm,
  BatsForm,
  BirdsBreedingForm,
  CourtshipTerritoryForm,
  InsectsForm,
  MammalsForm,
  ReptilesForm,
} from '@/components/forms/ObservationLogFormContainer'
import {
  PatchMedia,
  useMutationWrapper,
  usePatchObservationLog,
  useUploadMedia,
} from '@/hooks'
import dayjs, { Dayjs } from 'dayjs'
import {
  ObservationLog,
  PatchObservationLog,
  ModelsWithMedia,
  MediaType,
} from '@/types'
import {
  PLACE_FOUND_OPTION_VALUE,
  PLACE_NOT_FOUND_OPTION_VALUE,
} from './fieldsOptions'

export type ObservationLogFormData = Partial<
  Omit<
    PatchObservationLog,
    | 'photos'
    | 'sound_recordings'
    | 'amount'
    | 'amount2'
    | 'amount3'
    | 'amount4'
    | 'amount_per_15_min'
    | 'is_place_found'
    | 'first_fledgling_time'
    | 'observed_at'
    | 'research_visit_event_id'
    | 'research_observation_log_type_id'
  >
> & {
  photos?: PatchMedia
  sound_recordings?: PatchMedia
  amount?: string
  amount2?: string
  amount3?: string
  amount4?: string
  amount_per_15_min?: string
  is_place_found?: string
  first_fledgling_time?: Dayjs
  observed_at?: Dayjs
}

export type ObservationLogFormContainerProps = {
  data: ObservationLog
  setUpdatedObservationLog: Dispatch<SetStateAction<ObservationLog>>
  onSuccessUpdate: () => void
}

export const ObservationLogFormContainer = ({
  data,
  setUpdatedObservationLog,
  onSuccessUpdate,
}: ObservationLogFormContainerProps): JSX.Element => {
  const {
    mutateAsync: updateObservationLog,
    isLoading: mutateObservationLogInProcess,
    error,
  } = useMutationWrapper(
    usePatchObservationLog.bind(null, {
      id: data.id,
    })
  )

  const { uploadMedia, isLoading: isMediaUploading } = useUploadMedia({
    modelKey: ModelsWithMedia.observationLogs,
  })

  const handleObservationLogSubmit = async (
    formData: ObservationLogFormData
  ) => {
    if (data.research_visit_event && data.research_observation_log_type) {
      const observationLogData = {
        ...formData,
        photos: null,
        sound_recordings: null,
        amount: !!formData.amount ? Number(formData.amount) : null,
        amount2: !!formData.amount2 ? Number(formData.amount2) : null,
        amount3: !!formData.amount3 ? Number(formData.amount3) : null,
        amount4: !!formData.amount4 ? Number(formData.amount4) : null,
        research_visit_event_id: data.research_visit_event.id,
        research_observation_log_type_id: data.research_observation_log_type.id,
        is_place_found: formData.is_place_found === PLACE_FOUND_OPTION_VALUE,
        recording_number: formData.recording_number || '',
        amount_per_15_min: Number(formData.amount_per_15_min),
        is_sound_made: !!formData.recording_number,
        observed_at:
          formData.observed_at && dayjs(formData.observed_at).utc().format(),
        first_fledgling_time:
          formData.first_fledgling_time &&
          dayjs(formData.first_fledgling_time).utc().format(),
      }

      if (formData.photos?.chosenToUpload || formData.photos?.stale) {
        await uploadMedia({
          media: formData.photos,
          entityId: data.id,
        })
      }

      if (
        formData.sound_recordings?.chosenToUpload ||
        formData.sound_recordings?.stale
      ) {
        await uploadMedia({
          media: formData.sound_recordings,
          entityId: data.id,
        })
      }

      await updateObservationLog(observationLogData, {
        successMessageKey:
          'observationLogs:notifications.observationLogChanged',
        onSuccess: (data) => {
          setUpdatedObservationLog(data)
          onSuccessUpdate()
        },
      })
    }
  }

  const defaultValues = {
    specie: data?.specie ?? '',
    specie2: data?.specie2 ?? '',
    specie3: data?.specie3 ?? '',
    specie4: data?.specie4 ?? '',
    other_specie: data?.other_specie ?? '',
    amount: data?.amount?.toString() ?? '',
    amount2: data?.amount2?.toString() ?? '',
    amount3: data?.amount3?.toString() ?? '',
    amount4: data?.amount4?.toString() ?? '',
    counting_method: data?.counting_method ?? '',
    counting_method2: data?.counting_method2 ?? '',
    counting_method3: data?.counting_method3 ?? '',
    counting_method4: data?.counting_method4 ?? '',
    behaviour: data?.behaviour ?? '',
    other_behaviour: data?.other_behaviour ?? '',
    behaviour_notes: data?.behaviour_notes ?? '',
    direction: data?.direction ?? '',
    wind_direction: data?.wind_direction ?? '',
    recording_number: data?.recording_number ?? '',
    amount_per_15_min: data?.amount_per_15_min?.toString() ?? '',
    is_place_found: data?.is_place_found
      ? PLACE_FOUND_OPTION_VALUE
      : data?.is_place_found === false
      ? PLACE_NOT_FOUND_OPTION_VALUE
      : undefined,
    place_type: data?.place_type ?? '',
    first_fledgling_time: data?.first_fledgling_time
      ? dayjs(data.first_fledgling_time)
      : undefined,
    place_type_notes: data?.place_type_notes ?? '',
    photos: {
      chosenToUpload: [],
      uploadedToApi: data?.photos ?? [],
      stale: [],
      mediaType: MediaType.photos,
    },
    sound_recordings: {
      chosenToUpload: [],
      uploadedToApi: data?.sound_recordings ?? [],
      stale: [],
      mediaType: MediaType.sound_recordings,
    },
    notes: data?.notes ?? '',
    observed_at: data?.observed_at ? dayjs(data.observed_at) : undefined,
    address: data?.address ?? '',
  }

  const props = {
    defaultValues,
    onSubmit: handleObservationLogSubmit,
    isLoading: mutateObservationLogInProcess || isMediaUploading,
    apiErrorData: error?.data,
  }

  switch (data.research_observation_log_type.code) {
    case 'bats':
      return <BatsForm {...props} />
    case 'bats_feeding_area':
      return <BatsFeedingAreaForm {...props} />
    case 'bats_flight_path':
      return <BatsFlightPathForm {...props} />
    case 'courtship_territory':
      return <CourtshipTerritoryForm {...props} />
    case 'birds_breeding':
      return <BirdsBreedingForm {...props} />
    case 'mammals':
      return <MammalsForm {...props} />
    case 'amphibian_fish':
      return <AmphibianFishForm {...props} />
    case 'reptiles':
      return <ReptilesForm {...props} />
    case 'insects':
      return <InsectsForm {...props} />
    default:
      return <></>
  }
}
