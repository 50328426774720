import { useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { Stack, Button, IconButton } from '@mui/material'
import { PlusCircle, X } from 'react-feather'
import {
  useMutationWrapper,
  useQuotationPaymentsTerms,
  usePostQuotationPaymentTerm,
  usePatchQuotationPaymentTerm,
  useDeleteQuotationPaymentTerm,
  getUseQuotationPaymentsTermsKey,
} from '@/hooks'
import { useConfirmationModal } from '@/hooks/useConfirmationModal'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  QuotationPaymentsTermsForm,
  QuotationPaymentsTermsFormData,
} from './QuotationPaymentsTermsForm'
import { FormActionButtons } from '@/features/quotationCreate/FormActionButtons'
import { quotationPaymentsTermsSchema } from './validationSchema'
import { theme } from '@/theme'
import { QuotationPaymentTerm } from '@/types'
import { DATE_FORMAT_YMD } from '@/constants'
import dayjs from 'dayjs'

type QuotationPaymentTermsFormContainerProps = {
  quotationId: number
  totalPrice: number
  subtotalPrice: number
}

export const QuotationPaymentsTermsFormContainer = ({
  quotationId,
  totalPrice,
  subtotalPrice,
}: QuotationPaymentTermsFormContainerProps): JSX.Element => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const { setConfirmationOpen } = useConfirmationModal()

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const { data: quotationPaymentsTerms } =
    useQuotationPaymentsTerms(quotationId)

  const { mutateAsync: createQuotationPaymentTerm, isLoading: isCreating } =
    useMutationWrapper(() => usePostQuotationPaymentTerm(quotationId))

  const { mutateAsync: updateQuotationPaymentTerm, isLoading: isMutating } =
    useMutationWrapper(() => usePatchQuotationPaymentTerm(quotationId))

  const { mutateAsync: deleteQuotationPaymentTerm, isLoading: isDeleting } =
    useMutationWrapper(() => useDeleteQuotationPaymentTerm(quotationId))

  const defaultValues = useMemo(() => {
    if (quotationPaymentsTerms) {
      const terms: QuotationPaymentsTermsFormData = {}

      quotationPaymentsTerms.forEach((term) => {
        terms[`${term.id}`] = {
          id: term.id,
          amount_percentage: term.amount_percentage,
          description: term.description,
          due_date: dayjs(term.due_date),
        }
      })

      return terms
    }

    return {}
  }, [quotationPaymentsTerms])

  const methods = useForm<QuotationPaymentsTermsFormData>({
    mode: 'onChange',
    defaultValues: {},
    values: defaultValues,
    resolver: yupResolver(quotationPaymentsTermsSchema),
  })

  const deleteTerm = (index: string) => {
    setConfirmationOpen(
      t('quotations:titles.deleteConfirmation'),
      t('quotations:titles.deleteTerm'),
      async () => {
        try {
          await deleteQuotationPaymentTerm({ id: Number(index) })

          const quotationQueryKey = getUseQuotationPaymentsTermsKey(quotationId)

          await queryClient.invalidateQueries(quotationQueryKey)
        } catch (e) {
          enqueueSnackbar(`${t('errors:api.execute')}: ${e}`, {
            variant: 'error',
          })
        }
      }
    )
  }

  const handleSubmit = methods.handleSubmit(async (formData) => {
    enqueueSnackbar('Saving...', {
      variant: 'default',
    })

    const updatedTerms: Promise<QuotationPaymentTerm>[] = []

    Object.keys(formData).forEach((termId) => {
      const termData = {
        amount_percentage: formData[termId].amount_percentage,
        description: formData[termId].description,
        due_date: dayjs(formData[termId].due_date).format(DATE_FORMAT_YMD),
      }

      if (termId === 'new') {
        updatedTerms.push(createQuotationPaymentTerm({ ...termData }))
      } else {
        updatedTerms.push(
          updateQuotationPaymentTerm({
            id: Number(termId),
            ...termData,
          })
        )
      }
    })

    try {
      await Promise.all(updatedTerms)

      const quotationQueryKey = getUseQuotationPaymentsTermsKey(quotationId)

      await queryClient.invalidateQueries(quotationQueryKey)

      closeSnackbar()
    } catch (e) {
      enqueueSnackbar(`${t('errors:api.execute')}: ${e}`, {
        variant: 'error',
      })
    }
  })

  const handleReset = () =>
    methods.reset({
      ...defaultValues,
    })

  const handleAddNewTerm = () => {
    methods.setValue('new', {
      id: undefined,
      amount_percentage: 0,
      description: '',
      due_date: null,
    })
  }

  const lines = methods.watch()

  return (
    <>
      <FormProvider {...methods}>
        <Stack spacing={5}>
          {Object.entries(lines).map(([key], index) => (
            <Stack direction="row" alignItems="center" key={key}>
              <QuotationPaymentsTermsForm
                index={key}
                orderNr={index}
                totalPrice={totalPrice}
                subtotalPrice={subtotalPrice}
              />
              {key !== 'new' && (
                <IconButton onClick={() => deleteTerm(key)}>
                  <X size="16px" color={theme.palette.green['500']} />
                </IconButton>
              )}
            </Stack>
          ))}
        </Stack>
        {methods.formState.isDirty && (
          <FormActionButtons
            cancelClick={handleReset}
            saveClick={handleSubmit}
            isLoading={isCreating || isMutating || isDeleting}
            disabled={!methods.formState.isDirty}
          />
        )}
      </FormProvider>
      <Button
        variant="text"
        onClick={handleAddNewTerm}
        startIcon={<PlusCircle size="16px" />}
        sx={{ mt: 6 }}
      >
        {t('quotations:buttons.addAdditionalTerm')}
      </Button>
    </>
  )
}
