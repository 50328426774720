import { Quotation } from '@/types'
import { Paper, Table, TableContainer } from '@mui/material'
import { QuotationsTableBody } from './QuotationsTableBody'
import { QuotationsTableHead } from './QuotationsTableHead'

export type QuotationsTableProps = {
  rows: Quotation[]
}

export const QuotationsTable = ({
  rows,
}: QuotationsTableProps): JSX.Element => (
  <TableContainer component={Paper}>
    <Table stickyHeader={true}>
      <QuotationsTableHead />
      <QuotationsTableBody rows={rows} />
    </Table>
  </TableContainer>
)
