import { APIErrorResponse, Specie } from '@/types'
import { UseQueryResult } from '@tanstack/react-query'
import { useQueryList } from '../useQueryList'

export const useProtocolsSpecies = (
  enabled = true
): UseQueryResult<Specie[], APIErrorResponse> =>
  useQueryList({
    url: '/protocols/species/',
    options: {
      enabled,
    },
  })
