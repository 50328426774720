import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { Stack, Box, TextField } from '@mui/material'
import { ControlledDateField, ControlledTextField } from '@/components/inputs'
import { Dayjs } from 'dayjs'

export type QuotationPaymentsTermsFormData = {
  [key: string]: {
    id?: number
    amount_percentage: number
    description: string
    due_date: Dayjs | null
  }
}

export type QuotationPaymentsTermsFormDataProps = {
  index: string
  orderNr: number
  totalPrice: number
  subtotalPrice: number
}

export const QuotationPaymentsTermsForm = ({
  index,
  orderNr,
  totalPrice,
  subtotalPrice,
}: QuotationPaymentsTermsFormDataProps) => {
  const { t } = useTranslation()

  const { control, getValues } =
    useFormContext<QuotationPaymentsTermsFormData>()

  return (
    <Stack
      direction="row"
      spacing={2}
      borderRadius={2.5}
      p={4}
      bgcolor="grey.50"
      width="100%"
    >
      <Stack
        direction={{ xs: 'column', xl: 'row' }}
        justifyContent={index !== 'new' ? 'space-between' : 'flex-start'}
        spacing={4}
        width="100%"
      >
        {index !== 'new' && (
          <Box width={110}>
            <TextField
              value={orderNr + 1}
              label={t('quotations:placeholders.paymentOrder')}
              disabled={true}
            />
          </Box>
        )}
        <Box width={100}>
          <ControlledTextField
            name={`${index}.amount_percentage`}
            control={control}
            type="number"
            placeholder={t('quotations:placeholders.amountPercentage')}
            inputProps={{ min: 1 }}
            errorMessagePrefix=""
          />
        </Box>
        <Box width={380}>
          <ControlledTextField
            name={`${index}.description`}
            control={control}
            placeholder={t('quotations:placeholders.description')}
            errorMessagePrefix=""
          />
        </Box>
        <Box width={240}>
          <ControlledDateField
            name={`${index}.due_date`}
            control={control}
            placeholder={t('quotations:placeholders.dueDate')}
            errorMessagePrefix=""
            disablePast={true}
          />
        </Box>
        {index !== 'new' && (
          <>
            <Box width={120}>
              <TextField
                value={(
                  (subtotalPrice * getValues(`${index}.amount_percentage`)) /
                  100
                ).toFixed(2)}
                label={t('quotations:labels.totalExcVat')}
                disabled={true}
              />
            </Box>
            <Box width={120}>
              <TextField
                value={(
                  (totalPrice * getValues(`${index}.amount_percentage`)) /
                  100
                ).toFixed(2)}
                label={t('quotations:placeholders.totalPrice')}
                disabled={true}
              />
            </Box>
          </>
        )}
      </Stack>
    </Stack>
  )
}
