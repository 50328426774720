import { useTranslation } from 'react-i18next'
import { Box, Stack, Typography, TextField } from '@mui/material'
import {
  QuotationResearchRoundSummary,
  QuotationOverheadSummary,
  QuotationAdditionalSummary,
} from '@/features'
import { QuotationDetails } from '@/types'

type QuotationPaymentsStepProps = { quotationDetails: QuotationDetails }

export const QuotationSummary = ({
  quotationDetails,
}: QuotationPaymentsStepProps): JSX.Element => {
  const { t } = useTranslation('quotations')

  return (
    <>
      <Box>
        <Typography variant="h6" sx={{ mb: 4 }}>
          {t('titles.financial')}
        </Typography>
        {quotationDetails.researches.map((quotationResearch) => (
          <QuotationResearchRoundSummary
            key={quotationResearch.id}
            data={quotationResearch}
          />
        ))}
        <Typography variant="h6" sx={{ mt: 8, mb: 4 }}>
          {t('titles.overhead')}
        </Typography>
        <QuotationOverheadSummary data={quotationDetails.overhead} />
        {!!quotationDetails.additional_lines.lines.length && (
          <>
            <Typography variant="h6" sx={{ mt: 8, mb: 4 }}>
              {t('titles.additional')}
            </Typography>
            <QuotationAdditionalSummary
              data={quotationDetails.additional_lines}
            />
          </>
        )}
      </Box>
      <Stack direction="row" justifyContent="flex-end" mt={8}>
        <Stack spacing={4}>
          <TextField
            value={quotationDetails.subtotal_price}
            label={t('labels.totalExcVat')}
            disabled={true}
          />
          <TextField
            value={quotationDetails.tax_price}
            label={t('labels.21Vat')}
            disabled={true}
          />
          <TextField
            value={quotationDetails.total_price}
            label={t('labels.totalInclVat')}
            disabled={true}
          />
        </Stack>
      </Stack>
    </>
  )
}
