import {
  FieldValues,
  useController,
  Control,
  Controller,
  Path,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid, Stack, Box, TextField, Typography } from '@mui/material'
import { EntityPhoto, EntitySoundRecords } from '@/components'
import { SoundRecordUpload } from './SoundRecordUpload'
import {
  ControlledFieldProps,
  ApiErrorData,
  MediaType,
  Photo,
  SoundRecord,
} from '@/types'
import {
  capitalizeFirstLetter,
  getFieldErrorMessage,
  hasFieldError,
} from '@/utils'
import { ChangeEvent } from 'react'
import { PatchMedia } from '@/hooks'

export type ControlledMediaUploadProps<T extends FieldValues> = {
  name: Path<T>
  control: Control<T>
  isReadonly?: boolean
  eventId?: number
  label?: string
  apiErrorData?: ApiErrorData
  apiFieldName?: string
  isFieldStateErrorShown?: boolean
  errorMessagePrefix?: string
  disabled?: boolean
} & ControlledFieldProps<T>

export const ControlledMediaUpload = <T extends FieldValues>({
  name,
  control,
  apiErrorData,
  label,
  isFieldStateErrorShown = true,
  apiFieldName,
  errorMessagePrefix = 'errors:validation',
  disabled = false,
}: ControlledMediaUploadProps<T>): JSX.Element | null => {
  const { t } = useTranslation()

  const {
    field: { onChange, value },
  } = useController<T>({
    name,
    control,
  })

  const media = value as PatchMedia

  const onChooseMedia = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement

    if (target.files) {
      const chosenMedia = Array.from(target.files).map((mi) => ({
        file: mi,
        link: URL.createObjectURL(mi),
      }))

      onChange({
        ...value,
        chosenToUpload: [...value.chosenToUpload, ...chosenMedia],
      })
    }
  }

  const onDeleteChosenMedia = (index: number) => {
    if (media && media.chosenToUpload) {
      media.chosenToUpload.splice(index, 1)
      onChange({
        ...value,
        chosenToUpload: [...media.chosenToUpload],
      })
    }
  }

  const onDeleteUploadedMedia = (id: number) => {
    if (media && media.chosenToUpload) {
      onChange({ ...value, stale: [...value.stale, id] })
    }
  }

  return (
    <Stack>
      <Typography>{label}</Typography>

      {!disabled && (
        <Box width="33%">
          <Controller
            name={name}
            control={control}
            render={({ fieldState: { error } }) => (
              <TextField
                id={name}
                onChange={onChooseMedia}
                value=""
                type="file"
                error={
                  !!error || hasFieldError(apiFieldName ?? name, apiErrorData)
                }
                helperText={
                  isFieldStateErrorShown && error?.message
                    ? t(
                        errorMessagePrefix
                          ? `${errorMessagePrefix}.${error.message}`
                          : error.message
                      )
                    : capitalizeFirstLetter(
                        getFieldErrorMessage(apiFieldName ?? name, apiErrorData)
                      )
                }
                inputProps={{
                  multiple: true,
                }}
              />
            )}
          />
        </Box>
      )}

      {media?.mediaType === MediaType.photos ? (
        <Grid container={true} spacing={2} mt={4}>
          {media.chosenToUpload.map((mi, index) => (
            <EntityPhoto
              photo={mi}
              onDelete={() => onDeleteChosenMedia(index)}
              key={index}
            />
          ))}
          {media.uploadedToApi.map(
            (mi, index) =>
              !media.stale.includes(mi.id) && (
                <EntityPhoto
                  photo={mi as Photo}
                  onDelete={
                    !disabled ? () => onDeleteUploadedMedia(mi.id) : undefined
                  }
                  key={`${mi.id}_${index}`}
                />
              )
          )}
        </Grid>
      ) : (
        <>
          {!!media?.chosenToUpload.length && (
            <Stack mt={4}>
              <Typography>
                {t('soundRecords:titles.newFilesToUpload')}
              </Typography>
              {media.chosenToUpload.map((mi, index) => (
                <SoundRecordUpload
                  soundRecord={mi}
                  onDelete={() => onDeleteChosenMedia(index)}
                  key={index}
                />
              ))}
            </Stack>
          )}
          {!!media?.uploadedToApi.length && (
            <Stack mt={4}>
              <Typography>
                {t('soundRecords:titles.uploadedSoundRecords')}
              </Typography>
              <EntitySoundRecords
                soundRecords={
                  media.uploadedToApi.filter(
                    (mi) => !media.stale.includes(mi.id)
                  ) as SoundRecord[]
                }
                onDelete={!disabled ? onDeleteUploadedMedia : undefined}
              />
            </Stack>
          )}
        </>
      )}
    </Stack>
  )
}
