import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { useApiClient } from '@/hooks'
import { unwrapErrorResponse } from '@/utils'
import { APIErrorResponse, DeleteQuotationPaymentTerm } from '@/types'

export const useDeleteQuotationPaymentTerm = (
  quotationId: number
): UseMutationResult<unknown, APIErrorResponse, DeleteQuotationPaymentTerm> => {
  const client = useApiClient()

  return useMutation((params) =>
    client
      .delete(`/quotations/${quotationId}/payment_terms/${params.id}/`)
      .catch(unwrapErrorResponse)
  )
}
