import {
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { SpeciesResearchTypes } from '@/features/projectDetail/SpeciesResearchTypes'
import { LinkButton } from '@/components'
import { useMemo } from 'react'
import { ResearchArea, ResearchVisitEvent } from '@/types'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { DATE_FORMAT_DMY, ROUTES_PATH, PageReferrers } from '@/constants'
import { getPath } from '@/utils'
import { theme } from '@/theme'

export type ResearchAreaEventProps = {
  isLoading?: boolean
  events: ResearchVisitEvent[]
  researchArea: ResearchArea
  canGoToDetail?: boolean
}

export const ResearchAreaEvents = ({
  isLoading = false,
  events,
  researchArea,
  canGoToDetail = false,
}: ResearchAreaEventProps) => {
  const { t } = useTranslation('projects')

  const protocolEvents = useMemo<Record<number, ResearchVisitEvent[]>>(() => {
    const values: Record<number, ResearchVisitEvent[]> = {}

    events.forEach((event) => {
      const { protocol_id } = event

      if (!values[protocol_id] || !values[protocol_id].length) {
        values[protocol_id] = []
      }

      values[protocol_id].push(event)
    })

    return values
  }, [events])

  return (
    <Stack spacing={3} mt={5}>
      {isLoading && <Skeleton height={125} />}

      <Stack spacing={3}>
        {researchArea?.research_area_protocols.map(({ protocol }) => (
          <Stack
            key={protocol.id}
            data-testid="research-type-events-form"
            p={5}
            bgcolor="grey.50"
            borderRadius={2.5}
            spacing={5}
            flex={1}
            width="100%"
          >
            <SpeciesResearchTypes
              protocol={protocol}
              speciesColumnWidth="240px"
              researchTypesColumnWidth="100%"
            />
            <Stack
              px={5}
              mb={1}
              borderRadius="12px"
              spacing={4}
              direction="row"
              flex={1}
              justifyContent="space-between"
            />
            <Stack spacing={5}>
              {!protocolEvents[protocol.id]?.length ? (
                <Typography>{t('areas.noEvents')}</Typography>
              ) : (
                <TableContainer
                  component={Paper}
                  sx={{
                    filter: 'drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.07))',
                  }}
                >
                  <Table stickyHeader={true}>
                    <TableHead
                      sx={{
                        borderRadius: 8,
                      }}
                    >
                      <TableRow>
                        <TableCell
                          sx={{
                            backgroundColor: theme.palette.green[200],
                          }}
                        >
                          {t(`areas.round`)}
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: theme.palette.green[200],
                          }}
                        >
                          {t(`areas.startDate`)}
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: theme.palette.green[200],
                          }}
                        >
                          {t(`areas.researchers`)}
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: theme.palette.green[200],
                          }}
                        />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {protocolEvents[protocol.id]?.map(
                        ({
                          id,
                          round_number,
                          round_type,
                          start_datetime,
                          researchers,
                        }) => (
                          <TableRow key={id}>
                            <TableCell>
                              {round_type} {round_number}
                            </TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                              {start_datetime
                                ? dayjs(start_datetime).format(DATE_FORMAT_DMY)
                                : '-'}
                            </TableCell>
                            <TableCell>
                              {researchers?.map(
                                ({ first_name, last_name }, i) =>
                                  `${
                                    i > 0 ? ', ' : ''
                                  }${first_name} ${last_name}`
                              ) ?? '-'}
                            </TableCell>
                            <TableCell>
                              {canGoToDetail && (
                                <LinkButton
                                  url={getPath(ROUTES_PATH.MONITORING_DETAIL, {
                                    id,
                                  })}
                                  title={t(`buttons.detail`)}
                                  referrer={PageReferrers.PROJECT}
                                  arrowIcon={true}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
}
