import { AxiosResponse } from 'axios'
import { useApiClient, getMediaBaseEndpoint } from '@/hooks'
import { unwrapErrorResponse } from '@/utils'
import { MediaType, ModelsWithMedia } from '@/types'

type UseDeletePhotoParams = {
  modelKey: ModelsWithMedia
}

type UseDeletePhotoPayload = {
  entityId: number
  mediaId: number
  mediaType: MediaType
}

export const useDeleteEntityMedia = ({
  modelKey,
}: UseDeletePhotoParams): ((
  data: UseDeletePhotoPayload
) => Promise<AxiosResponse<void>>) => {
  const client = useApiClient()

  return ({ mediaId, entityId, mediaType }) =>
    client
      .delete(
        `${getMediaBaseEndpoint(modelKey)}/${entityId}/${mediaType}/${mediaId}/`
      )
      .then((response) => response)
      .catch(unwrapErrorResponse)
}
