import { ModalLayout } from '@/components'
import { QuotationResearchFormContainer } from './QuotationResearchFormContainer'
import { useTranslation } from 'react-i18next'

export type QuotationResearchModalProps = {
  quotationId: number
  researchId?: number
}

export const QuotationResearchModal = ({
  quotationId,
  researchId,
}: QuotationResearchModalProps): JSX.Element => {
  const { t } = useTranslation('quotations')

  return (
    <ModalLayout
      title={t('titles.quotationResearch')}
      cardContentSx={{
        maxHeight: '70vh',
        overflowY: 'auto',
        minWidth: { xs: 'auto', md: '920px' },
      }}
    >
      <QuotationResearchFormContainer
        quotationId={quotationId}
        researchId={researchId}
      />
    </ModalLayout>
  )
}
