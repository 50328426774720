import { useTranslation } from 'react-i18next'
import { Stack, Button, Typography } from '@mui/material'
import { ChosenFile } from '@/hooks'

export type EntitySoundRecordProps = {
  soundRecord: ChosenFile
  onDelete: () => void
}

export const SoundRecordUpload = ({
  soundRecord,
  onDelete,
}: EntitySoundRecordProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      borderRadius={2.5}
      px={3}
      py={1}
      bgcolor="grey.50"
    >
      <Typography my={4}>{soundRecord.file.name}</Typography>
      <Button onClick={onDelete} variant="contained" size="small" color="error">
        {t('common:buttons.delete')}
      </Button>
    </Stack>
  )
}
