import { useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { Stack, IconButton, TextField } from '@mui/material'
import { X } from 'react-feather'
import {
  useMutationWrapper,
  usePatchQuotationOverheadLine,
  useDeleteQuotationOverheadLine,
  getUseQuotationDetailsKey,
} from '@/hooks'
import { useConfirmationModal } from '@/hooks/useConfirmationModal'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  QuotationOverheadForm,
  QuotationOverheadFormData,
} from './QuotationOverheadForm'
import { FormActionButtons } from '@/features/quotationCreate/FormActionButtons'
import { QuotationOverhead, QuotationOverheadLine } from '@/types'
import { quotationOverheadSchema } from './validationSchema'
import { theme } from '@/theme'

type QuotationOverheadFormContainerProps = {
  quotationId: number
  data: QuotationOverhead
}

export const QuotationOverheadFormContainer = ({
  quotationId,
  data,
}: QuotationOverheadFormContainerProps): JSX.Element => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const { setConfirmationOpen } = useConfirmationModal()

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const { mutateAsync: updateQuotationOverheadLine, isLoading: isMutating } =
    useMutationWrapper(() => usePatchQuotationOverheadLine(quotationId))

  const { mutateAsync: deleteQuotationOverheadLine, isLoading: isDeleting } =
    useMutationWrapper(() => useDeleteQuotationOverheadLine(quotationId))

  const defaultValues = useMemo(() => {
    const lines: QuotationOverheadFormData = {}

    data.lines.forEach((line) => {
      lines[`${line.id}`] = {
        id: line.id,
        type: line.type,
        amount: line.amount,
        number_of_visits: line.number_of_visits,
        amount_per_round: line.amount_per_round,
        price_per_unit: line.price_per_unit,
        total_price: line.total_price,
      }
    })

    return lines
  }, [data])

  const methods = useForm<QuotationOverheadFormData>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(quotationOverheadSchema),
  })

  useEffect(() => {
    if (data) {
      methods.reset(defaultValues)
    }
  }, [data, methods, defaultValues])

  const deleteOverheadType = (lineId: number) => {
    setConfirmationOpen(
      t('quotations:titles.deleteConfirmation'),
      t('quotations:titles.deleteOverheadItem'),
      async () => {
        try {
          await deleteQuotationOverheadLine({ id: lineId })

          const quotationQueryKey = getUseQuotationDetailsKey(quotationId)

          await queryClient.invalidateQueries(quotationQueryKey)
        } catch (e) {
          enqueueSnackbar(`${t('errors:api.execute')}: ${e}`, {
            variant: 'error',
          })
        }
      }
    )
  }

  const handleSubmit = methods.handleSubmit(async (formData) => {
    enqueueSnackbar('Saving...', {
      variant: 'default',
    })

    const updatedLines: Promise<QuotationOverheadLine>[] = []

    Object.keys(formData).forEach((lineId) => {
      updatedLines.push(
        updateQuotationOverheadLine({
          id: Number(lineId),
          amount_per_round: formData[lineId].amount_per_round,
          price_per_unit: formData[lineId].price_per_unit,
        })
      )
    })

    try {
      await Promise.all(updatedLines)

      const quotationQueryKey = getUseQuotationDetailsKey(quotationId)

      await queryClient.invalidateQueries(quotationQueryKey)

      closeSnackbar()
    } catch (e) {
      enqueueSnackbar(`${t('errors:api.execute')}: ${e}`, {
        variant: 'error',
      })
    }
  })

  const handleReset = () =>
    methods.reset({
      ...defaultValues,
    })

  const lines = methods.watch()

  const totalPrice =
    lines &&
    Object.keys(lines)
      .map(
        (key) =>
          Number(methods.getValues(`${key}.amount_per_round`)) *
            methods.getValues(`${key}.number_of_visits`) *
            Number(methods.getValues(`${key}.price_per_unit`)) ?? 0
      )
      .reduce((a, b) => a + b, 0)
      .toFixed(1)

  return (
    <>
      <FormProvider {...methods}>
        <Stack spacing={5}>
          {data.lines.map((line) => (
            <Stack direction="row" alignItems="center" key={line.id}>
              <QuotationOverheadForm line={line} />

              <IconButton onClick={() => deleteOverheadType(line.id)}>
                <X size="16px" color={theme.palette.green['500']} />
              </IconButton>
            </Stack>
          ))}
          {data.lines.length > 0 && (
            <Stack direction="row" justifyContent="flex-end">
              <TextField
                value={totalPrice}
                label={t('quotations:placeholders.totalPrice')}
                disabled={true}
              />
            </Stack>
          )}
        </Stack>
        {methods.formState.isDirty && (
          <FormActionButtons
            cancelClick={handleReset}
            saveClick={handleSubmit}
            isLoading={isMutating || isDeleting}
            disabled={!methods.formState.isDirty}
          />
        )}
      </FormProvider>
    </>
  )
}
