import { useEffect, useMemo } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { CardAttribute } from '@/components/ui'
import {
  AutocompleteOption,
  ControlledAutocomplete,
  ControlledCheckboxField,
  ControlledDateField,
  ControlledSelect,
  ControlledTextField,
  ProjectLeaderAutocomplete,
} from '@/components/inputs'
import { useForm } from 'react-hook-form'
import { Client, FormComponentProps, Project, ProjectStatus } from '@/types'
import { Dayjs } from 'dayjs'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { useClients, useQuotations } from '@/hooks'
import { capitalizeFirstLetter, formatDateInformation } from '@/utils'

export type ProjectBasicInfo = {
  client: AutocompleteOption<Client> | null
  projectLeader: AutocompleteOption<number> | null
  projectNumber: string
  name: string
  description?: string
  startDate?: Dayjs | null
  endDate?: Dayjs | null
  isSmp: boolean
  isOnlyPlanning: boolean
  isFieldReportRequired: boolean
  sharepointUrl?: string
  address?: string
  hasGisStatus?: boolean
  status?: ProjectStatus
  quotations?: AutocompleteOption<number>[]
  reportingHoursPerVisit?: string
  travelTimePerVisit?: string
}

export type ProjectBasicInfoFormProps = {
  project?: Project
  isReadOnlyDate?: boolean
  hasStatusFields?: boolean
  showControlsOnDirty?: boolean
  controls: JSX.Element
} & FormComponentProps<ProjectBasicInfo>

const projectCreateScheme = yup.object({
  client: yup.object().typeError('required').required(),
  projectNumber: yup.string().required(),
  name: yup.string().required().nullable(),
  startDate: yup.date().typeError('invalid_date').required().nullable(),
  endDate: yup.date().typeError('invalid_date').required().nullable(),
})

const projectBasicInfoScheme = yup.object({
  projectNumber: yup.string().required(),
  name: yup.string().required().nullable(),
  startDate: yup.date().typeError('invalid_date').required().nullable(),
  endDate: yup.date().typeError('invalid_date').required().nullable(),
})

export const ProjectBasicInfoForm = ({
  project,
  controls,
  onSubmit,
  apiErrorData,
  hasStatusFields = false,
  isReadOnlyDate = false,
  showControlsOnDirty = false,
}: ProjectBasicInfoFormProps): JSX.Element => {
  const { t } = useTranslation('projects')

  const { data: clients } = useClients(
    {
      limit: 1000,
      offset: 0,
    },
    !project
  )

  const { data: quotations } = useQuotations({
    project_id: project ? `${project.id},null` : `null`,
    limit: 1000,
    offset: 0,
  })

  const resolver = project ? projectBasicInfoScheme : projectCreateScheme

  const defaultValues = useMemo(
    () => ({
      client: null,
      projectNumber: (project && project.project_number) ?? '',
      name: (project && project.name) ?? '',
      description: (project && project.description) ?? '',
      startDate: (project && project.start_date) ?? null,
      endDate: (project && project.end_date) ?? null,
      isSmp: (project && project.is_smp) ?? false,
      isOnlyPlanning: (project && project.is_only_planning) ?? false,
      isFieldReportRequired:
        (project && project.is_field_report_required) ?? true,
      address: (project && project.address) ?? '',
      reportingHoursPerVisit:
        (project && project.reporting_hours_per_visit) ?? '',
      travelTimePerVisit: (project && project.travel_time_per_visit) ?? '',
      hasGisStatus: (project && project.is_in_gis) ?? undefined,
      sharepointUrl: (project && project.sharepoint_url) ?? '',
      status: (project && project.status) ?? ProjectStatus.New,
      quotations:
        project?.quotations?.map(
          (q) =>
            ({
              title: t('labels.quotationOption', {
                id: q.id,
                reference: q.reference,
              }),
              value: q.id,
            } as AutocompleteOption<number>)
        ) ?? [],
      projectLeader:
        project && project.project_leader
          ? ({
              title: `${project.project_leader.first_name} ${project.project_leader.last_name} [${project.project_leader.email}]`,
              value: project.project_leader.id,
            } as AutocompleteOption<number>)
          : null,
    }),
    [project]
  )

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { isDirty },
    reset,
  } = useForm<ProjectBasicInfo>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(resolver),
  })

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues])

  const selectedClient = watch('client')

  useEffect(() => {
    if (selectedClient) {
      setValue(
        'reportingHoursPerVisit',
        selectedClient.value.reporting_hours_per_visit?.toString() ?? ''
      )
      setValue(
        'travelTimePerVisit',
        selectedClient.value.travel_time_per_visit?.toString() ?? ''
      )
    }
  }, [selectedClient])

  const clientOptions: AutocompleteOption<Client>[] = useMemo(() => {
    if (clients && clients.results.length > 0) {
      return clients.results
        .map((client) => ({
          value: client,
          title: client.name,
        }))
        .sort((a, b) =>
          a.title < b.title ? -1 : 1
        ) as AutocompleteOption<Client>[]
    }

    return []
  }, [clients])

  const quotationsOptions: AutocompleteOption<number>[] = useMemo(() => {
    if (quotations && quotations.results.length > 0) {
      return quotations.results.map(
        (q) =>
          ({
            title: t('labels.quotationOption', {
              id: q.id,
              reference: q.reference,
            }),
            value: q.id,
          } as AutocompleteOption<number>)
      )
    }

    return []
  }, [quotations])

  const statusOptions = Object.values(ProjectStatus).map((statusName) => ({
    name: t(`status.${statusName}`, capitalizeFirstLetter(statusName)),
    value: statusName,
  }))

  return (
    <Box data-testid="project-basic-info-form">
      {project && (
        <Stack
          direction="row"
          justifyContent="space-between"
          mt={2}
          maxWidth={isReadOnlyDate ? '800px' : '600px'}
          flexWrap="wrap"
        >
          <CardAttribute
            title={t('titles.clientName')}
            value={project.client_name}
          />

          {isReadOnlyDate && (
            <CardAttribute
              title={t('titles.projectDate')}
              value={formatDateInformation(
                project.start_date,
                project.end_date
              )}
            />
          )}
        </Stack>
      )}
      <form
        onSubmit={handleSubmit(async (data) => {
          onSubmit(data)
        })}
      >
        <Stack spacing={4} my={6}>
          {!project && (
            <Box maxWidth="520px">
              <Typography fontWeight="700" mb={3}>
                {t('placeholders.client')}
              </Typography>
              <ControlledAutocomplete
                placeholder={t('placeholders.client')}
                name="client"
                options={clientOptions}
                control={control}
                multiple={false}
              />
            </Box>
          )}
          <Stack
            maxWidth="520px"
            direction="row"
            justifyContent="space-between"
          >
            <Box width="160px">
              <Typography fontWeight="700" mb={3}>
                {t('placeholders.projectNumber')}
              </Typography>
              <ControlledTextField
                placeholder={t('placeholders.projectNumber')}
                name="projectNumber"
                type="text"
                control={control}
                apiErrorData={apiErrorData}
              />
            </Box>
            <Box width="340px">
              <Typography fontWeight="700" mb={3}>
                {t('placeholders.name')}
              </Typography>
              <ControlledTextField
                placeholder={t('placeholders.name')}
                name="name"
                type="text"
                control={control}
                apiErrorData={apiErrorData}
              />
            </Box>
          </Stack>
          <Box maxWidth="520px">
            <Typography fontWeight="700" mb={3}>
              {t('placeholders.description')}
            </Typography>
            <ControlledTextField
              placeholder={t('placeholders.description')}
              name="description"
              type="text"
              control={control}
              apiErrorData={apiErrorData}
            />
          </Box>
          {!isReadOnlyDate && (
            <Box>
              <Typography fontWeight="700" mb={3}>
                {t('projects:titles.projectDate')}
              </Typography>
              <Stack direction="row">
                <ControlledDateField
                  name="startDate"
                  placeholder={t('forms:placeholders.start-date')}
                  control={control}
                  apiFieldName="startDate"
                  apiErrorData={apiErrorData}
                />
                <Box sx={{ mx: 2, mt: 3 }}>
                  <Typography>{t('titles.to')}</Typography>
                </Box>
                <ControlledDateField
                  name="endDate"
                  placeholder={t('forms:placeholders.end-date')}
                  control={control}
                  apiFieldName="endDate"
                  apiErrorData={apiErrorData}
                  minDate={watch('startDate') ?? undefined}
                />
              </Stack>
            </Box>
          )}
          {hasStatusFields && (
            <>
              <Box data-testid="project-status" width={520}>
                <Typography fontWeight="700">{t('detail.status')}</Typography>
                <ControlledSelect
                  name="status"
                  options={statusOptions}
                  control={control}
                  fullWidth={true}
                />
              </Box>
              <Box data-testid="project-gisStatus">
                <Typography fontWeight="700">
                  {t('detail.gisStatus')}
                </Typography>
                <ControlledCheckboxField
                  name="hasGisStatus"
                  control={control}
                  label={t('labels.gisStatus')}
                />
              </Box>
            </>
          )}
          <Box>
            <Typography fontWeight="700">{t('detail.isSmp')}</Typography>
            <ControlledCheckboxField
              name="isSmp"
              control={control}
              label={t('labels.isSmp')}
            />
          </Box>
          <Box>
            <Typography fontWeight="700">
              {t('detail.isOnlyPlanning')}
            </Typography>
            <ControlledCheckboxField
              name="isOnlyPlanning"
              control={control}
              label={t('labels.isOnlyPlanning')}
            />
          </Box>
          <Box>
            <Typography fontWeight="700">
              {t('detail.isFieldReportRequired')}
            </Typography>
            <ControlledCheckboxField
              name="isFieldReportRequired"
              control={control}
              label={t('labels.isFieldReportRequired')}
            />
          </Box>
          <Box maxWidth="520px">
            <Typography fontWeight="700" mb={3}>
              {t('placeholders.sharepointUrl')}
            </Typography>
            <ControlledTextField
              placeholder={t('placeholders.sharepointUrl')}
              name="sharepointUrl"
              type="text"
              control={control}
              apiErrorData={apiErrorData}
            />
          </Box>
          <Box data-testid="project-address" maxWidth="520px">
            <Typography fontWeight="700" mb={3}>
              {t('placeholders.address')}
            </Typography>
            <ControlledTextField
              placeholder={t('placeholders.address')}
              name="address"
              type="text"
              control={control}
              apiErrorData={apiErrorData}
            />
          </Box>
          <Stack
            maxWidth="520px"
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Box width="250px">
              <Typography fontWeight="700" mb={3}>
                {t('titles.reportingHours')}
              </Typography>
              <ControlledTextField
                placeholder={t('placeholders.reportingHoursPerVisit')}
                name="reportingHoursPerVisit"
                type="text"
                control={control}
                apiErrorData={apiErrorData}
              />
            </Box>
            <Box width="250px">
              <ControlledTextField
                placeholder={t('placeholders.travelTimePerVisit')}
                name="travelTimePerVisit"
                type="text"
                control={control}
                apiErrorData={apiErrorData}
              />
            </Box>
          </Stack>
          <Box width={520}>
            <Typography fontWeight="700" mb={3}>
              {t('detail.linkedQuotations')}
            </Typography>
            <ControlledAutocomplete
              options={quotationsOptions}
              name="quotations"
              control={control}
              multiple={true}
            />
          </Box>
          <Box width={520}>
            <Typography fontWeight="700" mb={3}>
              {t('detail.projectLeader')}
            </Typography>
            <ProjectLeaderAutocomplete name="projectLeader" control={control} />
          </Box>
        </Stack>
        {(showControlsOnDirty && isDirty) || !showControlsOnDirty
          ? controls
          : null}
      </form>
    </Box>
  )
}
