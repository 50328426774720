import * as yup from 'yup'
import { photosSchema } from '@/components/inputs/ControlledMediaUpload/photosValidation'

export const schema = yup.object({
  specie: yup.string().required(),
  amount: yup.string().required(),
  counting_method: yup.string().required(),
  behaviour: yup.string().required(),
  behaviour_notes: yup.string().max(256),
  observed_at: yup.date().required(),
  photos: photosSchema,
})
