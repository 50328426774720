import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Grid, Stack } from '@mui/material'
import { Edit, XSquare } from 'react-feather'
import {
  AuthorizationGuard,
  CardAttribute,
  EntityPhoto,
  LinkButton,
  ModalLayout,
} from '@/components'
import { ObservationSoundRecords } from '@/features'
import {
  renderBehaviour,
  renderObservedSpecie,
  renderStringFromList,
} from '@/features/observationLogs/ObservationLogsTable/ObservationLogsTableBody'
import {
  ObservationLogFormContainer,
  ProcessingStatusForm,
  ProcessingStatusFormData,
} from '@/components/forms'
import {
  getUseObservationLogsKey,
  useModal,
  useMutationWrapper,
  usePostCloneObservationLog,
  usePutObservationLogStatus,
} from '@/hooks'
import dayjs from 'dayjs'
import { getPath } from '@/utils'
import { getSpeciesWithResearchTypesString } from '@/utils/getSpeciesWithResearchTypes'
import {
  ObservationLog,
  PostCloneObservationLog,
  SoundRecord,
  UserGroups,
} from '@/types'
import { DATE_FORMAT_DMY_TIME, PageReferrers, ROUTES_PATH } from '@/constants'

export type ObservationDetailsProps = {
  observationLog: ObservationLog
  showClonedItems: (ids: string) => void
}

export const Observation = ({
  observationLog,
  showClonedItems,
}: ObservationDetailsProps): JSX.Element => {
  const { t } = useTranslation('observationLogs')

  const queryClient = useQueryClient()

  const [data, setData] = useState<ObservationLog>(observationLog)

  const [isObservationLogEdit, setIsObservationLogEdit] = useState(false)

  const { close } = useModal()

  const {
    mutateAsync: updateObservationLogStatus,
    isLoading: mutateStatusInProcess,
  } = useMutationWrapper(
    usePutObservationLogStatus.bind(null, {
      id: data.id,
    })
  )

  const { mutateAsync: cloneObservationLog, isLoading: cloningInProcess } =
    useMutationWrapper(() => usePostCloneObservationLog({ id: data.id }))

  const handleStatusSubmit = async ({
    processingComment,
  }: ProcessingStatusFormData) => {
    const observationLogStatusData = {
      processing_comment: !!processingComment ? processingComment : null,
    }

    await updateObservationLogStatus(observationLogStatusData, {
      successMessageKey:
        'observationLogs:notifications.processingStatusChanged',
      onSuccess: (data) => {
        setData(data)
      },
    })
  }

  const updateSoundRecord = async (
    id: number,
    updatedFields: Partial<SoundRecord>
  ) => {
    setData((prevData) => ({
      ...prevData,
      sound_recordings: prevData.sound_recordings
        ? prevData.sound_recordings.map((recording) =>
            recording.id === id ? { ...recording, ...updatedFields } : recording
          )
        : [],
    }))
    const queryKey = getUseObservationLogsKey()

    await queryClient.invalidateQueries(queryKey)
  }

  const handleEditObservationLog = () => {
    setIsObservationLogEdit(!isObservationLogEdit)
  }

  const handleCloneObservationLog = async (
    cloneLog: PostCloneObservationLog
  ) => {
    if (cloneLog) {
      try {
        const clonedObservationLog = await cloneObservationLog(cloneLog)

        close()
        showClonedItems(`${data.id},${clonedObservationLog.id}`)
      } catch (error) {
        console.error(error)
      }
    }
  }

  return (
    <ModalLayout
      title={t(`observationDetailTitle`, { id: data.id })}
      cardContentSx={{ maxHeight: '70vh', overflowY: 'auto' }}
    >
      <Grid
        container={true}
        rowSpacing={8.25}
        width={{ sm: 'auto', md: 940, lg: 1200 }}
      >
        <Grid xs={12} item={true} container={true} spacing={4}>
          <Grid xs={3} item={true}>
            <CardAttribute
              title={t('observation-log.fieldTitles.projectNumber')}
              value={
                data.research_visit_event?.research_area.project.project_number
              }
            />
          </Grid>
          <Grid xs={6} item={true}>
            <CardAttribute
              title={t('observation-log.fieldTitles.projectName')}
              value={data.research_visit_event?.research_area.project.name}
            />
          </Grid>
          <Grid xs={3} item={true}>
            <CardAttribute
              title={t('observation-log.fieldTitles.clientName')}
              value={
                data.research_visit_event?.research_area.project.client_name
              }
            />
          </Grid>

          <Grid xs={3} item={true}>
            <CardAttribute
              title={t('observation-log.fieldTitles.researchAreaNumber')}
              value={data.research_visit_event?.research_area.id}
            />
          </Grid>
          <Grid xs={9} item={true}>
            <CardAttribute
              title={t('observation-log.fieldTitles.researchAreaLocation')}
              value={data.research_visit_event?.research_area.location_name}
            />
          </Grid>
        </Grid>

        <Grid xs={12} item={true} container={true} spacing={4}>
          <Grid xs={3} item={true}>
            <CardAttribute
              title={t('observation-log.fieldTitles.round')}
              value={
                <AuthorizationGuard
                  allowedGroups={[UserGroups.MANAGER]}
                  redirect={false}
                  fallback={
                    <>{`${data.research_visit_event?.round_type}
                ${data.research_visit_event?.round_number ?? ''}`}</>
                  }
                >
                  <LinkButton
                    url={getPath(ROUTES_PATH.MONITORING_DETAIL, {
                      id: data.research_visit_event.id,
                    })}
                    title={`${data.research_visit_event?.round_type}
                ${data.research_visit_event?.round_number ?? ''}`}
                    referrer={PageReferrers.OBSERVATION}
                    arrowIcon={true}
                    variant="textThin"
                    action={() => close()}
                  />
                </AuthorizationGuard>
              }
            />
          </Grid>
          <Grid xs={9} item={true}>
            <CardAttribute
              title={t('observation-log.fieldTitles.species')}
              value={getSpeciesWithResearchTypesString(
                data.research_visit_event
              )}
            />
          </Grid>
        </Grid>

        <Grid xs={12} item={true} container={true} spacing={4}>
          <Grid xs={3} item={true}>
            <CardAttribute
              title={t('observation-log.fieldTitles.observationDate')}
              value={
                data.observed_at
                  ? dayjs(data.observed_at).format(DATE_FORMAT_DMY_TIME)
                  : '-'
              }
            />
          </Grid>
          <Grid xs={3} item={true}>
            <CardAttribute
              title={t('observation-log.fieldTitles.createdBy')}
              value={`${data.created_by?.first_name ?? '-'} ${
                data.created_by?.last_name ?? ''
              }`}
            />
          </Grid>
          <Grid xs={6} item={true}>
            <CardAttribute
              title={t('observation-log.fieldTitles.researchers')}
              value={
                data.research_visit_event.researchers?.map(
                  ({ first_name, last_name }, i) =>
                    `${i > 0 ? ', ' : ''}${first_name} ${last_name}`
                ) ?? t('observation-log.noResearchers')
              }
            />
          </Grid>
          <Grid xs={3} item={true}>
            <CardAttribute
              title={t('observation-log.fieldTitles.updatedAt')}
              value={
                data.updated_at
                  ? dayjs(data.updated_at).format(DATE_FORMAT_DMY_TIME)
                  : '-'
              }
            />
          </Grid>
          <Grid xs={9} item={true}>
            <CardAttribute
              title={t('observation-log.fieldTitles.updatedBy')}
              value={`${data.edited_by?.first_name ?? '-'} ${
                data.edited_by?.last_name ?? ''
              }`}
            />
          </Grid>
        </Grid>

        <Stack
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
          spacing={4}
          width="100%"
          mt={6}
        >
          <CardAttribute
            title={t('observation-log.fieldTitles.observationType')}
            value={data.research_observation_log_type.name}
          />
          <AuthorizationGuard
            allowedGroups={[
              UserGroups.MANAGER,
              UserGroups.EMPLOYEE,
              UserGroups.SOUND_TECHNICIAN,
            ]}
          >
            <Button
              onClick={handleEditObservationLog}
              variant="textThin"
              endIcon={
                isObservationLogEdit ? (
                  <XSquare size={14} />
                ) : (
                  <Edit size={14} />
                )
              }
            >
              {isObservationLogEdit
                ? t('buttons.closeEditing')
                : t('buttons.editObservationLog')}
            </Button>
          </AuthorizationGuard>
        </Stack>

        {isObservationLogEdit ? (
          <ObservationLogFormContainer
            data={data}
            setUpdatedObservationLog={setData}
            onSuccessUpdate={() => setIsObservationLogEdit(false)}
          />
        ) : (
          <>
            <Grid xs={12} item={true} container={true} spacing={4}>
              {!!data.direction && (
                <Grid xs={3} item={true}>
                  <CardAttribute
                    title={t('observation-log.fieldTitles.direction')}
                    value={data.direction}
                  />
                </Grid>
              )}
              {!!data.wind_direction && (
                <Grid xs={3} item={true}>
                  <CardAttribute
                    title={t('observation-log.fieldTitles.windDirection')}
                    value={data.wind_direction}
                  />
                </Grid>
              )}
              {!!data.recording_number && (
                <Grid xs={3} item={true}>
                  <CardAttribute
                    title={t('observation-log.fieldTitles.recordingNumber')}
                    value={data.recording_number}
                  />
                </Grid>
              )}
              {data.amount_per_15_min !== null && (
                <Grid xs={3} item={true}>
                  <CardAttribute
                    title={t('observation-log.fieldTitles.amountPer15Min')}
                    value={data.amount_per_15_min}
                  />
                </Grid>
              )}
              {!!data.first_fledgling_time && (
                <Grid xs={3} item={true}>
                  <CardAttribute
                    title={t('observation-log.fieldTitles.firstFledglingTime')}
                    value={dayjs(data.first_fledgling_time).format(
                      DATE_FORMAT_DMY_TIME
                    )}
                  />
                </Grid>
              )}
            </Grid>

            <Grid xs={12} item={true} container={true} spacing={4}>
              <Grid xs={data.specie2 ? 6 : 3} item={true}>
                <CardAttribute
                  title={t('observation-log.fieldTitles.foundSpecies')}
                  value={renderObservedSpecie(
                    data.specie,
                    data.specie2,
                    data.specie3,
                    data.specie4,
                    data.other_specie
                  )}
                />
              </Grid>
              <Grid xs={3} item={true}>
                <CardAttribute
                  title={t('observation-log.fieldTitles.amount')}
                  value={renderStringFromList(
                    data.amount,
                    data.amount2,
                    data.amount3,
                    data.amount4
                  )}
                />
              </Grid>
              <Grid xs={data.specie2 ? 3 : 6} item={true}>
                <CardAttribute
                  title={t('observation-log.fieldTitles.countingMethod')}
                  value={renderStringFromList(
                    data.counting_method,
                    data.counting_method2,
                    data.counting_method3,
                    data.counting_method4
                  )}
                />
              </Grid>
              <Grid xs={3} item={true}>
                <CardAttribute
                  title={t('observation-log.fieldTitles.behaviour')}
                  value={renderBehaviour(data.behaviour, data.other_behaviour)}
                />
              </Grid>
              {!!data.behaviour_notes && (
                <Grid xs={9} item={true}>
                  <CardAttribute
                    title={t('observation-log.fieldTitles.behaviourNotes')}
                    value={data.behaviour_notes}
                  />
                </Grid>
              )}
              {data.is_place_found && (
                <Grid xs={3} item={true}>
                  <CardAttribute
                    title={t('observation-log.fieldTitles.placeType')}
                    value={data.place_type}
                  />
                </Grid>
              )}
              {!!data.place_type_notes && (
                <Grid xs={6} item={true}>
                  <CardAttribute
                    title={t('observation-log.fieldTitles.placeTypeNotes')}
                    value={data.place_type_notes}
                  />
                </Grid>
              )}
            </Grid>
            {!!data.notes && (
              <Grid xs={12} item={true}>
                <CardAttribute
                  title={t('observation-log.fieldTitles.notes')}
                  value={data.notes}
                />
              </Grid>
            )}
            {!!data.photos?.length && (
              <Grid xs={12} item={true} container={true} spacing={4}>
                {data.photos.map((photo) => (
                  <EntityPhoto photo={photo} key={photo.id} />
                ))}
              </Grid>
            )}
            {!!data.sound_recordings?.length && (
              <Grid xs={12} item={true}>
                <ObservationSoundRecords
                  observationLog={data}
                  updateSoundRecord={updateSoundRecord}
                  handleCloneObservationLog={handleCloneObservationLog}
                  cloningInProcess={cloningInProcess}
                />
              </Grid>
            )}
          </>
        )}

        <AuthorizationGuard
          allowedGroups={[UserGroups.MANAGER]}
          redirect={false}
        >
          <Grid item={true} sm={12}>
            <CardAttribute
              title={t('observation-log.fieldTitles.processingStatus')}
              value={
                <ProcessingStatusForm
                  defaultValues={{
                    processingComment: data.processing_comment ?? '',
                  }}
                  onSubmit={handleStatusSubmit}
                  isLoading={mutateStatusInProcess}
                />
              }
            />
          </Grid>
        </AuthorizationGuard>
      </Grid>
    </ModalLayout>
  )
}
