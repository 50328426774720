import { SearchAutocomplete } from '@/components/inputs'
import { capitalizeFirstLetter } from '@/utils'
import { ProjectStatus } from '@/types'
import { useTranslation } from 'react-i18next'

type ProjectStatusMultiSelectProps = {
  value?: string
  onChange: (value: string) => void
  placeholder: string
}

export const ProjectStatusMultiSelect = ({
  value,
  onChange,
  placeholder,
}: ProjectStatusMultiSelectProps): JSX.Element => {
  const { t } = useTranslation('projects')

  const statusOptions = Object.values(ProjectStatus).map((statusName) => ({
    label: t(`status.${statusName}`, capitalizeFirstLetter(statusName)),
    value: statusName,
  }))

  return (
    <SearchAutocomplete
      value={value}
      onChange={onChange}
      options={statusOptions}
      sort={true}
      placeholder={placeholder}
      fieldSx={{ width: 340 }}
    />
  )
}
