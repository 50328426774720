import { CircularProgress, Stack } from '@mui/material'
import { useSnackbar } from 'notistack'
import {
  useProject,
  usePatchProject,
  useMutationWrapper,
  useStepperContext,
  useLinkQuotationToProject,
} from '@/hooks'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CardLayout } from '@/components/layouts/CardLayout'
import { ProjectBasicInfoForm, ProjectBasicInfo } from '@/components/forms'
import dayjs from 'dayjs'
import { DATE_FORMAT_YMD, ROUTES_PATH } from '@/constants'
import { FooterActions } from '@/features'

type ProjectStepProps = {
  projectId: number
}

export const ProjectStep = ({ projectId }: ProjectStepProps): JSX.Element => {
  const { t } = useTranslation()

  const { enqueueSnackbar } = useSnackbar()

  const { stepForward } = useStepperContext()

  const navigate = useNavigate()

  const { data: project, isLoading } = useProject({ id: projectId })

  const { mutateAsync: extendProject, isLoading: mutateInProcess } =
    useMutationWrapper(usePatchProject.bind(null, projectId))

  const linkQuotations = useLinkQuotationToProject()

  const handleSubmit = async ({
    projectLeader,
    projectNumber,
    name,
    description,
    startDate,
    endDate,
    isSmp,
    isOnlyPlanning,
    isFieldReportRequired,
    sharepointUrl,
    address,
    reportingHoursPerVisit,
    travelTimePerVisit,
    quotations,
  }: ProjectBasicInfo) => {
    const projectExtendedData = {
      project_leader_id: projectLeader?.value || null,
      project_number: projectNumber,
      name,
      description,
      start_date: dayjs(startDate).format(DATE_FORMAT_YMD),
      end_date: dayjs(endDate).format(DATE_FORMAT_YMD),
      is_smp: isSmp,
      is_only_planning: isOnlyPlanning,
      is_field_report_required: isFieldReportRequired,
      sharepoint_url: sharepointUrl,
      address,
      reporting_hours_per_visit: reportingHoursPerVisit,
      travel_time_per_visit: travelTimePerVisit,
    }

    try {
      const project = await extendProject(projectExtendedData)

      await linkQuotations(project, quotations)

      stepForward()
    } catch (e) {
      enqueueSnackbar(`${t('errors:api.execute')}: ${e}`, {
        variant: 'error',
      })
    }
  }

  if (isLoading || !project)
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        <CircularProgress size="5rem" color="secondary" />
      </Stack>
    )

  return (
    <CardLayout title={t('projects:titles.projectInfo')} cardSx={{ pb: 0 }}>
      <ProjectBasicInfoForm
        project={project}
        onSubmit={handleSubmit}
        controls={
          <FooterActions
            isNextStepDisabled={false}
            mutateInProcess={mutateInProcess}
            onCancel={() => navigate(ROUTES_PATH.PROJECTS_OVERVIEW)}
          />
        }
      />
    </CardLayout>
  )
}
