import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { useApiClient } from '@/hooks'
import { unwrapErrorResponse } from '@/utils'
import { APIErrorResponse, DeleteQuotationOverheadLine } from '@/types'

export const useDeleteQuotationOverheadLine = (
  quotationId: number
): UseMutationResult<
  unknown,
  APIErrorResponse,
  DeleteQuotationOverheadLine
> => {
  const client = useApiClient()

  return useMutation((params) =>
    client
      .delete(`/quotations/${quotationId}/research_overhead/${params.id}/`)
      .catch(unwrapErrorResponse)
  )
}
