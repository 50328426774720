import { useState, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  IconButton,
  Collapse,
  Checkbox,
  Stack,
} from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Copy, XSquare } from 'react-feather'
import { ObservationSoundRecordDetails } from '@/features'
import { AuthorizationGuard } from '@/components'
import {
  ObservationLog,
  PostCloneObservationLog,
  SoundRecord,
  UserGroups,
} from '@/types'
import { getFileNameFromUrl } from '@/utils'
import { LoadingButton } from '@mui/lab'
import { theme } from '@/theme'

export type EntitySoundRecordsProps = {
  observationLog: ObservationLog
  updateSoundRecord?: (id: number, updatedFields: Partial<SoundRecord>) => void
  handleCloneObservationLog: (items: PostCloneObservationLog) => void
  cloningInProcess: boolean
}

const columnNames = [
  'id',
  'fileName',
  'createdBy',
  'analysed',
  'analysedBy',
  'specieIdentified',
]

export const ObservationSoundRecords = ({
  observationLog,
  updateSoundRecord,
  handleCloneObservationLog,
  cloningInProcess,
}: EntitySoundRecordsProps): JSX.Element => {
  const { t } = useTranslation()

  const [openedSoundRecords, setOpenedSoundRecords] = useState<number[]>([])

  const [cloneObservationLog, setCloneObservationLog] = useState<
    PostCloneObservationLog | undefined
  >()

  const handleCheckboxChange = (id: number, type: 'move' | 'copy') => {
    if (!cloneObservationLog) return
    const idStr = id.toString()

    setCloneObservationLog((prev) => {
      if (!prev) return prev

      const isMove = type === 'move'

      const sourceList = isMove
        ? prev.move_sound_recording_ids
        : prev.copy_sound_recording_ids

      const toggledList = sourceList.includes(idStr)
        ? sourceList.filter((i) => i !== idStr)
        : [...sourceList, idStr]

      const otherList = isMove
        ? prev.copy_sound_recording_ids.filter((i) => i !== idStr)
        : prev.move_sound_recording_ids.filter((i) => i !== idStr)

      return {
        move_sound_recording_ids: isMove ? toggledList : otherList,
        copy_sound_recording_ids: isMove ? otherList : toggledList,
      }
    })
  }

  const toggleSoundRecordDetails = (id: number): void => {
    setOpenedSoundRecords((list) =>
      openedSoundRecords.includes(id)
        ? list.filter((areaId) => areaId !== id)
        : [...list, id]
    )
  }

  return (
    <>
      <AuthorizationGuard
        allowedGroups={[UserGroups.SOUND_TECHNICIAN]}
        redirect={false}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={cloneObservationLog ? 'space-between' : 'flex-end'}
          spacing={4}
          width="100%"
          mt={6}
          mb={2}
        >
          {cloneObservationLog && (
            <LoadingButton
              variant="contained"
              color="secondary"
              type="submit"
              loading={cloningInProcess}
              onClick={() => handleCloneObservationLog(cloneObservationLog)}
              disabled={
                !(
                  cloneObservationLog &&
                  (cloneObservationLog.move_sound_recording_ids.length ||
                    cloneObservationLog.copy_sound_recording_ids.length)
                ) || cloningInProcess
              }
            >
              <span>{t('soundRecords:buttons.moveOrCopyRecords')}</span>
            </LoadingButton>
          )}

          <Button
            onClick={() =>
              setCloneObservationLog(
                cloneObservationLog
                  ? undefined
                  : {
                      move_sound_recording_ids: [],
                      copy_sound_recording_ids: [],
                    }
              )
            }
            variant="textThin"
            endIcon={
              cloneObservationLog ? <XSquare size={14} /> : <Copy size={14} />
            }
          >
            {cloneObservationLog
              ? t('soundRecords:buttons.cancelCloningObservationLog')
              : t('soundRecords:buttons.cloneObservationLog')}
          </Button>
        </Stack>
      </AuthorizationGuard>

      <Table>
        <TableHead>
          <TableRow>
            {cloneObservationLog && (
              <>
                <TableCell sx={{ backgroundColor: 'white' }}>
                  {t('soundRecords:buttons.move')}
                </TableCell>
                <TableCell sx={{ backgroundColor: 'white' }}>
                  {t('soundRecords:buttons.copy')}
                </TableCell>
              </>
            )}
            {columnNames.map((columnName) => (
              <TableCell key={columnName} sx={{ backgroundColor: 'white' }}>
                {t(`soundRecords:columnNames.${columnName}`)}
              </TableCell>
            ))}
            <TableCell sx={{ backgroundColor: 'white' }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {observationLog.sound_recordings?.map((sr, index) => (
            <Fragment key={index}>
              <TableRow
                sx={{
                  backgroundColor: openedSoundRecords.includes(sr.id)
                    ? theme.palette.green[100]
                    : theme.palette.background.paper,
                  '& > *': {
                    fontWeight: openedSoundRecords.includes(sr.id)
                      ? theme.typography.fontWeightBold
                      : theme.typography.fontWeightRegular,
                  },
                }}
              >
                {cloneObservationLog && (
                  <>
                    <TableCell>
                      <Checkbox
                        checked={cloneObservationLog.move_sound_recording_ids.includes(
                          sr.id.toString()
                        )}
                        onChange={() => handleCheckboxChange(sr.id, 'move')}
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={cloneObservationLog.copy_sound_recording_ids.includes(
                          sr.id.toString()
                        )}
                        onChange={() => handleCheckboxChange(sr.id, 'copy')}
                      />
                    </TableCell>
                  </>
                )}
                <TableCell>{sr.id}</TableCell>
                <TableCell>
                  <a href={sr.link} target="_blank" rel="noreferrer">
                    {getFileNameFromUrl(sr.link)}
                  </a>
                </TableCell>
                <TableCell>
                  {sr.created_by
                    ? `${sr.created_by.first_name} ${sr.created_by.last_name}`
                    : '-'}
                </TableCell>
                <TableCell>
                  {sr.analysed
                    ? t('soundRecords:sound-record.yes')
                    : t('soundRecords:sound-record.no')}
                </TableCell>
                <TableCell>
                  {sr.analysed_by
                    ? `${sr.analysed_by.first_name} ${sr.analysed_by.last_name}`
                    : '-'}
                </TableCell>
                <TableCell>{sr.specie_identified}</TableCell>
                <TableCell>
                  <IconButton
                    size="small"
                    onClick={() => toggleSoundRecordDetails(sr.id)}
                  >
                    {openedSoundRecords.includes(sr.id) ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  colSpan={9}
                  sx={{ p: 0, backgroundColor: theme.palette.background.paper }}
                >
                  <Collapse
                    in={openedSoundRecords.includes(sr.id)}
                    timeout="auto"
                    unmountOnExit={true}
                  >
                    {openedSoundRecords.includes(sr.id) && (
                      <ObservationSoundRecordDetails
                        researchObservationLogType={
                          observationLog.research_observation_log_type.code
                        }
                        soundRecord={sr}
                        updateSoundRecord={updateSoundRecord}
                      />
                    )}
                  </Collapse>
                </TableCell>
              </TableRow>
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </>
  )
}
