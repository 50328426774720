import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { useQueryClient } from '@tanstack/react-query'
import { Box, Button, Stack } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { SelectOption } from '@/components'
import { ControlledSelect } from '@/components/inputs'
import {
  useMutationWrapper,
  usePatchQuotation,
  getUseQuotationDetailsKey,
} from '@/hooks'
import { Quotation, QuotationStatus, QuotationStatuses } from '@/types'

export type QuotationStatusFormData = {
  status: QuotationStatus
}

export type QuotationStatusFormProps = {
  quotation: Quotation
}

export const QuotationStatusFormContainer = ({
  quotation,
}: QuotationStatusFormProps): JSX.Element => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const { mutateAsync: editQuotation, isLoading: mutateInProcess } =
    useMutationWrapper(() => usePatchQuotation(quotation.id))

  const defaultValues = useMemo(
    () => ({
      status: quotation.status,
    }),
    [quotation.status]
  )

  const methods = useForm<QuotationStatusFormData>({
    mode: 'onChange',
    defaultValues,
  })

  useEffect(() => {
    methods.reset(defaultValues)
  }, [defaultValues])

  const handleSubmitForm = async ({ status }: QuotationStatusFormData) => {
    enqueueSnackbar('Saving...', {
      variant: 'default',
    })

    const quotationData = { status }

    try {
      await editQuotation(quotationData)

      const quotationQueryKey = getUseQuotationDetailsKey(quotation.id)

      await queryClient.invalidateQueries(quotationQueryKey)
    } catch (e) {
      enqueueSnackbar(`${t('errors:api.execute')}: ${e}`, {
        variant: 'error',
      })
    }
  }

  const handleReset = () =>
    methods.reset({
      ...defaultValues,
    })

  const statusOptions = Object.keys(QuotationStatuses).map((key) => {
    const status = QuotationStatuses[
      key as keyof typeof QuotationStatuses
    ] as string

    return {
      name: t(`forms:quotationStatus.${status}`),
      value: status,
    } as SelectOption
  })

  return (
    <form onSubmit={methods.handleSubmit(handleSubmitForm)}>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={4}
      >
        <Box width={160}>
          <ControlledSelect
            name="status"
            control={methods.control}
            options={statusOptions}
            errorMessagePrefix=""
            label={t('quotations:titles.quotationStatus')}
          />
        </Box>
        {methods.formState.isDirty && (
          <>
            <Button variant="textThin" color="secondary" onClick={handleReset}>
              {t('forms:buttons.cancel')}
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              color="secondary"
              loading={mutateInProcess}
              disabled={mutateInProcess}
              disableElevation={true}
            >
              <span>{t('forms:buttons.save')}</span>
            </LoadingButton>
          </>
        )}
      </Stack>
    </form>
  )
}
