import { useTranslation } from 'react-i18next'
import {
  Button,
  Icon,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { HoursReportingTableProps } from '@/features'
import { Indicator } from '@/components/ui'
import dayjs from 'dayjs'
import { DATE_FORMAT_DMY } from '@/constants'

export const HoursReportingTableBody = ({
  rows,
  showHoursReportDetails,
}: HoursReportingTableProps): JSX.Element => {
  const { t } = useTranslation('hoursReporting')

  return (
    <>
      <TableBody>
        {rows?.map(({ id, researcher, created_at, status }) => (
          <TableRow key={id}>
            <TableCell>{id}</TableCell>
            <TableCell>{`${researcher.first_name} ${researcher.last_name}`}</TableCell>
            <TableCell>
              {created_at ? dayjs(created_at).format(DATE_FORMAT_DMY) : '-'}
            </TableCell>
            <TableCell>
              <Indicator isSmall={false} status={status} name={status} />
            </TableCell>
            <TableCell align="right">
              <Button
                variant="text"
                onClick={() => showHoursReportDetails(id)}
                endIcon={
                  <Icon
                    sx={{
                      fontSize: 14,
                      lineHeight: '21px',
                      fontWeight: 600,
                    }}
                  >
                    <ArrowForwardIcon sx={{ height: 14, width: 14 }} />
                  </Icon>
                }
              >
                <Typography variant="body2" fontWeight={600}>
                  {status === 'invalid'
                    ? t('buttons.validateData')
                    : t('buttons.view')}
                </Typography>
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </>
  )
}
