import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { useQueryClient } from '@tanstack/react-query'
import { Stack, Typography, Link } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import {
  useMutationWrapper,
  usePostProjectFromQuotation,
  getUseQuotationDetailsKey,
} from '@/hooks'
import { getPath } from '@/utils'
import { Quotation } from '@/types'
import { NEW_PROJECT_STATUSES, ROUTES_PATH } from '@/constants'

export type ProjectCreateFromQuotationProps = {
  quotation: Quotation
}

export const ProjectCreateFromQuotation = ({
  quotation,
}: ProjectCreateFromQuotationProps): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const queryClient = useQueryClient()

  const { mutateAsync: createProject, isLoading: mutateInProcess } =
    useMutationWrapper(() => usePostProjectFromQuotation(quotation.id))

  const handleSubmit = async () => {
    enqueueSnackbar('Generating...', {
      variant: 'default',
    })

    try {
      const project = await createProject(undefined)

      const quotationQueryKey = getUseQuotationDetailsKey(quotation.id)

      await queryClient.invalidateQueries(quotationQueryKey)

      const showProjectDetail = project.status
        ? !NEW_PROJECT_STATUSES.includes(project.status)
        : false

      const projectExtendUrl = getPath(
        showProjectDetail
          ? ROUTES_PATH.PROJECT_DETAIL
          : ROUTES_PATH.PROJECT_EXTEND,
        { id: project.id }
      )

      navigate(`/${projectExtendUrl}?step=1`)
    } catch (e) {
      enqueueSnackbar(`${t('errors:api.execute')}: ${e}`, {
        variant: 'error',
      })
    }
  }

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={4}
      my={4}
    >
      <Typography>{t('quotations:titles.projectBasedOnQuotation')}</Typography>
      {quotation.project_id ? (
        <Link
          href={`/${getPath(ROUTES_PATH.PROJECT_EXTEND, {
            id: quotation.project_id,
          })}?step=1`}
          target="_blank"
          rel="noopener noreferrer"
          color="primary.light"
        >
          {t('quotations:buttons.linkToProject')}
        </Link>
      ) : (
        <LoadingButton
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          loading={mutateInProcess}
          disabled={mutateInProcess}
          disableElevation={true}
        >
          <span>{t('quotations:buttons.createProject')}</span>
        </LoadingButton>
      )}
    </Stack>
  )
}
