import { useState } from 'react'
import { useApiClient } from '@/hooks'

export type HoursReportsExportParams = {
  contractor?: string
  startDate?: string
  endDate?: string
}

export const useHoursReportsExport = () => {
  const client = useApiClient()

  const [loading, setLoading] = useState(false)

  const exportReport = async ({
    contractor,
    startDate,
    endDate,
  }: HoursReportsExportParams) => {
    const queryParams = new URLSearchParams()

    if (contractor) queryParams.append('contractor', contractor)
    if (startDate) queryParams.append('date_start', startDate)
    if (endDate) queryParams.append('date_end', endDate)

    setLoading(true)

    try {
      const response = await client.get<Blob>(
        `/hours_reporting/hr/export/?${queryParams.toString()}`,
        { responseType: 'blob' }
      )

      const fileName = extractFileName(response.headers['content-disposition'])

      saveAs(response.data, fileName)
    } finally {
      setLoading(false)
    }
  }

  return { exportReport, loading }
}

const extractFileName = (contentDispositionValue: string) => {
  let filename = ''

  if (
    contentDispositionValue &&
    contentDispositionValue.indexOf('attachment') !== -1
  ) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    const matches = filenameRegex.exec(contentDispositionValue)

    if (matches !== null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '')
    }
  }

  return filename
}

const saveAs = (data: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')

  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
}
