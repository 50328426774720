import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Stack,
  Typography,
  IconButton,
  Pagination,
  Button,
} from '@mui/material'
import { X } from 'react-feather'
import {
  HoursReportingTable,
  HoursReport,
  HoursReportsExportForm,
} from '@/features'
import { Loader } from '@/components'
import {
  SearchTextField,
  SearchDateField,
  ResearchersMultiSelect,
  HoursReportStatusMultiSelect,
  ContractorsMultiSelect,
} from '@/components/inputs'
import { EmptyListData } from '@/components/ui'
import {
  useModal,
  useFilters,
  useHoursReports,
  HoursReportsSearchParams,
} from '@/hooks'
import { DEFAULT_LIST_LIMIT, DEFAULT_LIST_OFFSET } from '@/constants'
import { theme } from '@/theme'
import { PaginatedListResponse, HoursReport as HoursReportType } from '@/types'

export const HoursReportingContainer = (): JSX.Element => {
  const { t } = useTranslation('hoursReporting')
  const { open } = useModal()

  const defaultFiltersParams: HoursReportsSearchParams = {
    id: undefined,
    researchers: undefined,
    contractors: undefined,
    status: undefined,
    startDate: undefined,
    endDate: undefined,
    limit: DEFAULT_LIST_LIMIT,
    offset: DEFAULT_LIST_OFFSET,
  }

  const openHoursReport = (id: number) => {
    if (data && data.results) {
      const index = data.results.findIndex((row) => row.id === id)

      if (index !== -1) {
        open(<HoursReport hoursReportData={data.results[index]} />)
      }
    }
  }

  const {
    filterParams,
    handleFieldValueChange,
    handleResetFilters,
    initPagesCount,
    pagesCount,
    page,
    onPageChange,
    openDetail,
  } = useFilters<
    HoursReportsSearchParams,
    PaginatedListResponse<HoursReportType>,
    HoursReportType
  >(defaultFiltersParams)

  const { data, isLoading } = useHoursReports(filterParams)

  useEffect(() => {
    if (data && data.count) {
      initPagesCount(data?.count)

      const detailItemId = openDetail()

      if (detailItemId) {
        openHoursReport(detailItemId)
      }
    }
  }, [data])

  return (
    <Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          mb={4}
        >
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            spacing={4}
          >
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              alignItems={{ xs: 'flex-start', md: 'center' }}
              justifyContent="flex-start"
              spacing={4}
              width="100%"
            >
              <SearchTextField
                searchValue={filterParams.id as string}
                onChange={(value) => handleFieldValueChange(value, 'id')}
                placeholder="ID"
                searchIcon={false}
                width="80px"
              />
              <ResearchersMultiSelect
                value={filterParams.researchers as string}
                onChange={(value) =>
                  handleFieldValueChange(value, 'researchers')
                }
                placeholder={t('placeholders.researchers')}
              />
              <HoursReportStatusMultiSelect
                value={filterParams.status as string}
                onChange={(value) => handleFieldValueChange(value, 'status')}
              />
            </Stack>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              alignItems={{ xs: 'flex-start', md: 'center' }}
              justifyContent="flex-start"
              spacing={4}
              width="100%"
            >
              <ContractorsMultiSelect
                value={filterParams.contractors as string}
                onChange={(value) =>
                  handleFieldValueChange(value, 'contractors')
                }
                placeholder={t('placeholders.contractors')}
              />
              <SearchDateField
                searchStartValue={filterParams.startDate as string}
                searchEndValue={filterParams.endDate as string}
                onChangeStartDate={(date) =>
                  handleFieldValueChange(date, 'startDate')
                }
                onChangeEndDate={(date) =>
                  handleFieldValueChange(date, 'endDate')
                }
              />
            </Stack>
          </Stack>
          <Typography color="green.600">
            {t('amount', { amount: data?.count ?? 0 })}
          </Typography>
          <IconButton
            onClick={handleResetFilters}
            title={t('buttons.resetFilters')}
          >
            <X size="16px" color={theme.palette.green['500']} />
          </IconButton>
        </Stack>
        <Button onClick={() => open(<HoursReportsExportForm />)}>
          {t('buttons.exportReports')}
        </Button>
      </Stack>

      {data?.count ? (
        <>
          <HoursReportingTable
            rows={data.results}
            showHoursReportDetails={openHoursReport}
          />

          {pagesCount > 1 && (
            <Box alignSelf="center" mt={16}>
              <Pagination
                count={pagesCount}
                page={page}
                onChange={onPageChange}
              />
            </Box>
          )}
        </>
      ) : (
        <Box my={20}>
          {isLoading && <Loader />}

          {!isLoading && !data?.count && (
            <EmptyListData textKey="hoursReporting:noHoursReports" />
          )}
        </Box>
      )}
    </Stack>
  )
}
