import { useContext } from 'react'
import { StepperContext, StepperContextType } from '@/contexts/StepperContext'

export const useStepperContext = (): StepperContextType => {
  const context = useContext(StepperContext)

  if (!context) {
    throw new Error('StepperContext is used outside Provider!')
  }

  return context
}
