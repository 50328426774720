import { VisitLog } from '@/types'
import { Stack, Grid, Typography } from '@mui/material'
import { DATE_FORMAT_DMY } from '@/constants'
import dayjs from 'dayjs'
import { CardAttribute } from '@/components'
import { useTranslation } from 'react-i18next'
import { InvalidDataInfo } from '@/features'

export type PlanningDataProps = Pick<
  VisitLog,
  'start_datetime' | 'end_datetime' | 'invalid_variables'
>

const protocolPlanningVariables = [
  'date_range',
  'time_range',
  'start_time_relative_to_sunset',
  'end_time_relative_to_sunrise',
  'visit_duration',
  'period_between_visits',
  'touch_time',
]

export const ResearchEventDate = ({
  start_datetime,
  end_datetime,
  invalid_variables,
}: PlanningDataProps): JSX.Element => {
  const { t } = useTranslation('dataValidation')

  const invalidData = protocolPlanningVariables.filter((key) =>
    invalid_variables?.includes(key)
  )

  return (
    <>
      <Grid xs={3} item={true}>
        <CardAttribute
          title={t('visit-log.fieldTitles.observationStartTime')}
          value={
            <Stack direction="row" alignItems="center">
              {!!invalidData.length && (
                <InvalidDataInfo
                  title={t(`visit-log.fieldTitles.invalidProtocolVariables`)}
                  invalidData={invalidData.map((item) =>
                    t(`visit-log.planningData.${item}`)
                  )}
                />
              )}
              <Typography
                sx={invalidData.length ? { color: 'error.main' } : {}}
              >
                {start_datetime
                  ? dayjs(start_datetime)
                      .format(`${DATE_FORMAT_DMY} HH:mm`)
                      .toString()
                  : '-'}
              </Typography>
            </Stack>
          }
        />
      </Grid>
      <Grid xs={3} item={true}>
        <CardAttribute
          title={t('visit-log.fieldTitles.observationEndTime')}
          value={
            <Typography sx={invalidData.length ? { color: 'error.main' } : {}}>
              {end_datetime
                ? dayjs(end_datetime)
                    .format(`${DATE_FORMAT_DMY} HH:mm`)
                    .toString()
                : '-'}
            </Typography>
          }
        />
      </Grid>
    </>
  )
}
