import {
  Quotation,
  PaginatedListResponse,
  SearchRequestParams,
  APIErrorResponse,
} from '@/types'
import { UseQueryResult } from '@tanstack/react-query'
import { useQueryList } from '../useQueryList'
import { QUERY_KEYS } from '@/constants'

export type UseQuotationsParams = SearchRequestParams & {
  status?: string
  project_leader?: string
  project_id?: string
}

export const useQuotations = ({
  ...params
}: UseQuotationsParams): UseQueryResult<
  PaginatedListResponse<Quotation>,
  APIErrorResponse
> =>
  useQueryList({
    key: [...QUERY_KEYS.QUOTATIONS, params],
    url: '/quotations/',
    params: {
      ...(!!params.project_id && { project_id: params.project_id }),
      ...(!!params.status && { status: params.status }),
      ...(!!params.project_leader && { project_leader: params.project_leader }),
      ...(!!params.search && { search: params.search }),
      limit: params.limit,
      offset: params.offset,
    },
  })
