import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, Grid, Button } from '@mui/material'
import { CardAttribute, ModalLayout } from '@/components'
import { Indicator } from '@/components/ui'
import { useMutationWrapper, usePostHoursReportApprove } from '@/hooks'
import { HoursReport as HoursReportType } from '@/types'
import {
  HoursReportItem,
  HourReportParams,
} from '@/features/hoursReporting/HoursReport/HoursReportItem'

export type HoursReportDetailsProps = {
  hoursReportData: HoursReportType
}

export const HoursReport = ({
  hoursReportData,
}: HoursReportDetailsProps): JSX.Element => {
  const { t } = useTranslation('hoursReporting')
  const [data, setData] = useState<HoursReportType>(hoursReportData)

  const { mutateAsync: approveValidation } = useMutationWrapper(() =>
    usePostHoursReportApprove({ id: hoursReportData.id })
  )

  const handleApproveValidation = async () => {
    await approveValidation(undefined, {
      successMessageKey: 'hoursReporting:notifications.approved',
      onSuccess: (data) => {
        setData(data)
      },
    })
  }

  return (
    <ModalLayout
      title={t('reportDetailTitle', { id: data.id })}
      headerAction={
        data.status === 'invalid' ? (
          <Button onClick={handleApproveValidation}>
            {t('hours-report.buttons.approveValidation')}
          </Button>
        ) : null
      }
    >
      <Grid container={true} rowSpacing={5} width={{ md: 925, sm: 'auto' }}>
        <>
          {!data ? (
            <Typography>{t('hours-report.noData')}</Typography>
          ) : (
            <>
              <Grid xs={12} item={true} container={true}>
                <Grid xs={12} item={true} container={true}>
                  <Grid xs={3} item={true}>
                    <CardAttribute
                      title={t('hours-report.fieldTitles.researchTimeMinutes')}
                      value={
                        <HoursReportItem
                          name={HourReportParams.RESEARCH_TIME}
                          value={data.research_time_minutes}
                          invalidVariables={data.invalid_variables}
                        />
                      }
                      mt={4}
                    />
                  </Grid>
                  {!!data.research_time_comment && (
                    <Grid xs={9} item={true}>
                      <CardAttribute
                        title={t(
                          'hours-report.fieldTitles.researchTimeComment'
                        )}
                        value={data.research_time_comment}
                        mt={4}
                      />
                    </Grid>
                  )}
                </Grid>

                <Grid xs={12} item={true} container={true}>
                  <Grid xs={3} item={true}>
                    <CardAttribute
                      title={t('hours-report.fieldTitles.travelTimeMinutes')}
                      value={
                        <HoursReportItem
                          name={HourReportParams.TRAVEL_TIME}
                          value={data.travel_time_minutes}
                          invalidVariables={data.invalid_variables}
                        />
                      }
                      mt={4}
                    />
                  </Grid>
                  {!!data.travel_time_comment && (
                    <Grid xs={9} item={true}>
                      <CardAttribute
                        title={t('hours-report.fieldTitles.travelTimeComment')}
                        value={data.travel_time_comment}
                        mt={4}
                      />
                    </Grid>
                  )}
                </Grid>

                <Grid xs={12} item={true} container={true}>
                  <Grid xs={3} item={true}>
                    <CardAttribute
                      title={t('hours-report.fieldTitles.reportingTimeMinutes')}
                      value={
                        <HoursReportItem
                          name={HourReportParams.REPORTING_TIME}
                          value={data.reporting_time_minutes}
                          invalidVariables={data.invalid_variables}
                        />
                      }
                      mt={4}
                    />
                  </Grid>
                  {!!data.reporting_time_comment && (
                    <Grid xs={9} item={true}>
                      <CardAttribute
                        title={t(
                          'hours-report.fieldTitles.reportingTimeComment'
                        )}
                        value={data.reporting_time_comment}
                        mt={4}
                      />
                    </Grid>
                  )}
                </Grid>

                <Grid xs={12} item={true} container={true}>
                  <Grid xs={3} item={true}>
                    <CardAttribute
                      title={t('hours-report.fieldTitles.travelDistanceKm')}
                      value={
                        <HoursReportItem
                          name={HourReportParams.TRAVEL_DISTANCE}
                          value={data.travel_distance_km}
                          invalidVariables={data.invalid_variables}
                        />
                      }
                      mt={4}
                    />
                  </Grid>
                  {!!data.travel_distance_comment && (
                    <Grid xs={9} item={true}>
                      <CardAttribute
                        title={t(
                          'hours-report.fieldTitles.travelDistanceComment'
                        )}
                        value={data.travel_distance_comment}
                        mt={4}
                      />
                    </Grid>
                  )}
                </Grid>

                <Grid xs={12} item={true}>
                  <CardAttribute
                    title={t('hours-report.fieldTitles.status')}
                    value={
                      <Indicator
                        isSmall={false}
                        status={data.status}
                        name={data.status}
                      />
                    }
                    mt={4}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </>
      </Grid>
    </ModalLayout>
  )
}
