import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { useApiClient } from '@/hooks'
import { unwrapErrorResponse, unwrapResponse } from '@/utils/unwrapResponse'
import { QuotationDetails } from '@/types/api/entities'
import { APIErrorResponse } from '@/types'
import { QUERY_KEYS } from '@/constants'

export const getUseQuotationDetailsKey = (id: number): string[] =>
  QUERY_KEYS.QUOTATION_DETAILS.map((key) =>
    key === ':id' ? id.toString() : key
  )

export type UseQuotationDetailsProps = {
  id: number
  isEnabled?: boolean
}

export const useQuotationDetails = ({
  id,
  isEnabled = true,
}: UseQuotationDetailsProps): UseQueryResult<
  QuotationDetails,
  APIErrorResponse
> => {
  const client = useApiClient()

  const queryKey = getUseQuotationDetailsKey(id)

  return useQuery(
    queryKey,
    () =>
      client
        .get(`/quotations/${id}/details/`)
        .then(unwrapResponse)
        .catch(unwrapErrorResponse),
    { enabled: isEnabled }
  )
}
