import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CardLayout } from '@/components/layouts/CardLayout'
import { QuotationBasicInfo, QuotationBasicInfoForm } from '@/components/forms'
import { FooterActions } from '@/features'
import { useQuotation, usePatchQuotation, useMutationWrapper } from '@/hooks'
import { getPath } from '@/utils'
import { ROUTES_PATH } from '@/constants'

type QuotationEditProps = { quotationId: number }

export const QuotationEditStep = ({
  quotationId,
}: QuotationEditProps): JSX.Element => {
  const { t } = useTranslation('quotations')

  const { data: quotation, isLoading } = useQuotation({ id: quotationId })

  const { mutateAsync: editQuotation, isLoading: mutateInProcess } =
    useMutationWrapper(() => usePatchQuotation(quotationId))

  const navigate = useNavigate()

  const handleSubmit = async ({
    client_id,
    reference,
    is_smp,
    contact_person_ids,
    address,
    project_leader_id,
  }: QuotationBasicInfo) => {
    const quotationData = {
      id: quotationId,
      client_id: Number(client_id?.value.id),
      reference,
      is_smp,
      contact_person_ids: contact_person_ids?.map((cp) => cp.value),
      address,
      project_leader_id: project_leader_id?.value || null,
    }

    await editQuotation(quotationData, {
      onSuccess: (data) => {
        const extendQuotationStep = getPath(ROUTES_PATH.QUOTATION_EXTEND, {
          id: data.id,
        })

        navigate(`${extendQuotationStep}?step=2`)
      },
    })
  }

  return (
    <CardLayout title={t('titles.clientInfo')} cardSx={{ pb: 0 }}>
      <QuotationBasicInfoForm
        quotation={quotation}
        onSubmit={handleSubmit}
        controls={
          <FooterActions
            isNextStepDisabled={false}
            mutateInProcess={isLoading || mutateInProcess}
            onCancel={() => navigate(ROUTES_PATH.QUOTATIONS)}
          />
        }
      />
    </CardLayout>
  )
}
