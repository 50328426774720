import { useApiClient } from '@/hooks'
import { unwrapErrorResponse, unwrapResponse } from '@/utils/unwrapResponse'
import { QuotationResearch, PatchQuotationResearch } from '@/types/api/entities'
import { APIErrorResponse } from '@/types'
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import { getUseQuotationResearchKey } from '@/hooks'

export const usePatchQuotationResearch = (
  quotationId: number
): UseMutationResult<
  QuotationResearch,
  APIErrorResponse,
  PatchQuotationResearch
> => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  return useMutation(
    (params) =>
      client
        .patch(`/quotations/${quotationId}/researches/${params.id}/`, params)
        .then(unwrapResponse)
        .catch(unwrapErrorResponse),
    {
      onSuccess: (data) => {
        const queryKey = getUseQuotationResearchKey(quotationId, data.id)

        queryClient.setQueryData(queryKey, data)
      },
    }
  )
}
