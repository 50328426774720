import { useTranslation } from 'react-i18next'
import {
  Button,
  Icon,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { ObservationLogsTableProps } from '@/features'
import { AuthorizationGuard, LinkButton } from '@/components'
import { getSpeciesWithResearchTypesString } from '@/utils/getSpeciesWithResearchTypes'
import { getPath } from '@/utils'
import dayjs from 'dayjs'
import { DATE_FORMAT_DMY_TIME, PageReferrers, ROUTES_PATH } from '@/constants'
import { UserGroups } from '@/types'

export const renderStringFromList = (
  amount: number | string | null,
  amount2: number | string | null,
  amount3: number | string | null,
  amount4: number | string | null
) => [amount, amount2, amount3, amount4].filter((s) => !!s).join(', ')

export const renderObservedSpecie = (
  specie: string | null,
  specie2: string | null,
  specie3: string | null,
  specie4: string | null,
  other_specie: string | null
) => {
  if (other_specie) {
    return other_specie
  }

  return [specie, specie2, specie3, specie4].filter((s) => !!s).join(', ')
}

export const renderBehaviour = (
  behaviour: string | null,
  other_behaviour: string | null
) => (other_behaviour ? other_behaviour : behaviour || '')

export const renderPlaceTypeFound = (
  is_place_found: boolean,
  place_type: string | null
) => (is_place_found ? place_type || '' : '-')

export const ObservationLogsTableBody = ({
  rows,
  showObservationLogDetails,
}: ObservationLogsTableProps): JSX.Element => {
  const { t } = useTranslation('observationLogs')

  return (
    <TableBody>
      {rows?.map(
        ({
          id,
          research_visit_event,
          research_observation_log_type,
          observed_at,
          created_by,
          specie,
          specie2,
          specie3,
          specie4,
          other_specie,
          amount,
          amount2,
          amount3,
          amount4,
          behaviour,
          other_behaviour,
          is_place_found,
          place_type,
        }) => (
          <TableRow key={id}>
            <TableCell>{id}</TableCell>
            <TableCell component="th" scope="row">
              {research_visit_event?.research_area?.project?.client_name ?? '-'}
            </TableCell>
            <TableCell>
              {research_visit_event?.research_area?.project?.name ?? '-'}
            </TableCell>
            <TableCell>
              {research_visit_event?.research_area?.project?.project_number ??
                '-'}
            </TableCell>
            <TableCell>
              {research_visit_event?.research_area?.id ?? '-'}
            </TableCell>
            <TableCell>
              {research_visit_event?.research_area?.location_name ?? '-'}
            </TableCell>
            <TableCell>
              {!!research_visit_event?.id && (
                <AuthorizationGuard
                  allowedGroups={[UserGroups.MANAGER]}
                  redirect={false}
                  fallback={
                    <>{`${research_visit_event?.round_type} ${
                      research_visit_event?.round_number ?? ''
                    }`}</>
                  }
                >
                  <LinkButton
                    url={getPath(ROUTES_PATH.MONITORING_DETAIL, {
                      id: research_visit_event.id,
                    })}
                    title={`${research_visit_event?.round_type} ${
                      research_visit_event?.round_number ?? ''
                    }`}
                    referrer={PageReferrers.OBSERVATION}
                    arrowIcon={false}
                    variant="textThin"
                  />
                </AuthorizationGuard>
              )}
            </TableCell>
            <TableCell>
              {getSpeciesWithResearchTypesString(research_visit_event)}
            </TableCell>
            <TableCell>{research_observation_log_type.name}</TableCell>
            <TableCell>
              {observed_at
                ? dayjs(observed_at).format(DATE_FORMAT_DMY_TIME)
                : '-'}
            </TableCell>
            <TableCell>
              {created_by
                ? `${created_by.first_name} ${created_by.last_name}`
                : '-'}
            </TableCell>
            <TableCell>
              {renderObservedSpecie(
                specie,
                specie2,
                specie3,
                specie4,
                other_specie
              )}
            </TableCell>
            <TableCell>
              {renderStringFromList(amount, amount2, amount3, amount4)}
            </TableCell>
            <TableCell>{renderBehaviour(behaviour, other_behaviour)}</TableCell>
            <TableCell>
              {renderPlaceTypeFound(is_place_found, place_type)}
            </TableCell>
            <TableCell align="right">
              <Button
                variant="text"
                onClick={() => showObservationLogDetails(id)}
                endIcon={
                  <Icon
                    sx={{
                      fontSize: 14,
                      lineHeight: '21px',
                      fontWeight: 600,
                    }}
                  >
                    <ArrowForwardIcon sx={{ height: 14, width: 14 }} />
                  </Icon>
                }
              >
                <Typography variant="body2" fontWeight={600}>
                  {t('buttons.view')}
                </Typography>
              </Button>
            </TableCell>
          </TableRow>
        )
      )}
    </TableBody>
  )
}
