import {
  FieldValues,
  useController,
  Control,
  Controller,
  Path,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Stack, Box, TextField, Typography } from '@mui/material'
import { ControlledFieldProps, ApiErrorData } from '@/types'
import {
  capitalizeFirstLetter,
  getFieldErrorMessage,
  hasFieldError,
} from '@/utils'
import { ChangeEvent } from 'react'

export type ControlledFileUploadProps<T extends FieldValues> = {
  name: Path<T>
  control: Control<T>
  isReadonly?: boolean
  eventId?: number
  label?: string
  apiErrorData?: ApiErrorData
  apiFieldName?: string
  isFieldStateErrorShown?: boolean
  errorMessagePrefix?: string
} & ControlledFieldProps<T>

export const ControlledFileUpload = <T extends FieldValues>({
  name,
  control,
  apiErrorData,
  label,
  isFieldStateErrorShown = true,
  apiFieldName,
  errorMessagePrefix = 'errors:validation',
}: ControlledFileUploadProps<T>): JSX.Element | null => {
  const { t } = useTranslation()

  const {
    field: { onChange, value },
  } = useController<T>({
    name,
    control,
  })

  const onChooseFile = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement

    if (target.files) {
      onChange(target.files[0])
    }
  }

  return (
    <Stack>
      <Typography>{label}</Typography>
      {!!value && <Typography fontWeight="bold">{value.name}</Typography>}
      <Box width={300}>
        <Controller
          name={name}
          control={control}
          render={({ fieldState: { error } }) => (
            <TextField
              id={name}
              onChange={onChooseFile}
              value=""
              type="file"
              error={
                !!error || hasFieldError(apiFieldName ?? name, apiErrorData)
              }
              helperText={
                isFieldStateErrorShown && error?.message
                  ? t(
                      errorMessagePrefix
                        ? `${errorMessagePrefix}.${error.message}`
                        : error.message
                    )
                  : capitalizeFirstLetter(
                      getFieldErrorMessage(apiFieldName ?? name, apiErrorData)
                    )
              }
              inputProps={{
                multiple: false,
              }}
            />
          )}
        />
      </Box>
    </Stack>
  )
}
