import { useTranslation } from 'react-i18next'
import { Box, Stack, TextField, Typography } from '@mui/material'
import { QuotationOverhead } from '@/types'

type QuotationOverheadSummaryProps = {
  data: QuotationOverhead
}

export const QuotationOverheadSummary = ({
  data,
}: QuotationOverheadSummaryProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Stack spacing={4}>
      {data.lines.map((line) => (
        <Stack
          key={line.id}
          direction="row"
          sx={{
            justifyContent: 'space-between',
            borderRadius: 2.5,
            backgroundColor: 'grey.50',
            width: '100%',
            p: 4,
          }}
          spacing={2}
        >
          <Stack direction="row" spacing={2}>
            <Box width={120}>
              <Typography variant="body2" color="grey">
                {t('quotations:titles.description')}
              </Typography>
              <Typography>{t(`quotations:overhead.${line.type}`)}</Typography>
            </Box>
            <Box width={100}>
              <TextField
                value={line.amount_per_round}
                label={t('quotations:placeholders.hoursPerVisit')}
                disabled={true}
              />
            </Box>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Box width={100}>
              <TextField
                value={line.amount}
                label={t('quotations:placeholders.numberOfHours')}
                disabled={true}
              />
            </Box>
            <Box width={100}>
              <TextField
                value={line.price_per_unit}
                label={t('quotations:placeholders.rate')}
                disabled={true}
              />
            </Box>
            <Box width={100}>
              <TextField
                value={line.total_price}
                label={t('quotations:placeholders.totalPrice')}
                disabled={true}
              />
            </Box>
          </Stack>
        </Stack>
      ))}
    </Stack>
  )
}
