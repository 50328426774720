import { useFormContext } from 'react-hook-form'
import { Stack, Box, TextField, Typography } from '@mui/material'
import { ProtocolRoundInfoTooltip } from '@/features'
import {
  ControlledDateField,
  ControlledTextField,
  AutocompleteOption,
} from '@/components/inputs'
import { Dayjs } from 'dayjs'
import { ProjectProtocol, ProjectProtocolRound } from '@/types'
import { useTranslation } from 'react-i18next'

export type ProtocolRoundResearchersFormData = {
  [key: string]: {
    id: number
    is_customized: boolean
    rounds: RoundFormData[]
    required_checks: AutocompleteOption[]
  }
}

export type RoundFormData = {
  id: number
  number_of_visits: number
  required_number_of_researchers: number
  from_date: Dayjs | null
  to_date: Dayjs | null
  visit_duration: number
}

export type ProtocolRoundFormProps = {
  protocol: ProjectProtocol
  round: ProjectProtocolRound
  index: number
  isReadOnly: boolean
  onChange: (id: number) => void
}

export const ProtocolRoundForm = ({
  protocol,
  round,
  index,
  isReadOnly,
  onChange,
}: ProtocolRoundFormProps) => {
  const { control, watch } = useFormContext<ProtocolRoundResearchersFormData>()
  const { t } = useTranslation()

  return (
    <Stack
      direction="row"
      spacing={2}
      borderRadius={2.5}
      p={3}
      bgcolor="grey.50"
      width="100%"
    >
      <Stack direction="row" mt={2.5} width={80}>
        <Typography>{round.round_type}</Typography>
        <ProtocolRoundInfoTooltip round={round} allRounds={protocol.rounds} />
      </Stack>
      <Stack direction={{ xs: 'column', xl: 'row' }} spacing={{ xs: 6, xl: 2 }}>
        <Stack direction="row" spacing={2}>
          <Box
            width={{ xs: 236, xl: 164 }}
            onClick={() => onChange(protocol.id)}
          >
            <ControlledDateField
              name={`${protocol.id}.rounds.${index}.from_date`}
              placeholder={t('projects:protocolsStep.fromDate')}
              control={control}
              readOnly={isReadOnly}
            />
          </Box>
          <Box
            width={{ xs: 236, xl: 164 }}
            onClick={() => onChange(protocol.id)}
          >
            <ControlledDateField
              name={`${protocol.id}.rounds.${index}.to_date`}
              placeholder={t('projects:protocolsStep.toDate')}
              control={control}
              minDate={
                watch(`${protocol.id}.rounds.${index}.from_date`) ?? undefined
              }
              readOnly={isReadOnly}
            />
          </Box>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Box width={100}>
            <ControlledTextField
              name={`${protocol.id}.rounds.${index}.required_number_of_researchers`}
              control={control}
              type="number"
              inputProps={{ min: 1 }}
              placeholder={t('projects:protocolsStep.researchers')}
              errorMessagePrefix=""
            />
          </Box>
          <Box width={80} onClick={() => onChange(protocol.id)}>
            <ControlledTextField
              name={`${protocol.id}.rounds.${index}.number_of_visits`}
              control={control}
              type="number"
              inputProps={{ min: 1 }}
              placeholder={t('projects:protocolsStep.rounds')}
              readOnly={isReadOnly}
              errorMessagePrefix=""
            />
          </Box>
          <Box width={90}>
            <TextField
              value={
                (watch(`${protocol.id}.rounds.${index}.number_of_visits`) ??
                  0) *
                (watch(
                  `${protocol.id}.rounds.${index}.required_number_of_researchers`
                ) ?? 0)
              }
              label={t('projects:protocolsStep.visitTotal')}
              disabled={true}
            />
          </Box>
          <Box width={100} onClick={() => onChange(protocol.id)}>
            <ControlledTextField
              name={`${protocol.id}.rounds.${index}.visit_duration`}
              control={control}
              type="number"
              inputProps={{ min: 1 }}
              placeholder={t('projects:protocolsStep.visitDuration')}
              readOnly={isReadOnly}
              errorMessagePrefix=""
            />
          </Box>
          <Box width={80}>
            <TextField
              value={(
                ((watch(`${protocol.id}.rounds.${index}.number_of_visits`) ??
                  0) *
                  (watch(
                    `${protocol.id}.rounds.${index}.required_number_of_researchers`
                  ) ?? 0) *
                  (watch(`${protocol.id}.rounds.${index}.visit_duration`) ??
                    0)) /
                60
              ).toFixed(1)}
              label={t('projects:protocolsStep.hours')}
              disabled={true}
            />
          </Box>
        </Stack>
      </Stack>
    </Stack>
  )
}
