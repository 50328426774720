import {
  VisitLog,
  PaginatedListResponse,
  APIErrorResponse,
  SearchRequestParams,
} from '@/types'
import { UseQueryResult } from '@tanstack/react-query'
import { useQueryList } from './useQueryList'
import { QUERY_KEYS } from '@/constants'

export type VisitLogsSearchParams = SearchRequestParams & {
  id?: string
  date?: string
  startDate?: string
  endDate?: string
  visitResearchers?: string
  project_leader?: string
  isEventApproved?: boolean
}

export const getUseVisitLogsKey = (): string[] =>
  QUERY_KEYS.VISIT_LOGS.slice(0, 2) as string[]

export const useVisitLogs = (
  { ...params }: VisitLogsSearchParams,
  enabled = true
): UseQueryResult<PaginatedListResponse<VisitLog>, APIErrorResponse> =>
  useQueryList({
    url: '/plannings/visit_logs/',
    options: {
      enabled,
    },
    params: {
      ...(!!params.id && {
        id: params.id,
      }),
      ...(!!params.search && {
        search: params.search,
      }),
      ...(!!params.status && {
        status: params.status,
      }),
      ...(!!params.startDate && { date_start: params.startDate }),
      ...(!!params.endDate && { date_end: params.endDate }),
      ...(!!params.visitResearchers && {
        researchers: params.visitResearchers,
      }),
      ...(!!params.project_leader && { project_leader: params.project_leader }),
      ...(params.isEventApproved !== undefined && {
        is_event_approved: params.isEventApproved,
      }),
      limit: params.limit,
      offset: params.offset,
    },
  })
